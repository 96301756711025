import axios from 'axios';
import React, { useContext, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { AuthContext } from '../../../Context/AuthStore';
import { Document, Page, Text, View, StyleSheet, pdf, Font } from '@react-pdf/renderer';
import { saveAs } from 'file-saver';
import { toast } from 'react-toastify';

export default function ClaimDetails() {
    let { accessToken } = useContext(AuthContext);
    let [claimDetails, setClaimDetails] = useState([]);
    let [workersClaim, setWorkersClaim] = useState([]);
    let [retireesClaim, setRetireesClaim] = useState([]);

    let getClaimDetails = async () => {
        try {
            let { data } = await axios.get(`${process.env.REACT_APP_API_URL}/api/abuQirForms/claimDetails`, {
                headers: {
                    "Authorization": `Bearer ${accessToken}`
                }
            });
            setClaimDetails(data);
            setWorkersClaim(data.workersForms);
            setRetireesClaim(data.retireesForms); 
        } catch (error) {
            toast.error('حدث خطأ ما')
        }
      
    };

    useEffect(() => {
        getClaimDetails();
    }, []);

    const handlePrint = () => {
        window.print();
    };

    let showClaimDetails = () => {
        if (claimDetails) {
            return (
                <div className="my-2 rounded rounded-4 bg-white mx-3 p-3 table-responsive">
                    <table dir="rtl" className='table table-bordered table-hover align-middle text-center table-to-print'>
                        <thead className='table-secondary no-wrap-heading'>
                            <tr>
                                <th></th>
                                <th>إجمالي قبل التعاقد</th>
                                <th>إجمالي بعد التعاقد</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td className='bg-secondary-subtle'>العاملين</td>
                                <td>{claimDetails.workersTotal}</td>
                                <td>{claimDetails.workersTotallAfterContracting}</td>
                            </tr>
                            <tr>
                                <td></td>
                            </tr>
                            <tr>
                                <td className='bg-secondary-subtle'>المعاشات</td>
                                <td>{claimDetails.retiredTotal}</td>
                                <td>{claimDetails.retiredTotalAfterContracting}</td>
                            </tr>
                            <tr>
                                <td></td>
                            </tr>
                            <tr>
                                <td className='bg-secondary-subtle'>إجمالي المطالبة</td>
                                <td>{claimDetails.totalRemaining}</td>
                                <td>{claimDetails.totalAfterContracting}</td>
                            </tr>
                        </tbody>
                    </table>
                    <button className="btn btn-secondary d-print-none" onClick={handlePrint}>طباعة</button>
                </div>
            );
        } else {
            return (
                <div className='d-flex justify-content-center vh-100 align-items-center'>
                    <i className='fa fa-spinner fa-spin fa-4x'></i>
                </div>
            );
        }
    };

    // Register Arabic font
    Font.register({
        family: 'Amiri',
        src: 'https://fonts.gstatic.com/s/amiri/v14/J7aRnpd8CGxBHqUpvrIw74NL.ttf',
    });

    const styles = StyleSheet.create({
        page: {
            padding: 30,
        },
        table: {
            display: 'table',
            width: 'auto',
            borderStyle: 'solid',
            borderColor: '#000',
            borderWidth: 1,
            borderRightWidth: 0,
            borderBottomWidth: 0,
        },
        tableRow: {
            flexDirection: 'row-reverse',
        },
        tableColHeader: {
            borderStyle: 'solid',
            borderColor: '#000',
            borderBottomColor: '#000',
            borderWidth: 1,
            borderLeftWidth: 0,
            borderTopWidth: 0,
            paddingHorizontal:4,
            backgroundColor: "#333",
            color: '#fff'
        },
        tableColSmall: {
            width: '10%', // عرض العمود الثاني "رقم القيد"
            borderStyle: 'solid',
            borderColor: '#000',
            borderWidth: 1,
            borderLeftWidth: 0,
            borderTopWidth: 0,
            textAlign: 'center',
            paddingHorizontal:4,
        },
        tableCol: {
            width: '25%', // عرض العمود الثاني "رقم القيد"
            borderStyle: 'solid',
            borderColor: '#000',
            borderWidth: 1,
            borderLeftWidth: 0,
            borderTopWidth: 0,
            textAlign: 'center',
            paddingHorizontal:4,
        },
        tableColWide: {
            width: '35%', // عرض العمود "الباقي"
            borderStyle: 'solid',
            borderColor: '#000',
            borderWidth: 1,
            borderLeftWidth: 0,
            borderTopWidth: 0,
            textAlign: 'center',
            paddingHorizontal:4,
        },
        tableCellHeader: {
            margin: 'auto',
            marginTop: 5,
            fontSize: 14,
            fontWeight: 'bold',
            fontFamily: 'Amiri',
        },
        tableCell: {
            margin: 'auto',
            marginTop: 5,
            fontSize: 12,
            fontFamily: 'Amiri',
        },
    });

    const generateWorkersPDF = () => (
        <Document>
            <Page size="A4" style={styles.page}>
                <View style={styles.table}>
                    <View style={styles.tableRow}>
                        <View style={[styles.tableColHeader, styles.tableColSmall]}><Text style={styles.tableCellHeader}>م</Text></View>
                        <View style={[styles.tableColHeader, styles.tableColSmall]}><Text style={styles.tableCellHeader}>رقم القيد</Text></View>
                        <View style={[styles.tableColHeader, styles.tableColWide]}><Text style={styles.tableCellHeader}>اسم العميل</Text></View>
                        <View style={[styles.tableColHeader, styles.tableCol]}><Text style={styles.tableCellHeader}>ملاحظات</Text></View>
                        <View style={[styles.tableColHeader, styles.tableCol]}><Text style={styles.tableCellHeader}>الباقي</Text></View>
                    </View>
                    {workersClaim.map((worker, index) => (
                        <View key={worker.id} style={styles.tableRow}>
                            <View style={styles.tableColSmall}><Text style={styles.tableCell}>{index + 1}</Text></View>
                            <View style={styles.tableColSmall}><Text style={styles.tableCell}>{worker?.client?.registrationNumber}</Text></View>
                            <View style={styles.tableColWide}><Text style={styles.tableCell}>{worker?.client?.name}</Text></View>
                            <View style={styles.tableCol}><Text style={styles.tableCell}></Text></View>
                            <View style={styles.tableCol}><Text style={styles.tableCell}>{worker?.remaining}</Text></View>
                        </View>
                    ))}
                </View>
            </Page>
        </Document>
    );

    const generateRetireesPDF = () => (
        <Document>
            <Page size="A4" style={styles.page}>
                <View style={styles.table}>
                    <View style={styles.tableRow}>
                        <View style={[styles.tableColHeader, styles.tableColSmall]}><Text style={styles.tableCellHeader}>م</Text></View>
                        <View style={[styles.tableColHeader, styles.tableColSmall]}><Text style={styles.tableCellHeader}>رقم القيد</Text></View>
                        <View style={[styles.tableColHeader, styles.tableColWide]}><Text style={styles.tableCellHeader}>اسم العميل</Text></View>
                        <View style={[styles.tableColHeader, styles.tableCol]}><Text style={styles.tableCellHeader}>ملاحظات</Text></View>
                        <View style={[styles.tableColHeader, styles.tableCol]}><Text style={styles.tableCellHeader}>الباقي</Text></View>
                    </View>
                    {retireesClaim.map((retired, index) => (
                        <View key={retired.id} style={styles.tableRow}>
                            <View style={styles.tableColSmall}><Text style={styles.tableCell}>{index + 1}</Text></View>
                            <View style={styles.tableColSmall}><Text style={styles.tableCell}>{retired?.client?.registrationNumber}</Text></View>
                            <View style={styles.tableColWide}><Text style={styles.tableCell}>{retired.client?.name}</Text></View>
                            <View style={styles.tableCol}><Text style={styles.tableCell}></Text></View>
                            <View style={styles.tableCol}><Text style={styles.tableCell}>{retired?.remaining}</Text></View>
                        </View>
                    ))}
                </View>
            </Page>
        </Document>
    );

    return (
        <>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Claim Details</title>
            </Helmet>

            {showClaimDetails()}
            <div>
                <button className='btn btn-secondary m-3' onClick={() => {
                    const pdfContent = generateWorkersPDF();
                    pdf(pdfContent).toBlob().then(blob => {
                        saveAs(blob, 'عاملين.pdf');
                    });
                }}>طباعة عاملين</button>
            </div>
            <div>
                <button className='btn btn-secondary mx-3' onClick={() => {
                    const pdfContent = generateRetireesPDF();
                    pdf(pdfContent).toBlob().then(blob => {
                        saveAs(blob, 'معاشات.pdf');
                    });
                }}>طباعة معاشات</button>
            </div>
        </>
    );
}
