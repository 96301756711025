import axios from 'axios';
import React, { useContext, useEffect, useState } from 'react'
import { Helmet } from 'react-helmet-async';
import { NavLink } from 'react-router-dom';
import { AuthContext } from '../../Context/AuthStore';
import Pagination from '../Pagination/Pagination';
import { toast } from 'react-toastify';
import { useRef } from 'react';
import Joi from 'joi';

export default function Employees() {
    let { accessToken } = useContext(AuthContext);
    let [employees, setEmployess] = useState({})
    let [pagination, setPagination] = useState(null);
    let [currentPage, setCurrentPage] = useState(1); // Current page state

    let [filterBranchId, setFilterBranchId] = useState('');
    function handleBranchIdChange(event) {
        setFilterBranchId(event?.target?.value);
    }
    let [filterCategryId, setFilterCategryId] = useState('');
    function handleCategryIdChange(event) {
        setFilterCategryId(event.target.value)
    }
    let getEmployeeData = async (page = 1) => {
        try {
            let employeesResult;
            let urlApi = `${process.env.REACT_APP_API_URL}/api/employees/filter?`;
            if (filterBranchId !== undefined && filterBranchId.length > 0) {
                urlApi += `branch_id=${filterBranchId}&`
            }
            if (filterCategryId !== undefined && filterCategryId.length > 0) {
                urlApi += `category_id=${filterCategryId}&`
            }
            urlApi += `page=${page}`
            employeesResult = await axios.get(urlApi, {
                headers: {
                    "Authorization": `Bearer ${accessToken}`
                }
            })

            if (employeesResult) {
                setEmployess(employeesResult.data.data);
                setPagination(employeesResult.data.meta); // Set pagination data
                setCurrentPage(page); // Set current page
            }
        } catch (error) {
            toast.error('حدث خطأ ما')
        }

    };
    useEffect(() => { getEmployeeData() },
        [filterBranchId, filterCategryId]);

    let [branches, setBranches] = useState([]);
    let getBranches = async () => {
        let { data } = await axios.get(`${process.env.REACT_APP_API_URL}/api/properties/getCustomList/branch`, {
            headers: {
                "Authorization": `Bearer ${accessToken}`
            }
        });
        setBranches(data)
    }
    useEffect(() => {
        getBranches()
        getEmployeeCategory()
    }, [])
    //for handle page change
    let handlePageChange = (page) => {
        getEmployeeData(page);
    };
    let deleteEmployee = async (empId) => {
        try {
            let { data } = await axios.delete(`${process.env.REACT_APP_API_URL}/api/employees/delete/${empId}`, {
                headers: {
                    "Authorization": `Bearer ${accessToken}`
                }
            });
            toast.success(data.message);
            getEmployeeData()

        } catch (error) {
            toast.error('حدث خطأ ما، حاول مرة أخرى')
        }
    };
    let [employeeId, setEmployeeId] = useState('');
    let showEmployees = () => {
        if (employees.length > 0) {
            return (
                <div className="shadow rounded rounded-4 bg-white mx-3 p-3 table-responsive ">
                    <table dir="rtl" className='table  table-hover align-middle text-center table-responsive-list  '>
                        <thead className='table-primary   no-wrap-heading'>
                            <tr>
                                <th>الاسم</th>
                                <th>الكود</th>
                                <th>الفرع</th>
                                <th> سعر الساعة</th>
                                <th>الوظيفة</th>
                                <th>خيارات</th>
                            </tr>
                        </thead>
                        <tbody>
                            {employees.map((emp) => <tr key={emp.id}>
                                <td data-label="الاسم ">{emp?.name}</td>
                                <td data-label="الكود ">{emp?.code}</td>
                                <td data-label="الفرع ">{emp?.branch?.name}</td>
                                <td data-label="سعر الساعة">{emp?.hourRate}</td>
                                <td data-label="الوظيفة">{emp?.category?.name}</td>
                                <td data-label="خيارات">
                                    <div className="dropdown">
                                        <i className='bi bi-list-ul text-success mx-1 fs-5' onClick={() => {
                                            setEmployeeId(emp.id)
                                        }} data-bs-toggle="modal" data-bs-target="#employeeDetails"></i>
                                        <i className='bi bi-pencil-square text-primary fs-5 mx-1' onClick={() => {
                                            setEmployeeId(emp.id)
                                        }} data-bs-toggle="modal" data-bs-target="#editeEmployee"> </i>
                                        <span data-bs-toggle="dropdown" aria-expanded="false">
                                            <i className='bi bi-trash text-danger fs-5 '></i>
                                        </span>
                                        <ul className="dropdown-menu">
                                            <li>
                                                <button className="dropdown-item" onClick={() => {
                                                    deleteEmployee(emp.id)
                                                }}>حذف
                                                </button>
                                            </li>
                                        </ul>
                                    </div>
                                </td>
                            </tr>
                            )}
                        </tbody>
                    </table>
                </div>
            )
        } else {
            return (
                <div className=' d-flex justify-content-center  height-calc-70 align-items-center' >
                    {employees.length <= 0 && filterBranchId.length <= 0 && filterCategryId.length <= 0 ?
                        <i className='fa fa-spinner fa-spin  fa-3x'></i>
                        : <div className='alert alert-danger w-50 text-center'>لا يوجد تطابق</div>
                    }
                </div>
            )
        }
    };
    // employee details 
    // Helper function to format time
    const formatTime = (time) => {
        const [hours, minutes, seconds] = time.split(':');
        const ampm = hours >= 12 ? 'PM' : 'AM';
        const formattedHours = hours % 12 || 12;
        return `${formattedHours}:${minutes} ${ampm}`;
    };
    let [oneEmployee, setOneEmployee] = useState([]);
    let getOneEmployee = async () => {
        if (employeeId) {
            let { data } = await axios.get(`${process.env.REACT_APP_API_URL}/api/employees/show/${employeeId}`, {
                headers: {
                    "Authorization": `Bearer ${accessToken}`
                }
            });
            const employeeData = data.data;
            setOneEmployee(employeeData);
            let newAttendanceTimes = [
                ...employeeData.attendanceTimes
            ];
            const today = new Date().toLocaleString('en-US', { weekday: 'long' }); // اليوم الحالي
            const todayAttendance = newAttendanceTimes.find(entry => entry.day === today) || {
                attendance_time: '',
                departure_time: ''
            };
            setEditedEmployee({
                name: employeeData.name || '',
                code: employeeData.code || '',
                hourRate: employeeData.hourRate || '',
                attendance_time: todayAttendance.attendance_time,
                departure_time: todayAttendance.departure_time,
                category_id: employeeData.category?.id || '',
                notes: employeeData.notes || '',
                branch_id: employeeData.branch?.id || '',
                constantIncentive: employeeData?.constantIncentive || ''
            });
        }
    };

    useEffect(() => {
        getOneEmployee()
    }, [employeeId]);

    // edite abu qir client
    let formRef = useRef(null);
    let [isLoading, setIsLoading] = useState(false);
    let [editedEmployee, setEditedEmployee] = useState({
        name: '',
        code: '',
        hourRate: '',
        attendance_time: '',
        departure_time: '',
        category_id: '',
        notes: '',
        branch_id: '',
        constantIncentive: ''
    });
    //get employeeCategory data
    let [employeeCategory, setEmployeeCategory] = useState([]);
    let getEmployeeCategory = async () => {
        let { data } = await axios.get(`${process.env.REACT_APP_API_URL}/api/properties/getCustomList/employeeCategory`, {
            headers: {
                "Authorization": `Bearer ${accessToken}`
            }
        });
        setEmployeeCategory(data)
    }
    let handleInputChange = (event) => {
        let myEmployees = { ...editedEmployee }; //deep copy
        myEmployees[event.target.name] = event.target.value;
        setEditedEmployee(myEmployees);
    };
    let sendEditedEmployeeToApi = async () => {
        await axios.put(`${process.env.REACT_APP_API_URL}/api/employees/${employeeId}`, editedEmployee, {
            headers: {
                "Authorization": `Bearer ${accessToken}`
            }
        }).then((res) => {
            toast.success(res.data.message, {
                position: 'top-center'
            });
            setIsLoading(false);
            formRef.current.reset();
            setEmployeeId('')
            getEmployeeData()
        }).catch((errors) => {
            setIsLoading(false);
            try {
                const errorList = errors?.response?.data?.message;
                if (errorList !== undefined) {
                    Object.keys(errorList)?.map((err) => {
                        errorList[err]?.map((err) => {
                            toast.error(err);
                        })
                    });
                } else {
                    toast.error("حدث خطأ ما");
                }
            } catch (error) {
                toast.error("حدث خطأ ما");
            }

        });
    };

    let validateEditedEmployeeFrom = () => {
        const schema = Joi.object({
            name: Joi.string().required(),
            code: Joi.string().required(),
            hourRate: Joi.number().required(),
            attendance_time: Joi.string().required(),
            departure_time: Joi.string().required(),
            category_id: Joi.number().required(),
            notes: Joi.string().empty(''),
            branch_id: Joi.number().required(),
            constantIncentive: Joi.string().empty(''),
        });
        return schema.validate(editedEmployee, { abortEarly: false });
    };

    let submitEditedEmployee = (e) => {
        setIsLoading(true);
        e.preventDefault();
        let validation = validateEditedEmployeeFrom();
        if (!validation.error) {
            sendEditedEmployeeToApi();
        } else {
            try {
                validation.error.details.map((err) => {
                    toast.error(err.message);
                })
            } catch (e) {
                toast.error("حدث خطأ ما");
            }
        }
    };

    return (
        <>
            <Helmet>
                <meta charSet="utf-8" />
                <title> Employees</title>
            </Helmet>
            <div className=" my-3 text-center row mx-2  " dir='rtl'>
                <div className="col-md-4">
                    <NavLink to='/admin/employees/add' className='btn btn-primary mb-1'>إضافة موظف</NavLink>
                </div>
                <div className="col-md-4 m-auto   mb-2">
                    <select name="branch_id" defaultValue={0} className='form-control' id="branch_id"
                        onChange={handleBranchIdChange}>
                        <option value={0} hidden disabled>اختر الفرع...</option>
                        {branches.map((branch) => <option key={branch.id} value={branch?.id}>{branch?.name}</option>)}
                    </select>
                </div>
                <div className="col-md-4">
                    <select className='form-control' defaultValue={0} name="category_id" id="category_id"
                        onChange={handleCategryIdChange}>
                        <option value={0} hidden disabled>اختر الفئة...</option>
                        {employeeCategory.map((cat) => <option key={cat.id} value={cat.id}>{cat?.name}</option>)}
                    </select>
                </div>

            </div>

            <div className="text-center mb-2">
                <Pagination pagination={pagination} currentPage={currentPage} handlePageChange={handlePageChange} />
            </div>
            {showEmployees()}
            {/* employee details modal */}
            <div dir='rtl' className="modal fade" id="employeeDetails" tabIndex={-1}
                aria-labelledby="employeeDetailsLabel" aria-hidden="true">
                <div className="modal-dialog  modal-xl  ">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h1 className="modal-title text-danger fs-5  m-auto" id="employeeDetailsLabel"> تفاصيل
                                الموظف </h1>
                        </div>
                        <div className="modal-body ">

                            <div className="row m-2">
                                <p><strong> كود الموظف:</strong> <span>{oneEmployee?.code} </span></p>
                                <p><strong> اسم الموظف :</strong> <span>{oneEmployee?.name} </span></p>
                                <p><strong> اسم الفرع :</strong> <span>{oneEmployee?.branch?.name} </span></p>
                                <p><strong> قيمة الحافز الثابت  :</strong> <span>{oneEmployee?.constantIncentive} </span></p>
                                <p><strong> سعر الساعة :</strong> <span>{oneEmployee?.hourRate} </span></p>
                                <p><strong> الوظيفة :</strong> <span>{oneEmployee?.category?.name} </span></p>
                                {oneEmployee?.attendanceTimes?.map((time) => <div key={time.id} className='row my-1'>
                                    <div className='col-md-4'><strong> اليوم: </strong>
                                        <span>
                                            {time.day === 'Saturday' ? 'السبت' :
                                                time.day === 'Sunday' ? 'الأحد' :
                                                    time.day === 'Monday' ? 'الإثنين' :
                                                        time.day === 'Tuesday' ? 'الثلاثاء' :
                                                            time.day === 'Wednesday' ? 'الأربعاء' :
                                                                time.day === 'Thursday' ? 'الخميس' :
                                                                    time.day === 'Friday' ? 'الجمعة' : ''}
                                        </span>
                                    </div>
                                    <div className='col-md-4'><strong> موعد الحضور: </strong>
                                        <span>{formatTime(time.attendance_time)}</span></div>
                                    <div className='col-md-4'><strong> موعد الإنصراف: </strong>
                                        <span>{formatTime(time.departure_time)}</span></div>
                                </div>)}
                                <p className='my-2'><strong> ملاحظات :</strong> <span>  {oneEmployee?.notes} </span></p>
                            </div>
                        </div>
                        <div className="m-2">
                            <button type="button" className="btn btn-secondary ms-3" data-bs-dismiss="modal">غلق
                            </button>
                        </div>
                    </div>
                </div>
            </div>

            {/* edite employee modal */}
            <div dir='rtl' className="modal fade" id="editeEmployee" tabIndex={-1}
                aria-labelledby="editeEmployeeLabel" aria-hidden="true">
                <div className="modal-dialog  modal-xl  ">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h1 className="modal-title text-danger fs-5  m-auto" id="editeEmployeeLabel"> تعديل
                                الموظف </h1>
                        </div>
                        <div className="modal-body ">
                            <form ref={formRef} onSubmit={submitEditedEmployee}>
                                <div className="row gy-3">
                                    <div className="col-md-4">
                                        <label htmlFor="code" className='form-label'>كود الموظف</label>
                                        <input type="text" className='form-control' name="code" id="code" onChange={handleInputChange} />
                                    </div>
                                    <div className="col-md-4">
                                        <label htmlFor="name" className='form-label'>الاسم</label>
                                        <input type="text" className='form-control' name="name" id="name" onChange={handleInputChange} />
                                    </div>
                                    <div className="col-md-4">
                                        <label htmlFor="branch" className='form-label'>اسم الفرع</label>
                                        <select name="branch_id" className='form-control' id="branch" defaultValue={0} onChange={handleInputChange}>
                                            <option value={0} hidden disabled>اختر...</option>
                                            {branches.map((branch) => <option key={branch.id} value={branch?.id}>{branch?.name}</option>)}
                                        </select>
                                    </div>
                                    <div className="col-md-4">
                                        <label htmlFor="hourRate" className='form-label'> سعر الساعة </label>
                                        <input type="text" id="hourRate" className='form-control' name="hourRate" onChange={handleInputChange} />
                                    </div>
                                    <div className="col-md-4">
                                        <label htmlFor="category_id" className='form-label'>وظيفته </label>
                                        <select type="text" className='form-control' name="category_id" id="category_id" defaultValue={0} onChange={handleInputChange} >
                                            <option value={0} hidden disabled>اختر...</option>
                                            {employeeCategory.map((cat) => <option key={cat.id} value={cat.id}>{cat?.name}</option>)}
                                        </select>
                                    </div>
                                    <div className="col-md-4">
                                        <label htmlFor="constantIncentive" className='form-label'>  الحافز الثابت</label>
                                        <input type="text" id="constantIncentive" className='form-control' name="constantIncentive" onChange={handleInputChange} />
                                    </div>
                                    <div className="col-md-4">
                                        <label htmlFor="attendance_time" className='form-label'>  موعد الحضور </label>
                                        <input step="60" type="time" id="attendance_time" className='form-control' name="attendance_time" onChange={handleInputChange} />
                                    </div>
                                    <div className="col-md-4">
                                        <label htmlFor="departure_time" className='form-label'>  موعد الإنصراف </label>
                                        <input type="time" step='60' id="departure_time" className='form-control' name="departure_time" onChange={handleInputChange} />
                                    </div>
                                    <div className="col-md-12">
                                        <label htmlFor="notes" className='form-label'>ملاحظات</label>
                                        <textarea type="text" id="notes" className='form-control' name="notes" onChange={handleInputChange} />
                                    </div>
                                    <div className="col-md-3">
                                        <button type='submit' className='btn btn-primary form-control fs-5'>
                                            {isLoading == true ?
                                                <i className='fa fa-spinner fa-spin'></i> : 'تعديل'}
                                        </button>
                                    </div>
                                </div>
                            </form>

                        </div>
                        <div className="m-2">
                            <button type="button" className="btn btn-secondary ms-3" data-bs-dismiss="modal">غلق
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </>)
}
