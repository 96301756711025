
import axios from 'axios';
import React, { useContext, useEffect, useState, useRef } from 'react';
import { Helmet } from 'react-helmet-async';
import { AuthContext } from '../../Context/AuthStore';
import Pagination from '../Pagination/Pagination';

export default function WorkingHoursMoreTwelve() {
    let { accessToken } = useContext(AuthContext);
    let [pagination, setPagination] = useState(null);
    let [currentPage, setCurrentPage] = useState(1);
    let [workingHoursMoreTwelve, setWorkingHoursMoreTwelve] = useState([]);

    let getWorkingHoursMoreTwelveData = async (page = 1) => {
        let { data } = await axios.get(`${process.env.REACT_APP_API_URL}/api/attendance/filter?workingHoursMore=12&page=${page}`, {
            headers: {
                "Authorization": `Bearer ${accessToken}`
            }
        });
        setWorkingHoursMoreTwelve(data.data);
        setPagination(data.meta);
        setCurrentPage(page);
    };

    useEffect(() => {
        getWorkingHoursMoreTwelveData();
    }, []);

    let handlePageChange = (page) => {
        getWorkingHoursMoreTwelveData(page);
    };

    let showWorkingHoursMoreTwelve= () => {
        if (workingHoursMoreTwelve.length > 0) {
            return (
                <div className="shadow rounded rounded-4 bg-white m-3 p-3 table-responsive">
                    <table dir="rtl" className='table table-hover align-middle text-center table-responsive-list'>
                        <thead className='table-primary no-wrap-heading'>
                            <tr>
                                <th>التاريخ</th>
                                <th>اليوم</th>
                                <th>الموظف</th>
                                <th>الوظيفة</th>
                                <th>وقت الحضور</th>
                                <th>وقت الانصراف</th>
                                <th>ساعات الحضور </th>
                                <th>تأخير</th>
                            </tr>
                        </thead>
                        <tbody>
                            {workingHoursMoreTwelve.map((emp) => (
                                <tr key={emp.id}>
                                    <td data-label="التاريخ">{emp.date}</td>
                                    <td data-label="اليوم">
                                        {emp.day === 'Saturday' ? 'السبت' :
                                            emp.day === 'Sunday' ? 'الأحد' :
                                                emp.day === 'Monday' ? 'الإثنين' :
                                                    emp.day === 'Tuesday' ? 'الثلاثاء' :
                                                        emp.day === 'Wednesday' ? 'الأربعاء' :
                                                            emp.day === 'Thursday' ? 'الخميس' :
                                                                emp.day === 'Friday' ? 'الجمعة' : ''}
                                    </td>
                                    <td data-label="الموظف">{emp?.employee?.name}</td>
                                    <td data-label="الوظيفة">{emp.employee?.category?.name}</td>
                                    <td data-label="وقت الحضور">{emp?.attendance_time}</td>
                                    <td data-label="وقت الانصراف">{emp?.departure_time}</td>
                                    <td data-label="وقت الانصراف">{emp?.working_hours}</td>
                                    <td data-label="تأخير">{emp?.lateHours}</td>

                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            );
        } else {
            return (
                <div className='d-flex justify-content-center height-calc-70 align-items-center'>
                    <div className='alert alert-danger w-50 text-center'>لا يوجد </div>
                </div>
            );
        }
    };

    return (
        <>
            <Helmet>
                <meta charSet="utf-8" />
                <title> Working Hours More Twelve</title>
            </Helmet>
            <div className='text-center my-3'>
            <Pagination pagination={pagination} currentPage={currentPage} handlePageChange={handlePageChange} />
            {showWorkingHoursMoreTwelve()}
            </div>
    
        </>
    );
}
