
import axios from 'axios';
import Joi from 'joi';
import React, { useContext, useEffect, useRef, useState } from 'react'
import { Helmet } from 'react-helmet-async';
import { toast } from 'react-toastify';
import { AuthContext } from '../../../Context/AuthStore';
import Select from 'react-select';

export default function AddAbuQirForms() {
  let { accessToken } = useContext(AuthContext);
  const currentPath = window.location.pathname;
  //selcting form  to reset the form after submtion
  const formRef = useRef(null);
  const clientSelectRef = useRef(null);
  let [isLoading, setIsLoading] = useState(false);
  let [abuQirForms, setAbuQirForms] = useState({
    isAContribution: '',
    client_id: '',
    elements: [],
    notes: '',
    isReceived: true
  });
  // define if it a form or bill -- if form isReceived will be true & if bill isReceived will be false
  useEffect(() => {
    if (currentPath === '/admin/abuqir-bills/add' || currentPath === '/doctor/abuqir-bills/add' ||
      currentPath === '/casher/abuqir-bills/add' || currentPath === '/purchases/abuqir-bills/add'
      || currentPath === '/branch-manager/abuqir-bills/add') {
      setAbuQirForms((prevState) => ({
        ...prevState,
        isReceived: false,
      }));
    } else {
      setAbuQirForms((prevState) => ({
        ...prevState,
        isReceived: true,
      }));
    }
  }, [currentPath]);
  let [clients, setClients] = useState([]);
  let [clientOptions, setClientOptions] = useState([]);
  let getClientData = async () => {
    try {
      let { data } = await axios.get(`${process.env.REACT_APP_API_URL}/api/abuQirClients?noPaginate=1`, {
        headers: {
          "Authorization": `Bearer ${accessToken}`
        }
      });
      setClients(data.data);
    } catch (error) {
      toast.error('حدث خطأ ما.')
    }
  };

  useEffect(() => {
    getClientData()
  }, []);

  useEffect(() => {
    let mapClient = clients?.map((client) => ({
      value: `${client.id}`,
      label: `${client.registrationNumber}`,
      client_name: `${client.name}`
    }));
    setClientOptions(mapClient);
  }, [clients]);
  // let [clientCodeChanged, setClientCodeChanged] = useState(false);
  // useEffect(() => {
  //   if (abuQirForms.client_id !== '') {
  //     setClientCodeChanged(true);
  //   } else {
  //     setClientCodeChanged(false);
  //   }
  // }, [abuQirForms.client_id]);
  // check if the client has an unReceived forms (bills)
  let [clientBillsData, setClientBillsData] = useState({})
  let checkUnReceivedFormsForClient = async (clientId) => {
    try {
      let { data } = await axios.get(`${process.env.REACT_APP_API_URL}/api/abuQirForms/checkUnReceived/${clientId}`, {
        headers: {
          "Authorization": `Bearer ${accessToken}`
        }
      });
      setClientBillsData(data);
    } catch (error) {
      console.log(error);
      toast.error('حدث خطأ ما.')
    }
  }
  let [clientData, setClientData] = useState({});
  let getSelectedClient = (selectedOption) => {
    if (selectedOption) {
      checkUnReceivedFormsForClient(selectedOption.value)
      setAbuQirForms({
        ...abuQirForms,
        client_id: selectedOption?.value,
      });
      setClientData(selectedOption);
      setOpenBillModal(true);
    }
  };


  // let [nextId, setNextId] = useState('')
  // let getNextIdForForm = async () => {
  //   try {
  //     let { data } = await axios.get(`${process.env.REACT_APP_API_URL}/api/abuQirForms/nextId`, {
  //       headers: {
  //         "Authorization": `Bearer ${accessToken}`
  //       }
  //     });
  //     setNextId(data.nextId);
  //   } catch (error) {
  //     toast.error('حدث خطأ ما.')
  //   }
  // }
  // useEffect(() => {
  //   getNextIdForForm()
  // }, [])
  let [elmentsChanged, setElmentsChanged] = useState(false);
  useEffect(() => {
    if (abuQirForms.elements.length > 0) {
      setElmentsChanged(true);
    } else {
      setElmentsChanged(false);
    }
  }, [abuQirForms.elements]);
  let [totalValue, setTotalValue] = useState('')
  let [remainingValue, setRemainingValue] = useState('')

  let addElementField = () => {
    setAbuQirForms({
      ...abuQirForms,
      elements: [...abuQirForms.elements, '']
    });
  };

  let removeElementField = (index) => {
    const newElements = abuQirForms.elements.filter((_, i) => i !== index);
    setAbuQirForms({
      ...abuQirForms,
      elements: newElements
    });
  };

  let handleElementChange = (index, value) => {
    let remaining;
    const newElements = abuQirForms.elements.map((element, i) => (i === index ? parseFloat(value) : element));
    let total = newElements.reduce((sum, currentValue) => sum + currentValue, 0);
    setTotalValue(total)
    if (abuQirForms.isAContribution == 1) {
      remaining = total
      setRemainingValue(remaining)
    } else {
      remaining = (total * 75) / 100;
      setRemainingValue(remaining)
    }
    setAbuQirForms({
      ...abuQirForms,
      elements: newElements
    });
  };

  let getInputValue = (event) => {
    let myforms = { ...abuQirForms }; //deep copy
    myforms[event.target.name] = event.target.value;
    setAbuQirForms(myforms)

  };


  let sendAbuQirFormsDataToApi = async () => {
    try {
      await axios.post(`${process.env.REACT_APP_API_URL}/api/abuQirForms`, abuQirForms, {
        headers: {
          "Authorization": `Bearer ${accessToken}`
        }
      }).then((res) => {
        formRef.current.reset();
        clientSelectRef.current.clearValue();
        setAbuQirForms((prevState) => ({
          isAContribution: '',
          client_id: '',
          elements: [],
          notes: '',
          isReceived: prevState.isReceived // المحافظة على القيمة الحالية لـ isReceived
        }));
        setTotalValue('')
        setRemainingValue('')
        setClientBillsData({})
        toast.success(res.data.message, {
          position: 'top-center'
        });
        setIsLoading(false);
        // getNextIdForForm()
      }).catch((errors) => {
        setIsLoading(false);
        const errorList = errors?.response?.data?.message;
        if (errorList !== undefined) {
          Object.keys(errorList)?.map((err) => {
            errorList[err]?.map((err) => {
              toast.error(err);
            })
          });
        } else {
          toast.error("حدث خطأ ما");
        }
      });
    } catch (error) {
      toast.error("حدث خطأ ما");
    }
  };

  let validateAbuQirForm = () => {
    const schema = Joi.object({
      isAContribution: Joi.string().required().messages({
        'string.empty': 'يجب تحديد المساهمة',
        'any.required': 'يجب تحديد المساهمة',
      }),
      client_id: Joi.string().required().messages({
        'string.empty': ' رقم القيد  مطلوب',
        'any.required': ' رقم القيد  مطلوب',
      }),
      elements: Joi.any().required(),
      notes: Joi.string().empty(''),
      isReceived: Joi.boolean()

    });
    return schema.validate(abuQirForms, { abortEarly: false });
  };
  let submitAbuQirForm = (e) => {
    setIsLoading(true);
    e.preventDefault();
    let validation = validateAbuQirForm();
    if (!validation.error) {
      sendAbuQirFormsDataToApi();
    } else {
      setIsLoading(false);
      try {
        validation?.error?.details?.map((err) => {
          toast.error(err.message);
        })
      } catch (e) {
        toast.error("حدث خطأ ما");
      }
    }
  };
  //abu qir bill modal 
  let [openBillModal, setOpenBillModal] = useState(false)

  function closeModal() {
    setOpenBillModal(false);
  };

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Add AbuQirClients</title>
      </Helmet>
      <h3 className='alert alert-primary text-center mx-5 my-2  fw-bold'>إضافة إستمارة </h3>
      <div className="mx-5 p-3 rounded rounded-3 bg-white">

        <form ref={formRef} onSubmit={submitAbuQirForm} >
          <div className="row gy-3">
            <div className="col-md-4">
              <label htmlFor="client_id" className='form-label'>  رقم قيد العميل</label>
              <Select
                ref={clientSelectRef}
                name="client_id"
                options={clientOptions}
                onChange={getSelectedClient}
                isSearchable={true}
                placeholder="بحث  ..."

              />
            </div>
            {/* bills modal */}
            {(openBillModal && clientBillsData?.count > 0) ? (
              <div className="modal fade show d-block">
                <div className="modal-dialog">
                  <div className="modal-content">
                    <div className="modal-header">
                      <h5 className='text-danger m-auto'>بيانات العميل</h5>
                    </div>
                    <div className="modal-body">
                      <p> <strong>اسم العميل: </strong> {clientData?.client_name}</p>
                      <p className='bg-danger-subtle'>  هذ العميل لنا عنده {clientBillsData?.count} فواتير ، الرجاء الذهاب لشاشة "لنا فواتير" لتغيير حالتها إذا وصلت.</p>
                    </div>
                    <div className="modal-footer">
                      <button type="button" className="btn btn-secondary" onClick={closeModal}>
                        غلق
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              clientBillsData?.count === 0 && (
                <div className='col-md-4 mt-5 bg-danger-subtle rounded'>
                  <p> <strong>اسم العميل: </strong> {clientData?.client_name}</p>
                </div>
              )
            )}

            <div className="col-md-4">
              <label htmlFor="status_id" className='form-label'>مساهمة؟ </label>
              <select name="isAContribution" defaultValue={0} className='form-control'
                onChange={getInputValue}>
                <option value={0} hidden disabled>اختر...</option>
                <option value={1}>نعم</option>
                <option value={0}>لا</option>

              </select>
            </div>
            <div className="col-md-4">
              <label htmlFor="elemnts" className='form-label'>عناصر الإستمارة </label>
              {abuQirForms.elements.map((element, index) => (
                <div key={index} className="d-flex my-2">
                  <input
                    type='text'
                    className='form-control'
                    name={`elements[${index}]`}
                    onChange={(e) => handleElementChange(index, e.target.value)}
                  />
                  <button
                    type="button"
                    className="btn btn-danger mx-2"
                    onClick={() => removeElementField(index)}
                    disabled={abuQirForms.elements.length === 1}
                  >
                    -
                  </button>
                </div>
              ))}
              <button type="button" className="btn btn-success" onClick={addElementField}>
                +
              </button>

            </div>
            {elmentsChanged && <div className="col-md-4 ">
              <div className='bg-danger-subtle p-2 rounded'>
                <div>الأجمالي : {totalValue}</div>
                <div>الباقي : {remainingValue}</div>
              </div>
            </div>}
            <div className="col-md-12">
              <label htmlFor="notes" className='form-label'>ملاحظات</label>
              <textarea
                name="notes"
                className='form-control'
                onChange={getInputValue} />
            </div>

            <div className="col-md-3">
              <button type='submit' className='btn btn-primary form-control fs-5'>
                {isLoading == true ? <i className='fa fa-spinner fa-spin'></i> : 'إضافة'}
              </button>
            </div>

          </div>
        </form >

      </div >
    </>
  )
}

