
import axios from 'axios';
import React, { useContext, useEffect, useState } from 'react'
import { Helmet } from 'react-helmet-async';
import { NavLink } from 'react-router-dom';
import { AuthContext } from '../../Context/AuthStore';
import { toast } from 'react-toastify';
import EditePayBatch from './EditePayBatch';
import Pagination from '../Pagination/Pagination';


export default function PayBatchs() {
    let { accessToken } = useContext(AuthContext);
    const currentPath = window.location.pathname;
    let [pagination, setPagination] = useState(null);
    let [currentPage, setCurrentPage] = useState(1); // Current page state
    let [payBatchsData, setPayBatchsData] = useState([]);
    let getPayBatchsData = async (page = 1) => {
        try {
            let { data } = await axios.get(`${process.env.REACT_APP_API_URL}/api/payBatchs?page=${page}`, {
                headers: {
                    "Authorization": `Bearer ${accessToken}`
                }
            });      
            setPayBatchsData(data.data);
            setPagination(data.meta); // Set pagination data
            setCurrentPage(page); // Set current page
        } catch (error) {
            toast.error('حدث خطأ ما.')
        }
    };
    useEffect(() => { getPayBatchsData() },
        []);
    let handlePageChange = (page) => {
        getPayBatchsData(page);
    };
    let deleteBatch = async (batchId) => {
        try {
            let { data } = await axios.delete(`${process.env.REACT_APP_API_URL}/api/payBatchs/delete/${batchId}`, {
                headers: {
                    "Authorization": `Bearer ${accessToken}`
                }
            });
            toast.success(data.message);
            getPayBatchsData()

        } catch (error) {
            toast.error('حدث خطأ ما، حاول مرة أخرى')
        }
    };
    let [isReached, setIsReached] = useState({
        isReached: true
    });
    // for making isReached for post
    let makeisReachedForPost = async (batchId) => {
        alert('هل وصل الكلوز باتش إلى البنك؟ ')
        await axios.put(`${process.env.REACT_APP_API_URL}/api/payBatchs/${batchId}`, isReached, {
            headers: {
                "Authorization": `Bearer ${accessToken}`
            }
        }).then((res) => {
            toast.success(res.data.message);
            getPayBatchsData()
        }).catch((errors) => {
            toast.error('حدث خطأ ما');
        })
    }
    let [batchId, setBatchId] = useState('');
    let showPayBatchData = () => {
        if (payBatchsData.length > 0) {
            return (
                <div className="shadow rounded rounded-4 bg-white mx-3 p-3 table-responsive">
                    <table dir="rtl" className='table table-bordered table-hover align-middle text-center table-responsive-list '>
                        <thead className='table-primary   no-wrap-heading'>
                            <tr>
                                <th>تاريخ الإنشاء</th>
                                <th>قيمة الكلوز باتش</th>
                                <th>رقم الريسيت </th>
                                <th> وصل البنك </th>
                                {(currentPath === '/admin/pay-batchs') && (
                                    <>
                                        <th>تغيير حالته</th>
                                        <th>خيارات</th>
                                    </>
                                )}
                            </tr>
                        </thead>
                        <tbody>
                            {payBatchsData.map((batch) => <tr key={batch.id}>
                                <td data-label="تاريخ الإنشاء">{batch?.created_at}</td>
                                <td data-label="قيمة الكلوز باتش">{batch?.value}</td>
                                <td data-label="رقم الريسيت">{batch?.number}</td>
                                <td data-label="وصل البنك؟">
                                    {batch.isReached == false ? 'لا' : 'نعم'}
                                </td>
                                {(currentPath === '/admin/pay-batchs') && (
                                    <>
                                        <td data-label="تغيير حالته" >
                                            <i className='bi bi-check2-circle  fs-5' onClick={() => {
                                            makeisReachedForPost(batch.id)
                                        }}></i>
                                        </td>
                                        <td data-label="خيارات">
                                            <div className="dropdown">
                                                <i className='bi bi-pencil-square text-primary fs-5 mx-1' onClick={() => {
                                                    setBatchId(batch.id)
                                                }} data-bs-toggle="modal" data-bs-target="#editeBatch"> </i>
                                                <span data-bs-toggle="dropdown" aria-expanded="false">
                                                    <i className='bi bi-trash text-danger fs-5'></i>
                                                </span>
                                                <ul className="dropdown-menu">
                                                    <li>
                                                        <button className="dropdown-item" onClick={() => {
                                                            deleteBatch(batch.id)
                                                        }}>حذف
                                                        </button>
                                                    </li>
                                                </ul>
                                            </div>
                                        </td>
                                    </>
                                )}

                            </tr>
                            )}
                        </tbody>
                    </table>

                </div>
            )
        } else {
            return (
                <div className='d-flex justify-content-center  height-calc-70 align-items-center' >
                    <i className='fa fa-spinner fa-spin  fa-3x'></i>
                </div>
            )
        }
    };



    return (
        <>
            <Helmet>
                <meta charSet="utf-8" />
                <title> PayBatchs</title>
            </Helmet>
            <div className=" my-3 text-center row mx-2 " dir='rtl'>
                <div className="col-md-6">
                    {/* Render NavLink based on the current URL path */}
                    {currentPath === '/admin/pay-batchs' && (
                        <NavLink to='/admin/pay-batchs/add' className='btn btn-primary my-2 '>إضافة كلوز باتش</NavLink>
                    )}
                    {currentPath === '/doctor/pay-batchs' && (
                        <NavLink to='/doctor/pay-batchs/add' className='btn btn-primary my-2 '>إضافة كلوز باتش</NavLink>
                    )}
                    {currentPath === '/casher/pay-batchs' && (
                        <NavLink to='/casher/pay-batchs/add' className='btn btn-primary my-2 '>إضافة كلوز باتش</NavLink>
                    )}
                    {currentPath === '/purchases/pay-batchs' && (
                        <NavLink to='/purchases/pay-batchs/add' className='btn btn-primary my-2 '>إضافة كلوز باتش</NavLink>
                    )}
                    {currentPath === '/branch-manager/pay-batchs' && (
                        <NavLink to='/branch-manager/pay-batchs/add' className='btn btn-primary my-2 '>إضافة كلوز باتش</NavLink>
                    )}
                </div>
            </div>
            <div className="text-center mb-2">
                <Pagination pagination={pagination} currentPage={currentPage} handlePageChange={handlePageChange} />
            </div>
            {showPayBatchData()}
            {/* edite batch modal */}
            <div dir='rtl' className="modal fade" id="editeBatch" tabIndex={-1}
                aria-labelledby="editeBatchLabel" aria-hidden="true">
                <div className="modal-dialog  modal-xl">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h1 className="modal-title text-danger fs-5  m-auto" id="editeBatchLabel"> تعديل
                                الكلوز باتش </h1>
                        </div>
                        <div className="modal-body">
                            <EditePayBatch batchId={batchId} getPayBatchsData={getPayBatchsData} />
                        </div>
                        <div className="m-2">
                            <button type="button" className="btn btn-secondary ms-3" data-bs-dismiss="modal">غلق
                            </button>
                        </div>
                    </div>
                </div>
            </div>

        </>)
}
