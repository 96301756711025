import axios from 'axios';
import Joi from 'joi';
import React, { useContext,  useRef, useState } from 'react'
import { Helmet } from 'react-helmet-async';
import { toast } from 'react-toastify';
import { AuthContext } from '../../Context/AuthStore';

export default function AddComment({postId , getPostsData}) {
    let { accessToken } = useContext(AuthContext);
    const formRef = useRef(null);
    let [isLoading, setIsLoading] = useState(false);
    let [comment, setComment] = useState({
        body: ''
    });
    let handleInputChange = (event) => {
        const { name, value } = event.target;
        setComment(prevState => ({ ...prevState, [name]: value }));
    };
    let sendCommentDataToApi = async () => {
        try {
            await axios.post(`${process.env.REACT_APP_API_URL}/api/comments/addcomment/${postId}`, comment, {
                headers: {
                    "Authorization": `Bearer ${accessToken}`
                }
            }).then((res) => {
                formRef.current.reset();
                toast.success(res.data.message, {
                    position: 'top-center'
                });
                setIsLoading(false);
                getPostsData()
            }).catch((errors) => {                
                setIsLoading(false);
                const errorList = errors?.response?.data?.message;
                if (errorList !== undefined) {
                    Object.keys(errorList)?.map((err) => {
                        errorList[err]?.map((err) => {
                            toast.error(err);
                        })
                    });
                } else {
                    toast.error("حدث خطأ ما");
                }
            });
        } catch (error) {
            toast.error("حدث خطأ ما");
        }
    };

    let validateCommentForm = () => {
        const schema = Joi.object({
            body: Joi.string().required(),
        });
        return schema.validate(comment, { abortEarly: false });
    };
    let submitCommentForm = (e) => {
        setIsLoading(true);
        e.preventDefault();
        let validation = validateCommentForm();
        if (!validation.error) {
            sendCommentDataToApi();
        } else {
            setIsLoading(false);
            try {
                validation?.error?.details?.map((err) => {
                    toast.error(err.message);
                })
            } catch (e) {
                toast.error("حدث خطأ ما");
            }
        }
    };

    return (
        <>
            <div className="mx-5 p-3 rounded rounded-3 bg-white">
                <form ref={formRef} onSubmit={submitCommentForm} >
                    <div className="row gy-3">
                    <div className="col-md-12">
                            <label htmlFor="body" className='form-label'>التعليق</label>
                            <textarea className='form-control' name="body"
                                onChange={handleInputChange} />
                        </div>
                        <div className="col-md-3">
                            <button type='submit' className='btn btn-primary form-control fs-5'>
                                {isLoading == true ? <i className='fa fa-spinner fa-spin'></i> : 'إضافة'}
                            </button>
                        </div>
                    </div>
                </form >
            </div >
        </>
    )
}
