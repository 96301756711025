import axios from 'axios';
import React, { useContext, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { NavLink, useParams } from 'react-router-dom';
import { AuthContext } from '../../Context/AuthStore';
import { toast } from 'react-toastify';
import Pagination from '../Pagination/Pagination';

export default function DeliveryOrders() {
  let { accessToken } = useContext(AuthContext);
  let [pagination, setPagination] = useState(null);
  let [currentPage, setCurrentPage] = useState(1); // Current page state
  let { id } = useParams();
  let [orders, setOrders] = useState([]);
  let [searchText, setSearchText] = useState('');
  function handleSearchChange(event) {
    setSearchText(event.target.value)
  };
  let getOrderData = async (page = 1) => {
    let searchResult;
    if (searchText !== undefined && searchText.trim().length > 0) {
      try {
        searchResult = await axios.get(`${process.env.REACT_APP_API_URL}/api/orders/user/${id}?key=${searchText.trim()}&page=${page}`, {
          headers: {
            "Authorization": `Bearer ${accessToken}`
          }
        });
       
        
        setOrders(searchResult.data.data);
        setPagination(searchResult.data.meta); // Set pagination data
        setCurrentPage(page); // Set current page
      } catch (error) {
        toast.error('حدث خطأ ما')
      }
    } else {
      try {
        searchResult = await axios.get(`${process.env.REACT_APP_API_URL}/api/orders/user/${id}?page=${page}`, {
          headers: {
            "Authorization": `Bearer ${accessToken}`
          }
        });
        setOrders(searchResult.data.data);
        setPagination(searchResult.data.meta); // Set pagination data
        setCurrentPage(page); // Set current page
      } catch (error) {
        toast.error('حدث خطأ ما')
      }
    }
  };

  useEffect(() => {
    getOrderData();
  }, [searchText]);
  //for handle page change
  let handlePageChange = (page) => {
    getOrderData(page);
  };

  //get total money with the delivery from all orders
  let [unpaidAmount, setUnpaidAmmount] = useState([]);
  let getUnpiadAmmountForDelivery = async () => {
    try {
      let { data } = await axios.get(`${process.env.REACT_APP_API_URL}/api/users/show/${id}`, {
        headers: {
          "Authorization": `Bearer ${accessToken}`
        }
      });
      setUnpaidAmmount(data.data)
    } catch (error) {
      toast.error('حدث خطأ ما')
    }
  };
  useEffect(() => {
    getUnpiadAmmountForDelivery()
  }, []);
  //for delivary paid for himself
  let sendDelavaryPaidsToApi = async (ordId) => {
    await axios.put(`${process.env.REACT_APP_API_URL}/api/orders/deliveryOrderPay/${ordId}`, {}, {
      headers: {
        "Authorization": `Bearer ${accessToken}`
      }
    }).then((res) => {
      toast.success(res.data.message);
      getOrderData()
    }).catch((errors) => {
      toast.error('حدث خطأ ما');
      toast.error(errors?.response?.data?.message);
    })
  }
  //paid all money for all orders 
  let makePaidForAllOrders = async () => {
    await axios.put(`${process.env.REACT_APP_API_URL}/api/orders/payAllOrders/${id}`, {}, {
      headers: {
        "Authorization": `Bearer ${accessToken}`
      }
    }).then((res) => {
      toast.success(res.data.message);
      getOrderData()
      getUnpiadAmmountForDelivery()
    }).catch((errors) => {
      toast.error(errors?.response?.data?.message);
    })
  }
  let showOrders = () => {
    if (orders.length > 0) {
      return (
        <div className="shadow rounded rounded-4 bg-white mx-3 p-3 table-responsive ">
          <table dir="rtl" className='table  table-hover text-center align-middle'>
            <thead className='table-primary  no-wrap-heading'>
              <tr>
                <th>كود العميل</th>
                <th> قيمة الأوردر</th>
                <th> ملاحظات </th>
                <th> المنطقة </th>
                <th> نقطة البيع </th>
                <th>سداد</th>
              </tr>
            </thead>
            <tbody>
              {orders.map((order) => <tr key={order.id}>
                <td >{order?.customer?.code}</td>
                <td >{order?.cost}</td>
                <td >{order?.notes}</td>
                <td >{order?.area?.name}</td>
                <td >{order?.sale_point?.name}</td>
                <td >
                  {order.unpaid ? <i className='bi bi-x-circle-fill text-danger fs-4'
                    onClick={() => sendDelavaryPaidsToApi(order.id)} ></i>
                    : <i className='bi bi-check-circle-fill text-success fs-4' ></i>}
                </td>
                
              </tr>
               
             
              )}
            </tbody>
          </table>
        </div>
      )
    } else {
      return (
        <div className=' d-flex justify-content-center  height-calc-70 align-items-center' >
          <div className='alert alert-danger w-50 text-center'>لا يوجد أوردرات</div>
        </div>)

    }
  };

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Delivery Orders</title>
      </Helmet>
      <div className='container'>
        <div className=" my-3 text-center row   ">

          <div className='col-md-3'>
            <input type="text" className='form-control text-end mt-1 ' placeholder=' ...بحث عن أوردر ' onChange={handleSearchChange} />
          </div>
          <div className="col-md-2">
            <button type="button" className="btn btn-primary mt-1" data-bs-toggle="modal" data-bs-target="#exampleModal">
              سداد كلي
            </button>
          </div>
          <div className="col-md-4 my-2">
            <NavLink to='/delivery/pop-orders' className='btn btn-warning' > الأوردرات المعلقة  </NavLink>
          </div>
          <div className="col-md-3  bg-secondary-subtle  rounded w-auto  m-auto">
            <span className='text-bg-danger rounded p-1 fw-bolder d-block' >إجمالي المبلغ المطلوب سداده</span>
            <span className='fw-bolder'>{unpaidAmount?.unpaidAmount}</span>
          </div>

        </div>
      </div>
      <div className="text-center mb-3">
        <Pagination pagination={pagination} currentPage={currentPage} handlePageChange={handlePageChange} />
      </div>
      {/* paid modal  */}

      <div dir='rtl' className="modal fade" id="exampleModal" tabIndex={-1} aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h1 className="modal-title text-danger fs-5" id="exampleModalLabel">  هل أنت متأكد من تسديد المبلغ كاملا؟    </h1>
            </div>
            <div className="modal-body">
              <div className="my-2">
                <button type="button" className="btn btn-secondary ms-3" data-bs-dismiss="modal">غلق</button>
                <button type="button" className="btn btn-primary"  onClick={() => { makePaidForAllOrders() }}>تأكيد </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      {showOrders()}
    </>
  )
}
