import React, { useContext, useEffect, useState } from 'react';
import { AuthContext } from '../../Context/AuthStore';
import axios from 'axios';
import { toast } from 'react-toastify';
import Select from 'react-select';
import Joi from 'joi';
import { Document, Page, Text, View, StyleSheet, pdf, Font } from '@react-pdf/renderer';
import { saveAs } from 'file-saver';
import { Helmet } from 'react-helmet-async';


export default function Reports() {
    let { accessToken } = useContext(AuthContext);
    let [isLoading, setIsLoading] = useState(false);
    let [reportAll, setReportAll] = useState(false);
    let [attendanceRecordsReportsForOneEmployee, setAttendanceRecordsReportsForOneEmployee] = useState([]);
    let [oneEmployeeReportData, setOneEmployeeReportData] = useState([]);
    let [monthlyAccountReportForOneEmployee, setMonthlyAccountReportForOneEmployee] = useState([]);
    let [countOfAttendanceDaysForOneEmployee, setCountOfAttendanceDaysForOneEmployee] = useState([]);
    let [allReportsData, setAllReportsData] = useState([]);
    let [employees, setEmployees] = useState([]);
    let [employeesOptions, setEmployeesOptions] = useState([]);
    let [reports, setReports] = useState({
        month_year: '',
        id: '',
    });
    // Fetch Employees Data
    let getEmployeesData = async () => {
        try {
            let { data } = await axios.get(`${process.env.REACT_APP_API_URL}/api/employees?noPaginate=1`, {
                headers: {
                    "Authorization": `Bearer ${accessToken}`
                }
            });
            setEmployees(data.data);
        } catch (error) {
            toast.error('حدث خطأ ما.');
        }
    };

    useEffect(() => {
        getEmployeesData();
    }, []);

    useEffect(() => {
        let mapEmployees = employees?.map((emp) => ({
            value: `${emp.id}`,
            label: `${emp.name}`,
        }));
        setEmployeesOptions(mapEmployees);
    }, [employees]);

    let handleInputChange = (event) => {
        const { name, value } = event.target;
        setReports(prevState => ({ ...prevState, [name]: value }));
    };

    let handleCheckboxChange = (e) => {
        setReportAll(e.target.checked);
        if (e.target.checked) {
            setReports(prevState => ({ ...prevState, id: 'all' }));
        } else {
            setReports(prevState => ({ ...prevState, id: '' }));
        }
    };

    let sendReportsDataToApi = async () => {
        try {
            await axios.post(`${process.env.REACT_APP_API_URL}/api/employees/accountingReports`, reports, {
                headers: {
                    "Authorization": `Bearer ${accessToken}`
                }
            }).then((res) => {
                if (reports.id !== 'all') {
                    setAttendanceRecordsReportsForOneEmployee(res.data[0].attendanceRecords);
                    setOneEmployeeReportData(res.data[0].employee)
                    setMonthlyAccountReportForOneEmployee(res.data[0].monthlyAccount)
                    setCountOfAttendanceDaysForOneEmployee(res.data[0].countOfAttendanceDays)
                } else {
                    setAllReportsData(res.data)
                }
                setIsLoading(false);
            }).catch((errors) => {
                setIsLoading(false);
                const errorList = errors?.response?.data?.message;
                if (errorList !== undefined) {
                    Object.keys(errorList)?.map((err) => {
                        errorList[err]?.map((err) => {
                            toast.error(err);
                        });
                    });
                } else {
                    toast.error("حدث خطأ ما");
                }
            });
        } catch (error) {
            toast.error("حدث خطأ ما");
        }
    };

    let validateReportForm = () => {
        const schema = Joi.object({
            month_year: Joi.string().required(),
            id: Joi.string().required(),
        });
        return schema.validate(reports, { abortEarly: false });
    };

    let submitReportForm = (e) => {
        setIsLoading(true);
        e.preventDefault();
        let validation = validateReportForm();
        if (!validation.error) {
            sendReportsDataToApi();
        } else {
            setIsLoading(false);
            try {
                validation.error.details.map((err) => {
                    toast.error(err.message);
                });
            } catch (e) {
                toast.error("حدث خطأ ما");
            }
        }
    };

    // Register Arabic font
    Font.register({
        family: 'Amiri',
        src: 'https://fonts.gstatic.com/s/amiri/v14/J7aRnpd8CGxBHqUpvrIw74NL.ttf',
    });

    const styles = StyleSheet.create({
        page: {
            padding: 3,
        },
        header: {
            marginBottom: 4,
            color: '#130699'
        },
        headerText: {
            fontSize: 9,
            fontFamily: 'Amiri',
            margin: 'auto',

        },
        table: {
            display: 'table',
            width: '100%',
            borderStyle: 'solid',
            borderColor: '#000',
            borderWidth: 1,
            borderRightWidth: 0,
            borderBottomWidth: 0,
        },
        tableRow: {
            flexDirection: 'row-reverse',
        },
        tableCol: {
            width: '7%',
            borderStyle: 'solid',
            borderColor: '#000',
            borderWidth: 1,
            borderLeftWidth: 0,
            borderTopWidth: 0,
            textAlign: 'center',
            justifyContent: 'center',
            alignItems: 'center',
        },
        tableColSmall: {
            width: '4%',
            borderStyle: 'solid',
            borderColor: '#000',
            borderWidth: 1,
            borderLeftWidth: 0,
            borderTopWidth: 0,
            textAlign: 'center',
            justifyContent: 'center',
            alignItems: 'center',
        },
        tableCell: {
            margin: 'auto',
            marginTop: 3,
            fontSize: 7,
            fontFamily: 'Amiri',
        },
    });

    // Helper function to convert time format to 12-hour with AM/PM
    const formatTime = (time) => {
        if (!time) return '';
        let [hours, minutes, seconds] = time.split(':');
        let period = 'AM';
        hours = parseInt(hours);
        if (hours >= 12) {
            period = 'PM';
            if (hours > 12) hours -= 12;
        } else if (hours === 0) {
            hours = 12;
        }
        return `${hours}:${minutes} ${period}`;
    };
    const getDaysInMonth = (year, month) => {
        return new Date(year, month + 1, 0).getDate(); // الحصول على اليوم الأخير من الشهر المحدد
    };

    const generateOneEmployeeReportsPDF = () => {
        let numOfAttendanceDay = countOfAttendanceDaysForOneEmployee;
        let workingHours = monthlyAccountReportForOneEmployee?.working_hours;
        let additionalHours = monthlyAccountReportForOneEmployee?.additional_hours;
        let totalHours = (parseFloat(workingHours) + parseFloat(additionalHours)).toFixed(2);
        let constantIncentive = oneEmployeeReportData?.constantIncentive;
        let incentive = monthlyAccountReportForOneEmployee?.incentives;
        let baseSalary = monthlyAccountReportForOneEmployee?.base_salary;
        let totalDeductions = monthlyAccountReportForOneEmployee?.total_deductions;
        if (workingHours > 208) {
            workingHours = 208;
        }
        let part = constantIncentive / 208;
        let constantIncentiveValue = (workingHours * part).toFixed(2);
        let totalEntitlements = (parseFloat(baseSalary) + parseFloat(constantIncentiveValue) + parseFloat(incentive)).toFixed(2);
        let netSalary = (parseFloat(totalEntitlements) - parseFloat(totalDeductions));
        let currentYear = monthlyAccountReportForOneEmployee.year;
        let currentMonth = (monthlyAccountReportForOneEmployee.month) - 1;
        const daysInMonth = getDaysInMonth(currentYear, currentMonth);
        const numOfAbsenceDays = daysInMonth - numOfAttendanceDay;


        return (
            <Document>
                <Page size="A4" style={styles.page}>
                    <View style={styles.header}>
                        <Text style={styles.headerText}>اسم الموظف: {oneEmployeeReportData?.name} ، كود الموظف: {oneEmployeeReportData?.code} ، الوظيفة: {oneEmployeeReportData?.category?.name}</Text>
                    </View>
                        
                        <View style={styles.table}>
                            <View style={styles.tableRow}>
                                <View style={styles.tableColSmall}><Text style={styles.tableCell}> كود  </Text></View>
                                <View style={styles.tableCol}><Text style={styles.tableCell}>تار يخ </Text></View>
                                <View style={styles.tableCol}><Text style={styles.tableCell}> الحضور</Text></View>
                                <View style={styles.tableCol}><Text style={styles.tableCell}> الإنصراف</Text></View>
                                <View style={styles.tableColSmall}><Text style={styles.tableCell}>تأخير</Text></View>
                                <View style={styles.tableCol}><Text style={styles.tableCell}>عدد الساعات</Text></View>
                                <View style={styles.tableCol}><Text style={styles.tableCell}>ساعات إضافية</Text></View>
                                <View style={styles.tableCol}><Text style={styles.tableCell}>صافي ساعات</Text></View>
                                <View style={styles.tableCol}><Text style={styles.tableCell}>إجمالي الراتب</Text></View>
                                <View style={styles.tableCol}><Text style={styles.tableCell}>حافز ثابت</Text></View>
                                <View style={styles.tableColSmall}><Text style={styles.tableCell}>حوافز</Text></View>
                                <View style={styles.tableCol}><Text style={styles.tableCell}>مستحق</Text></View>
                                <View style={styles.tableCol}><Text style={styles.tableCell}>ساعات خصم</Text></View>
                                <View style={styles.tableCol}><Text style={styles.tableCell}>جزاءات</Text></View>
                                <View style={styles.tableCol}><Text style={styles.tableCell}>مديونية</Text></View>
                                <View style={styles.tableCol}><Text style={styles.tableCell}>مستقطع</Text></View>
                                <View style={styles.tableCol}><Text style={styles.tableCell}>صافي الراتب</Text></View>
                            </View>
                            {attendanceRecordsReportsForOneEmployee?.map((record) => (
                                <View key={record?.id} style={styles.tableRow}>
                                    <View style={styles.tableColSmall}><Text style={styles.tableCell}>{oneEmployeeReportData?.code}</Text></View>
                                    <View style={styles.tableCol}><Text style={styles.tableCell}>{record.date}</Text></View>
                                    <View style={styles.tableCol}><Text style={styles.tableCell}>{formatTime(record.attendance_time)}</Text></View>
                                    <View style={styles.tableCol}><Text style={styles.tableCell}>{formatTime(record.departure_time)}</Text></View>
                                    <View style={styles.tableColSmall}><Text style={styles.tableCell}>{record.lateHours}</Text></View>
                                    <View style={styles.tableCol}><Text style={styles.tableCell}>{record.working_hours}</Text></View>
                                    <View style={styles.tableCol}><Text style={styles.tableCell}></Text></View>
                                    <View style={styles.tableCol}><Text style={styles.tableCell}></Text></View>
                                    <View style={styles.tableCol}><Text style={styles.tableCell}></Text></View>
                                    <View style={styles.tableCol}><Text style={styles.tableCell}></Text></View>
                                    <View style={styles.tableColSmall}><Text style={styles.tableCell}></Text></View>
                                    <View style={styles.tableCol}><Text style={styles.tableCell}></Text></View>
                                    <View style={styles.tableCol}><Text style={styles.tableCell}></Text></View>
                                    <View style={styles.tableCol}><Text style={styles.tableCell}></Text></View>
                                    <View style={styles.tableCol}><Text style={styles.tableCell}></Text></View>
                                    <View style={styles.tableCol}><Text style={styles.tableCell}></Text></View>
                                    <View style={styles.tableCol}><Text style={styles.tableCell}></Text></View>
                                </View>
                            ))}
                            <View style={styles.tableRow}>
                                <View style={styles.tableColSmall}><Text style={styles.tableCell}></Text></View>
                                <View style={styles.tableCol}><Text style={styles.tableCell}></Text></View>
                                <View style={styles.tableCol}><Text style={styles.tableCell}></Text></View>
                                <View style={styles.tableCol}><Text style={styles.tableCell}></Text></View>
                                <View style={styles.tableColSmall}><Text style={styles.tableCell}></Text></View>
                                <View style={styles.tableCol}><Text style={styles.tableCell}>{workingHours}</Text></View>
                                <View style={styles.tableCol}><Text style={styles.tableCell}>{additionalHours}</Text></View>
                                <View style={styles.tableCol}><Text style={styles.tableCell}>{totalHours}</Text></View>
                                <View style={styles.tableCol}><Text style={styles.tableCell}>{baseSalary}</Text></View>
                                <View style={styles.tableCol}><Text style={styles.tableCell}>{constantIncentiveValue}</Text></View>
                                <View style={styles.tableColSmall}><Text style={styles.tableCell}>{incentive}</Text></View>
                                <View style={styles.tableCol}><Text style={styles.tableCell}>{totalEntitlements}</Text></View>
                                <View style={styles.tableCol}><Text style={styles.tableCell}>{monthlyAccountReportForOneEmployee?.discounted_hours}</Text></View>
                                <View style={styles.tableCol}><Text style={styles.tableCell}>{monthlyAccountReportForOneEmployee?.cash_penalties}</Text></View>
                                <View style={styles.tableCol}><Text style={styles.tableCell}>{monthlyAccountReportForOneEmployee?.debts}</Text></View>
                                <View style={styles.tableCol}><Text style={styles.tableCell}>{totalDeductions}</Text></View>
                                <View style={styles.tableCol}><Text style={styles.tableCell}>{netSalary}</Text></View>
                            </View>
                        </View>
                  
                </Page>
            </Document>
        )
    };

    const generateAllEmployeeReportsPDF = () => (
        <Document>
            {allReportsData.map((rep, index) => {
                let numOfAttendanceDay = rep.countOfAttendanceDays;
                let workingHours = rep.monthlyAccount?.working_hours;
                let additionalHours = rep.monthlyAccount?.additional_hours;
                let totalHours = (parseFloat(workingHours) + parseFloat(additionalHours)).toFixed(2);
                let constantIncentive = rep.employee?.constantIncentive;
                let incentive = rep.monthlyAccount?.incentives;
                let baseSalary = rep.monthlyAccount?.base_salary;
                let totalDeductions = rep.monthlyAccount?.total_deductions;
                if (workingHours > 208) {
                    workingHours = 208;
                }
                let part = constantIncentive / 208;
                let constantIncentiveValue = (workingHours * part).toFixed(2);
                let totalEntitlements = (parseFloat(baseSalary) + parseFloat(constantIncentiveValue) + parseFloat(incentive)).toFixed(2);
                let netSalary = (parseFloat(totalEntitlements) - parseFloat(totalDeductions)).toFixed(2);
                let hourRateAverageForDelivery = (parseFloat(totalEntitlements) / totalHours).toFixed(2);
                let hourRateAverageForAllExceptDelivery = ((parseFloat(totalEntitlements) * 26) / totalHours).toFixed(2);
                let currentYear = rep.monthlyAccount.year;
                let currentMonth = (rep.monthlyAccount?.month) - 1;
                const daysInMonth = getDaysInMonth(currentYear, currentMonth);
                const numOfAbsenceDays = daysInMonth - numOfAttendanceDay;

                return (
                    <Page size="A4" style={styles.page} key={index}>
                        <View>
                            <View style={styles.header}>
                                <Text style={styles.headerText}> {rep.monthlyAccount.year} / {rep.monthlyAccount?.month} ،  اسم الموظف : {rep.employee?.name} ، متوسط سعر الساعة :  {rep.employee?.category?.name == 'طيار' ? hourRateAverageForDelivery : hourRateAverageForAllExceptDelivery}</Text>
                            </View> 
                            <View style={styles.table}>
                                <View style={styles.tableRow}>
                                    <View style={styles.tableColSmall}><Text style={styles.tableCell}> كود  </Text></View>
                                    <View style={styles.tableCol}><Text style={styles.tableCell}>تار يخ </Text></View>
                                    <View style={styles.tableCol}><Text style={styles.tableCell}> الحضور</Text></View>
                                    <View style={styles.tableCol}><Text style={styles.tableCell}> الإنصراف</Text></View>
                                    <View style={styles.tableColSmall}><Text style={styles.tableCell}>تأخير</Text></View>
                                    <View style={styles.tableCol}><Text style={styles.tableCell}>عدد الساعات</Text></View>
                                    <View style={styles.tableCol}><Text style={styles.tableCell}>ساعات إضافية</Text></View>
                                    <View style={styles.tableCol}><Text style={styles.tableCell}>صافي ساعات</Text></View>
                                    <View style={styles.tableCol}><Text style={styles.tableCell}>إجمالي الراتب</Text></View>
                                    <View style={styles.tableCol}><Text style={styles.tableCell}>حافز ثابت</Text></View>
                                    <View style={styles.tableColSmall}><Text style={styles.tableCell}>حوافز</Text></View>
                                    <View style={styles.tableCol}><Text style={styles.tableCell}>مستحق</Text></View>
                                    <View style={styles.tableCol}><Text style={styles.tableCell}>ساعات خصم</Text></View>
                                    <View style={styles.tableCol}><Text style={styles.tableCell}>جزاءات</Text></View>
                                    <View style={styles.tableCol}><Text style={styles.tableCell}>مديونية</Text></View>
                                    <View style={styles.tableCol}><Text style={styles.tableCell}>مستقطع</Text></View>
                                    <View style={styles.tableCol}><Text style={styles.tableCell}>صافي الراتب</Text></View>
                                </View>
                                {rep.attendanceRecords?.map((record) => (
                                    <View key={record.id} style={styles.tableRow}>
                                        <View style={styles.tableColSmall}><Text style={styles.tableCell}>{rep.employee?.code}</Text></View>
                                        <View style={styles.tableCol}><Text style={styles.tableCell}>{record.date}</Text></View>
                                        <View style={styles.tableCol}><Text style={styles.tableCell}>{formatTime(record.attendance_time)}</Text></View>
                                        <View style={styles.tableCol}><Text style={styles.tableCell}>{formatTime(record.departure_time)}</Text></View>
                                        <View style={styles.tableColSmall}><Text style={styles.tableCell}>{record.lateHours}</Text></View>
                                        <View style={styles.tableCol}><Text style={styles.tableCell}>{record.working_hours}</Text></View>
                                        <View style={styles.tableCol}><Text style={styles.tableCell}></Text></View>
                                        <View style={styles.tableCol}><Text style={styles.tableCell}></Text></View>
                                        <View style={styles.tableCol}><Text style={styles.tableCell}></Text></View>
                                        <View style={styles.tableCol}><Text style={styles.tableCell}></Text></View>
                                        <View style={styles.tableColSmall}><Text style={styles.tableCell}></Text></View>
                                        <View style={styles.tableCol}><Text style={styles.tableCell}></Text></View>
                                        <View style={styles.tableCol}><Text style={styles.tableCell}></Text></View>
                                        <View style={styles.tableCol}><Text style={styles.tableCell}></Text></View>
                                        <View style={styles.tableCol}><Text style={styles.tableCell}></Text></View>
                                        <View style={styles.tableCol}><Text style={styles.tableCell}></Text></View>
                                        <View style={styles.tableCol}><Text style={styles.tableCell}></Text></View>
                                    </View>
                                ))}
                                <View style={styles.tableRow}>
                                    <View style={styles.tableColSmall}><Text style={styles.tableCell}></Text></View>
                                    <View style={styles.tableCol}><Text style={styles.tableCell}></Text></View>
                                    <View style={styles.tableCol}><Text style={styles.tableCell}></Text></View>
                                    <View style={styles.tableCol}><Text style={styles.tableCell}></Text></View>
                                    <View style={styles.tableColSmall}><Text style={styles.tableCell}></Text></View>
                                    <View style={styles.tableCol}><Text style={styles.tableCell}>{workingHours}</Text></View>
                                    <View style={styles.tableCol}><Text style={styles.tableCell}>{additionalHours}</Text></View>
                                    <View style={styles.tableCol}><Text style={styles.tableCell}>{totalHours}</Text></View>
                                    <View style={styles.tableCol}><Text style={styles.tableCell}>{baseSalary}</Text></View>
                                    <View style={styles.tableCol}><Text style={styles.tableCell}>{constantIncentiveValue}</Text></View>
                                    <View style={styles.tableColSmall}><Text style={styles.tableCell}>{incentive}</Text></View>
                                    <View style={styles.tableCol}><Text style={styles.tableCell}>{totalEntitlements}</Text></View>
                                    <View style={styles.tableCol}><Text style={styles.tableCell}>{rep.monthlyAccount?.discounted_hours}</Text></View>
                                    <View style={styles.tableCol}><Text style={styles.tableCell}>{rep.monthlyAccount?.cash_penalties}</Text></View>
                                    <View style={styles.tableCol}><Text style={styles.tableCell}>{rep.monthlyAccount?.debts}</Text></View>
                                    <View style={styles.tableCol}><Text style={styles.tableCell}>{totalDeductions}</Text></View>
                                    <View style={styles.tableCol}><Text style={styles.tableCell}>{netSalary}</Text></View>
                                </View>
                            </View>

                        </View>
                    </Page>
                )
            })}
        </Document>
    );


    return (
        <>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Reports</title>
            </Helmet>
            <div className="m-5 p-3 rounded rounded-3 bg-white">
                <form onSubmit={submitReportForm}>
                    <div className="">
                        <div className="col-md-5">
                            <label htmlFor="additional_hours" className='form-label'>اسم الموظف</label>
                            <Select
                                options={employeesOptions}
                                isSearchable={true}
                                onChange={(selectedOption) => {
                                    if (selectedOption && !reportAll) {
                                        setReports(prevState => ({ ...prevState, id: selectedOption.value }));
                                    }
                                }}
                                placeholder="بحث عن موظف..."
                            />
                        </div>
                        <div className='col-md-5 my-3'>
                            <label className='fw-bold ms-2'>تقارير الكل</label>
                            <input type="checkbox" onChange={handleCheckboxChange} />
                        </div>
                        <div className="col-md-4 my-3">
                            <label htmlFor="month_year" className='form-label'>شهر</label>
                            <input type="month" className='form-control' name="month_year" onChange={handleInputChange} />
                        </div>
                        <div className="col-md-3">
                            <button type='submit' className='btn btn-secondary form-control fs-5'>
                                {isLoading ? <i className='fa fa-spinner fa-spin'></i> : 'بحث'}
                            </button>
                        </div>
                    </div>
                </form>
                {(attendanceRecordsReportsForOneEmployee.length > 0 || Object.keys(oneEmployeeReportData).length > 0 || Object.keys(monthlyAccountReportForOneEmployee).length > 0) && (
                    <div className='text-center'>
                        <button className='btn btn-success m-4' onClick={() => {
                            const pdfContent = generateOneEmployeeReportsPDF();
                            pdf(pdfContent).toBlob().then(blob => {
                                saveAs(blob, 'تقرير.pdf');
                            });
                        }}>طباعة تقرير الموظف</button>
                    </div>
                )}
                {Object.keys(allReportsData).length > 0 && (
                    <div className='text-center'>
                        <button className='btn btn-danger m-4' onClick={() => {
                            const pdfContent = generateAllEmployeeReportsPDF();
                            pdf(pdfContent).toBlob().then(blob => {
                                saveAs(blob, 'تقارير.pdf');
                            });
                        }}>طباعة تقارير الموظفين</button>
                    </div>
                )}
            </div>
        </>
    );
}
