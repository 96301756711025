import { BrowserRouter, Route, Routes } from 'react-router-dom';
import '../../../src/App.css';
import MasterLayout from '../Layouts/MasterLayout';
import NotFound from '../NotFound/NotFound';
import Login from '../Login/Login';
import Clients from '../Clients/Clients';
import { useContext } from 'react';
import ProtectedRoute from '../ProtectedRoute/ProtectedRoute';
import AddClient from '../Clients/AddClient';
import EditeClient from '../Clients/EditeClient';
import ClientDetails from '../Clients/ClientDetails';
import Orders from '../Orders/Orders';
import DeleteOrder from '../Orders/DeleteOrder';
import EditeOrder from '../Orders/EditeOrder';
import OrderDetails from '../Orders/OrderDetails';
import { ToastContainer } from 'react-toastify';
import DeleteClient from '../Clients/DeleteClient';
import { Offline } from 'react-detect-offline';
import { AuthContext } from '../../Context/AuthStore';
import DeliveryLayout from '../Layouts/DeliveryLayout';
import DeliveryOrders from '../Delivery/DeliveryOrders';
import SalePoints from '../SalePoints/SalePoints';
import DoctorLayout from '../Layouts/DoctorLayout';
import DoctorOrders from '../Doctor/DoctorOrders';
import Branches from '../Branches/Branches';
import AddShortComings from '../ShortComings/AddShortComings';
import Purchases from '../Purchases/Purchases';
import PurchasesDeatils from '../Purchases/PurchasesDeatils';
import EditePurchases from '../Purchases/EditePurchases';
import DeletePurchases from '../Purchases/DeletePurchases';
import CustomersService from '../CustomersService/CustomersService';
import AddStatus from '../CustomersService/AddStatus';
import DoctorPurchases from '../Purchases/DoctorPurchases';
import Offers from '../Offers/Offers';
import AddOffer from '../Offers/AddOffer';
import DeleteOffer from '../Offers/DeleteOffer';
import EditeOffer from '../Offers/EditeOffer';
import Companies from '../Companies/Companies';
import AddCompany from '../Companies/AddCompany';
import EditeCompany from '../Companies/EditeCompany';
import Rules from '../Rules/Rules';
import AddRules from '../Rules/AddRules';
import EditeRule from '../Rules/EditeRule';
import InventoryProducts from '../InventoryProducts/InventoryProducts';
import Notifications from '../Notifications/Notifications';
import AddNotification from '../Notifications/AddNotification';
import AddInventoryProducts from '../InventoryProducts/AddInventoryProducts';
import SellingIncentives from '../SellingIncentives/SellingIncentives';
import AddSellingIncentives from '../SellingIncentives/AddSellingIncentives';
import ReasonsOfIncentives from '../SellingIncentives/ReasonsOfIncentives';
import DeleteSellingIncentives from '../SellingIncentives/DeleteSellingIncentives';
import EditeSellingIncentives from '../SellingIncentives/EditeSellingIncentives';
import PurchasesLayout from '../Layouts/PurchasesLayout';
import PurchasesUser from '../PurchasesUser.jsx/Purchases/PurchasesUser';
import Areas from '../Areas/Areas';
import PurchasesNotifications from '../PurchasesUser.jsx/Notifications/PurchasesNotifications';
import Home from '../Admin/Home/Home';
import Users from '../Admin/Users/Users';
import AddUser from '../Admin/Users/AddUser';
import DeleteUser from '../Admin/Users/DeleteUser';
import EditeUser from '../Admin/Users/EditeUser';
import UserDetails from '../Admin/Users/UserDetails';
import WorkPolicies from '../WorkPolicies/WorkPolicies';
import AddWorkPolicies from '../WorkPolicies/AddWorkPolicies';
import EditeWorkPolicies from '../WorkPolicies/EditeWorkPolicies';
import AddPopUpOrders from '../Orders/AddPopUpOrders';
import PopUpOrders from '../Orders/PopUpOrders';
import DeliveryPopUpOrders from '../Delivery/DeliveryPopUpOrders';
import CasherLayout from '../Layouts/CasherLayout';
import AllOrders from '../Casher/AllOrders';
import ClientsData from '../Casher/ClientsData';
import AllUsers from '../Casher/AllUsers';
import AbuQirClients from '../AbuQir/AbuQirClients/AbuQirClients'
import AddAbuQirClients from '../AbuQir/AbuQirClients/AddAbuQirClients'
import AbuQirForms from '../AbuQir/AbuQirForms/AbuQirForms';
import AddAbuQirForms from '../AbuQir/AbuQirForms/AddAbuQirForms';
import ClaimDetails from '../AbuQir/AbuQirForms/ClaimDetails';
import Employees from '../Employees/Employees';
import AddEmployee from '../Employees/AddEmployee';
import AttendanceDeparture from '../AttendanceDeparture/AttendanceDeparture';
import ResetPassword from '../Auth/ResetPassword';
import MonthlyAccounts from '../MonthlyAccounts/MonthlyAccounts';
import AllAttendanceDeparture from '../AttendanceDeparture/AllAttendanceDeparture';
import Reports from '../Reports/Reports';
import SimilarOrders from '../Orders/SimilarOrders';
import AttendanceRegister from '../AttendanceDeparture/AttendanceRegister';
import TransfersProducts from '../CustomersService/TransfersProducts';
import ClientsInterests from '../Clients/ClientsInterests';
import Doctors from '../Doctors/Doctors';
import AddDoctors from '../Doctors/AddDoctors';
import AbuQirBills from '../AbuQir/Bills/AbuQirBills';
import WorkingHoursMoreTwelve from '../AttendanceDeparture/WorkingHoursMoreTwelve';
import BranchManagerLayout from '../Layouts/BranchManagerLayout';



function App() {

  let { saveUserData } = useContext(AuthContext);

  return (
    <>
      <Offline> <div className='offline'>.أنت غير متصل بالإنترنت</div> </Offline>

      <BrowserRouter>
        <Routes>
          {/* login page */}
          <Route index element={<Login saveUserData={saveUserData} />} ></Route>
          {/* master layout -admin- */}
          <Route path='/admin' element={< MasterLayout />}>
            <Route path='home' element={<ProtectedRoute ><Home /></ProtectedRoute>} ></Route>
            <Route path='users' element={<ProtectedRoute ><Users /></ProtectedRoute>} ></Route>
            <Route path='users/add' element={<ProtectedRoute ><AddUser /></ProtectedRoute>} ></Route>
            <Route path='users/delete/:id' element={<ProtectedRoute ><DeleteUser /></ProtectedRoute>} ></Route>
            <Route path='users/edite/:id' element={<ProtectedRoute ><EditeUser /></ProtectedRoute>} ></Route>
            <Route path='users/details/:id' element={<ProtectedRoute ><UserDetails /></ProtectedRoute>} ></Route>
            <Route path='clients' element={<ProtectedRoute ><Clients /></ProtectedRoute>} ></Route>
            <Route path='clients/add' element={<ProtectedRoute ><AddClient /></ProtectedRoute>} ></Route>
            <Route path='clients/delete/:id' element={<ProtectedRoute ><DeleteClient /></ProtectedRoute>} ></Route>
            <Route path='clients/edite/:id' element={<ProtectedRoute ><EditeClient /></ProtectedRoute>} ></Route>
            <Route path='clients/details/:id' element={<ProtectedRoute ><ClientDetails /></ProtectedRoute>} ></Route>
            <Route path='orders' element={<ProtectedRoute ><Orders /></ProtectedRoute>} ></Route>
            <Route path='similar-orders' element={<ProtectedRoute >< SimilarOrders /></ProtectedRoute>} ></Route>
            <Route path='pop-order' element={<ProtectedRoute ><PopUpOrders /></ProtectedRoute>} ></Route>
            <Route path='pop-order/add' element={<ProtectedRoute ><AddPopUpOrders /></ProtectedRoute>} ></Route>
            <Route path='orders/delete/:id' element={<ProtectedRoute ><DeleteOrder /></ProtectedRoute>} ></Route>
            <Route path='orders/edite/:id' element={<ProtectedRoute ><EditeOrder /></ProtectedRoute>} ></Route>
            <Route path='sale-points' element={<ProtectedRoute ><SalePoints /></ProtectedRoute>} ></Route>
            <Route path='branches' element={<ProtectedRoute ><Branches /></ProtectedRoute>} ></Route>
            <Route path='shortcomings/add' element={<ProtectedRoute ><AddShortComings /></ProtectedRoute>} ></Route>
            <Route path='purchases' element={<ProtectedRoute ><Purchases /></ProtectedRoute>} ></Route>
            <Route path='purchases/delete/:id' element={<ProtectedRoute ><DeletePurchases /></ProtectedRoute>} ></Route>
            <Route path='purchases/edite/:id' element={<ProtectedRoute ><EditePurchases /></ProtectedRoute>} ></Route>
            <Route path='purchases/details/:id' element={<ProtectedRoute ><PurchasesDeatils /></ProtectedRoute>} ></Route>
            <Route path='customersservice' element={<ProtectedRoute ><CustomersService /></ProtectedRoute>} ></Route>
            <Route path='add-status' element={<ProtectedRoute ><AddStatus /></ProtectedRoute>} ></Route>
            <Route path='transfers-products' element={<ProtectedRoute >< TransfersProducts /></ProtectedRoute>} ></Route>
            <Route path='offers' element={<ProtectedRoute ><Offers /></ProtectedRoute>} ></Route>
            <Route path='offers/add' element={<ProtectedRoute ><AddOffer /></ProtectedRoute>} ></Route>
            <Route path='offers/delete/:id' element={<ProtectedRoute ><DeleteOffer /></ProtectedRoute>} ></Route>
            <Route path='offers/edite/:id' element={<ProtectedRoute ><EditeOffer /></ProtectedRoute>} ></Route>
            <Route path='companies' element={<ProtectedRoute ><Companies /></ProtectedRoute>} ></Route>
            <Route path='companies/add' element={<ProtectedRoute ><AddCompany /></ProtectedRoute>} ></Route>
            <Route path='companies/edite/:id' element={<ProtectedRoute ><EditeCompany /></ProtectedRoute>} ></Route>
            <Route path='rules' element={<ProtectedRoute ><Rules /></ProtectedRoute>} ></Route>
            <Route path='rules/add' element={<ProtectedRoute ><AddRules /></ProtectedRoute>} ></Route>
            <Route path='rules/edite/:id' element={<ProtectedRoute ><EditeRule /></ProtectedRoute>} ></Route>
            <Route path='inventory-products' element={<ProtectedRoute ><InventoryProducts /></ProtectedRoute>} ></Route>
            <Route path='inventory-products/add' element={<ProtectedRoute ><AddInventoryProducts /></ProtectedRoute>} ></Route>
            <Route path='notifications' element={<ProtectedRoute ><Notifications /></ProtectedRoute>} ></Route>
            <Route path='notifications/add' element={<ProtectedRoute ><AddNotification /></ProtectedRoute>} ></Route>
            <Route path='selling-incentives' element={<ProtectedRoute ><SellingIncentives /></ProtectedRoute>} ></Route>
            <Route path='selling-incentives/add' element={<ProtectedRoute ><AddSellingIncentives /></ProtectedRoute>} ></Route>
            <Route path='selling-incentives/delete/:id' element={<ProtectedRoute ><DeleteSellingIncentives /></ProtectedRoute>} ></Route>
            <Route path='selling-incentives/edite/:id' element={<ProtectedRoute ><EditeSellingIncentives /></ProtectedRoute>} ></Route>
            <Route path='reasons-of-incentives' element={<ProtectedRoute ><ReasonsOfIncentives /></ProtectedRoute>} ></Route>
            <Route path='areas' element={<ProtectedRoute ><Areas /></ProtectedRoute>} ></Route>
            <Route path='work-policy' element={<ProtectedRoute ><WorkPolicies /></ProtectedRoute>} ></Route>
            <Route path='work-policy/add' element={<ProtectedRoute ><AddWorkPolicies /></ProtectedRoute>} ></Route>
            <Route path='work-policy/edite/:id' element={<ProtectedRoute ><EditeWorkPolicies /></ProtectedRoute>} ></Route>
            <Route path='abuqir-clients' element={<ProtectedRoute >< AbuQirClients /></ProtectedRoute>} ></Route>
            <Route path='abuqir-clients/add' element={<ProtectedRoute >< AddAbuQirClients /></ProtectedRoute>} ></Route>
            <Route path='abuqir-forms' element={<ProtectedRoute >< AbuQirForms /></ProtectedRoute>} ></Route>
            <Route path='abuqir-forms/add' element={<ProtectedRoute >< AddAbuQirForms /></ProtectedRoute>} ></Route>
            <Route path='claim-details' element={<ProtectedRoute >< ClaimDetails /></ProtectedRoute>} ></Route>
            <Route path='abuqir-bills' element={<ProtectedRoute >< AbuQirBills /></ProtectedRoute>} ></Route>
            <Route path='abuqir-bills/add' element={<ProtectedRoute >< AddAbuQirForms /></ProtectedRoute>} ></Route>
            <Route path='employees' element={<ProtectedRoute >< Employees /></ProtectedRoute>} ></Route>
            <Route path='employees/add' element={<ProtectedRoute >< AddEmployee /></ProtectedRoute>} ></Route>
            <Route path='attendance-departure' element={<ProtectedRoute >< AttendanceDeparture /></ProtectedRoute>} ></Route>
            <Route path='all-attendance-departure' element={<ProtectedRoute >< AllAttendanceDeparture /></ProtectedRoute>} ></Route>
            <Route path='reset-password' element={<ProtectedRoute >< ResetPassword /></ProtectedRoute>} ></Route>
            <Route path='monthly-accounts' element={<ProtectedRoute >< MonthlyAccounts /></ProtectedRoute>} ></Route>
            <Route path='reports' element={<ProtectedRoute >< Reports /></ProtectedRoute>} ></Route>
            <Route path='clients-interests' element={<ProtectedRoute >< ClientsInterests /></ProtectedRoute>} ></Route>
            <Route path='doctors-nurses' element={<ProtectedRoute >< Doctors /></ProtectedRoute>} ></Route>
            <Route path='doctors-nurses/add' element={<ProtectedRoute >< AddDoctors /></ProtectedRoute>} ></Route>
            <Route path='working-hours-more-twelve' element={<ProtectedRoute >< WorkingHoursMoreTwelve /></ProtectedRoute>} ></Route>

          </Route>
          {/* delivery layout  */}
          <Route path='/delivery' element={<ProtectedRoute><DeliveryLayout /></ProtectedRoute>}>
            <Route path='orders/:id' element={<ProtectedRoute><DeliveryOrders /></ProtectedRoute>}></Route>
            <Route path='rules' element={<ProtectedRoute><Rules /></ProtectedRoute>}></Route>
            <Route path='work-policy' element={<ProtectedRoute><WorkPolicies /></ProtectedRoute>}></Route>
            <Route path='pop-orders' element={<ProtectedRoute><DeliveryPopUpOrders /></ProtectedRoute>}></Route>
            <Route path='reset-password' element={<ProtectedRoute >< ResetPassword /></ProtectedRoute>} ></Route>
            <Route path='attendance-register' element={<ProtectedRoute >< AttendanceRegister /></ProtectedRoute>} ></Route>
          </Route>
          {/* casher layout  */}
          <Route path='/casher' element={<ProtectedRoute><CasherLayout /></ProtectedRoute>}>
            <Route path='orders' element={<ProtectedRoute><AllOrders /></ProtectedRoute>}></Route>
            <Route path='pop-orders' element={<ProtectedRoute><PopUpOrders /></ProtectedRoute>}></Route>
            <Route path='pop-orders/add' element={<ProtectedRoute><AddPopUpOrders /></ProtectedRoute>}></Route>
            <Route path='clients' element={<ProtectedRoute><ClientsData /></ProtectedRoute>}></Route>
            <Route path='clients/edite/:id' element={<ProtectedRoute><EditeClient /></ProtectedRoute>}></Route>
            <Route path='users' element={<ProtectedRoute><AllUsers /></ProtectedRoute>}></Route>
            <Route path='abuqir-clients' element={<ProtectedRoute>< AbuQirClients /></ProtectedRoute>}></Route>
            <Route path='abuqir-clients/add' element={<ProtectedRoute>< AddAbuQirClients /></ProtectedRoute>}></Route>
            <Route path='abuqir-forms' element={<ProtectedRoute>< AbuQirForms /></ProtectedRoute>}></Route>
            <Route path='abuqir-forms/add' element={<ProtectedRoute>< AddAbuQirForms /></ProtectedRoute>}></Route>
            <Route path='claim-details' element={<ProtectedRoute >< ClaimDetails /></ProtectedRoute>} ></Route>
            <Route path='abuqir-bills' element={<ProtectedRoute>< AbuQirBills /></ProtectedRoute>}></Route>
            <Route path='abuqir-bills/add' element={<ProtectedRoute>< AddAbuQirForms /></ProtectedRoute>}></Route>
            <Route path='reset-password' element={<ProtectedRoute >< ResetPassword /></ProtectedRoute>} ></Route>
            <Route path='attendance-departure' element={<ProtectedRoute >< AttendanceDeparture /></ProtectedRoute>} ></Route>
            <Route path='attendance-register' element={<ProtectedRoute >< AttendanceRegister /></ProtectedRoute>} ></Route>
          </Route>
          {/* purchases layout  */}
          <Route path='/purchases' element={<ProtectedRoute><PurchasesLayout /></ProtectedRoute>}>
            <Route path='purchases' element={<ProtectedRoute><PurchasesUser /></ProtectedRoute>}></Route>
            <Route path='purchases/edite/:id' element={<ProtectedRoute><EditePurchases /></ProtectedRoute>}></Route>
            <Route path='purchases/delete/:id' element={<ProtectedRoute><DeletePurchases /></ProtectedRoute>}></Route>
            <Route path='purchases/details/:id' element={<ProtectedRoute><PurchasesDeatils /></ProtectedRoute>}></Route>
            <Route path='shortcomings/add' element={<ProtectedRoute><AddShortComings /></ProtectedRoute>}></Route>
            <Route path='inventory-products' element={<ProtectedRoute><InventoryProducts /></ProtectedRoute>}></Route>
            <Route path='inventory-products/add' element={<ProtectedRoute><AddInventoryProducts /></ProtectedRoute>}></Route>
            <Route path='companies' element={<ProtectedRoute><Companies /></ProtectedRoute>}></Route>
            <Route path='companies/add' element={<ProtectedRoute><AddCompany /></ProtectedRoute>}></Route>
            <Route path='companies/edite/:id' element={<ProtectedRoute><EditeCompany /></ProtectedRoute>}></Route>
            <Route path='customer-service' element={<ProtectedRoute><CustomersService /></ProtectedRoute>}></Route>
            <Route path='transfers-products' element={<ProtectedRoute >< TransfersProducts /></ProtectedRoute>} ></Route>
            <Route path='notifications' element={<ProtectedRoute><PurchasesNotifications /></ProtectedRoute>}></Route>
            <Route path='notifications/add' element={<ProtectedRoute><AddNotification /></ProtectedRoute>}></Route>
            <Route path='abuqir-clients' element={<ProtectedRoute>< AbuQirClients /></ProtectedRoute>}></Route>
            <Route path='abuqir-clients/add' element={<ProtectedRoute>< AddAbuQirClients /></ProtectedRoute>}></Route>
            <Route path='abuqir-forms' element={<ProtectedRoute>< AbuQirForms /></ProtectedRoute>}></Route>
            <Route path='abuqir-forms/add' element={<ProtectedRoute>< AddAbuQirForms /></ProtectedRoute>}></Route>
            <Route path='claim-details' element={<ProtectedRoute >< ClaimDetails /></ProtectedRoute>} ></Route>
            <Route path='abuqir-bills' element={<ProtectedRoute>< AbuQirBills /></ProtectedRoute>}></Route>
            <Route path='abuqir-bills/add' element={<ProtectedRoute>< AddAbuQirForms /></ProtectedRoute>}></Route>
            <Route path='reset-password' element={<ProtectedRoute >< ResetPassword /></ProtectedRoute>} ></Route>
            <Route path='attendance-register' element={<ProtectedRoute >< AttendanceRegister /></ProtectedRoute>} ></Route>
          </Route>
          {/* doctor layout  */}
          <Route path='/doctor' element={<ProtectedRoute><DoctorLayout /></ProtectedRoute>}>
            <Route path='orders' element={<ProtectedRoute><DoctorOrders /></ProtectedRoute>}></Route>
            <Route path='orders/edite/:id' element={<ProtectedRoute><EditeOrder /></ProtectedRoute>}></Route>
            <Route path='orders/details/:id' element={<ProtectedRoute><OrderDetails /></ProtectedRoute>}></Route>
            <Route path='pop-orders' element={<ProtectedRoute><PopUpOrders /></ProtectedRoute>}></Route>
            <Route path='pop-orders/add' element={<ProtectedRoute><AddPopUpOrders /></ProtectedRoute>}></Route>
            <Route path='clients' element={<ProtectedRoute><Clients /></ProtectedRoute>}></Route>
            <Route path='clients/add' element={<ProtectedRoute><AddClient /></ProtectedRoute>}></Route>
            <Route path='purchases' element={<ProtectedRoute ><DoctorPurchases /></ProtectedRoute>} ></Route>
            <Route path='purchases/details/:id' element={<ProtectedRoute><PurchasesDeatils /></ProtectedRoute>} ></Route>
            <Route path='purchases/edite/:id' element={<ProtectedRoute><EditePurchases /></ProtectedRoute>} ></Route>
            <Route path='shortcomings/add' element={<ProtectedRoute ><AddShortComings /></ProtectedRoute>} ></Route>
            <Route path='offers' element={<ProtectedRoute><Offers /></ProtectedRoute>}></Route>
            <Route path='offers/add' element={<ProtectedRoute><AddOffer /></ProtectedRoute>}></Route>
            <Route path='offers/edite/:id' element={<ProtectedRoute><EditeOffer /></ProtectedRoute>}></Route>
            <Route path='notifications' element={<ProtectedRoute><PurchasesNotifications /></ProtectedRoute>}></Route>
            <Route path='notifications/add' element={<ProtectedRoute><AddNotification /></ProtectedRoute>}></Route>
            <Route path='inventory-products' element={<ProtectedRoute><InventoryProducts /></ProtectedRoute>}></Route>
            <Route path='inventory-products/add' element={<ProtectedRoute><AddInventoryProducts /></ProtectedRoute>}></Route>
            <Route path='rules' element={<ProtectedRoute><Rules /></ProtectedRoute>}></Route>
            <Route path='work-policy' element={<ProtectedRoute><WorkPolicies /></ProtectedRoute>}></Route>
            <Route path='selling-incentives' element={<ProtectedRoute><SellingIncentives /></ProtectedRoute>}></Route>
            <Route path='customer-service' element={<ProtectedRoute><CustomersService /></ProtectedRoute>}></Route>
            <Route path='transfers-products' element={<ProtectedRoute >< TransfersProducts /></ProtectedRoute>} ></Route>
            <Route path='abuqir-clients' element={<ProtectedRoute>< AbuQirClients /></ProtectedRoute>}></Route>
            <Route path='abuqir-clients/add' element={<ProtectedRoute>< AddAbuQirClients /></ProtectedRoute>}></Route>
            <Route path='abuqir-forms' element={<ProtectedRoute>< AbuQirForms /></ProtectedRoute>}></Route>
            <Route path='abuqir-forms/add' element={<ProtectedRoute>< AddAbuQirForms /></ProtectedRoute>}></Route>
            <Route path='claim-details' element={<ProtectedRoute >< ClaimDetails /></ProtectedRoute>} ></Route>
            <Route path='abuqir-bills' element={<ProtectedRoute>< AbuQirBills /></ProtectedRoute>}></Route>
            <Route path='abuqir-bills/add' element={<ProtectedRoute>< AddAbuQirForms /></ProtectedRoute>}></Route>
            <Route path='attendance-departure' element={<ProtectedRoute >< AttendanceDeparture /></ProtectedRoute>} ></Route>
            <Route path='doctors-nurses' element={<ProtectedRoute >< Doctors /></ProtectedRoute>} ></Route>
            <Route path='doctors-nurses/add' element={<ProtectedRoute >< AddDoctors /></ProtectedRoute>} ></Route>
            <Route path='attendance-register' element={<ProtectedRoute >< AttendanceRegister /></ProtectedRoute>} ></Route>
            <Route path='reset-password' element={<ProtectedRoute >< ResetPassword /></ProtectedRoute>} ></Route>
          </Route>
          {/* branch manager layout  */}
          <Route path='/branch-manager' element={<ProtectedRoute><BranchManagerLayout /></ProtectedRoute>}>
            <Route path='orders' element={<ProtectedRoute><DoctorOrders /></ProtectedRoute>}></Route>
            <Route path='orders/edite/:id' element={<ProtectedRoute><EditeOrder /></ProtectedRoute>}></Route>
            <Route path='pop-orders' element={<ProtectedRoute><PopUpOrders /></ProtectedRoute>}></Route>
            <Route path='pop-orders/add' element={<ProtectedRoute><AddPopUpOrders /></ProtectedRoute>}></Route>  
            <Route path='clients' element={<ProtectedRoute><Clients /></ProtectedRoute>}></Route>
            <Route path='clients/add' element={<ProtectedRoute><AddClient /></ProtectedRoute>}></Route> 
            <Route path='purchases' element={<ProtectedRoute ><DoctorPurchases /></ProtectedRoute>} ></Route>
            <Route path='purchases/details/:id' element={<ProtectedRoute><PurchasesDeatils /></ProtectedRoute>} ></Route>
            <Route path='purchases/edite/:id' element={<ProtectedRoute><EditePurchases /></ProtectedRoute>} ></Route>
            <Route path='shortcomings/add' element={<ProtectedRoute ><AddShortComings /></ProtectedRoute>} ></Route>
           <Route path='offers' element={<ProtectedRoute><Offers /></ProtectedRoute>}></Route>
            <Route path='offers/add' element={<ProtectedRoute><AddOffer /></ProtectedRoute>}></Route>
            <Route path='offers/edite/:id' element={<ProtectedRoute><EditeOffer /></ProtectedRoute>}></Route>
            <Route path='notifications' element={<ProtectedRoute><PurchasesNotifications /></ProtectedRoute>}></Route>
            <Route path='notifications/add' element={<ProtectedRoute><AddNotification /></ProtectedRoute>}></Route>
            <Route path='inventory-products' element={<ProtectedRoute><InventoryProducts /></ProtectedRoute>}></Route>
            <Route path='inventory-products/add' element={<ProtectedRoute><AddInventoryProducts /></ProtectedRoute>}></Route>
            <Route path='rules' element={<ProtectedRoute><Rules /></ProtectedRoute>}></Route>
            <Route path='work-policy' element={<ProtectedRoute><WorkPolicies /></ProtectedRoute>}></Route> 
            <Route path='selling-incentives' element={<ProtectedRoute><SellingIncentives /></ProtectedRoute>}></Route>
            <Route path='customer-service' element={<ProtectedRoute><CustomersService /></ProtectedRoute>}></Route>
            <Route path='transfers-products' element={<ProtectedRoute >< TransfersProducts /></ProtectedRoute>} ></Route> 
            <Route path='abuqir-clients' element={<ProtectedRoute>< AbuQirClients /></ProtectedRoute>}></Route>
            <Route path='abuqir-clients/add' element={<ProtectedRoute>< AddAbuQirClients /></ProtectedRoute>}></Route>
            <Route path='abuqir-forms' element={<ProtectedRoute>< AbuQirForms /></ProtectedRoute>}></Route>
            <Route path='abuqir-forms/add' element={<ProtectedRoute>< AddAbuQirForms /></ProtectedRoute>}></Route>
            <Route path='claim-details' element={<ProtectedRoute >< ClaimDetails /></ProtectedRoute>} ></Route>
            <Route path='abuqir-bills' element={<ProtectedRoute>< AbuQirBills /></ProtectedRoute>}></Route>
            <Route path='abuqir-bills/add' element={<ProtectedRoute>< AddAbuQirForms /></ProtectedRoute>}></Route>
            <Route path='attendance-departure' element={<ProtectedRoute >< AttendanceDeparture /></ProtectedRoute>} ></Route>
            <Route path='all-attendance-departure' element={<ProtectedRoute >< AllAttendanceDeparture /></ProtectedRoute>} ></Route>
            <Route path='doctors-nurses' element={<ProtectedRoute >< Doctors /></ProtectedRoute>} ></Route>
            <Route path='doctors-nurses/add' element={<ProtectedRoute >< AddDoctors /></ProtectedRoute>} ></Route>
            <Route path='attendance-register' element={<ProtectedRoute >< AttendanceRegister /></ProtectedRoute>} ></Route>
            <Route path='monthly-accounts' element={<ProtectedRoute >< MonthlyAccounts /></ProtectedRoute>} ></Route>
            <Route path='reset-password' element={<ProtectedRoute >< ResetPassword /></ProtectedRoute>} ></Route>
          </Route>
          <Route path='*' element={<ProtectedRoute ><NotFound /></ProtectedRoute>}></Route>
        </Routes>
      </BrowserRouter>

      <ToastContainer
        position='top-left'
        autoClose={5000}
      />

    </>
  );
}

export default App;
