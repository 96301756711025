import axios from 'axios';
import Joi from 'joi';
import React, { useContext, useEffect, useState } from 'react'
import { toast } from 'react-toastify';
import { AuthContext } from '../../Context/AuthStore';

export default function EditeComment({ commentId, getPostsData }) {
    let { accessToken } = useContext(AuthContext);
    let [isLoading, setIsLoading] = useState(false);
    let [comment, setComment] = useState({
        body: ''
    });
    let handleInputChange = (event) => {
        const { name, value } = event.target;
        setComment(prevState => ({ ...prevState, [name]: value }));
    };
    let [oneComment, setOneComment] = useState([]);
    let getOneComment = async () => {
        if (commentId) {
            let { data } = await axios.get(`${process.env.REACT_APP_API_URL}/api/comments/show/${commentId}`, {
                headers: {
                    "Authorization": `Bearer ${accessToken}`
                }
            }); 
            setOneComment(data);
            setComment({
                body: data?.body,
            })
        }
    }
    useEffect(() => {
        getOneComment()
    }, [commentId])
    let sendCommentDataToApi = async () => {
        try {
            await axios.put(`${process.env.REACT_APP_API_URL}/api/comments/updatecomment/${commentId}`, comment, {
                headers: {
                    "Authorization": `Bearer ${accessToken}`
                }
            }).then((res) => {
                toast.success(res.data.message, {
                    position: 'top-center'
                });
                setIsLoading(false);
                getPostsData()
            }).catch((errors) => {             
                setIsLoading(false);
                const errorList = errors?.response?.data?.message;
                if (errorList !== undefined) {
                    Object.keys(errorList)?.map((err) => {
                        errorList[err]?.map((err) => {
                            toast.error(err);
                        })
                    });
                } else {
                    toast.error("حدث خطأ ما");
                }
            });
        } catch (error) {
            toast.error("حدث خطأ ما");
        }
    };

    let validateCommentForm = () => {
        const schema = Joi.object({
            body: Joi.string().required(),
        });
        return schema.validate(comment, { abortEarly: false });
    };
    let submitCommentForm = (e) => {
        setIsLoading(true);
        e.preventDefault();
        let validation = validateCommentForm();
        if (!validation.error) {
            sendCommentDataToApi();
        } else {
            setIsLoading(false);
            try {
                validation?.error?.details?.map((err) => {
                    toast.error(err.message);
                })
            } catch (e) {
                toast.error("حدث خطأ ما");
            }
        }
    };

    return (
        <>
            <div className="mx-5 p-3 rounded rounded-3 bg-white">
                <form onSubmit={submitCommentForm} >
                    <div className="row gy-3">
                    <div className="col-md-12">
                            <label htmlFor="body" className='form-label'>المنشور</label>
                            <textarea className='form-control' name="body" value={comment.body}
                                onChange={handleInputChange} />
                        </div>
                    </div>
                    <div className="col-md-3 my-3">
                        <button type='submit' className='btn btn-primary form-control fs-5'>
                            {isLoading == true ? <i className='fa fa-spinner fa-spin'></i> : 'تعديل'}
                        </button>
                    </div>
                </form >
            </div >
        </>
    )
}

