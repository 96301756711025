
import axios from 'axios';
import Joi, { number } from 'joi';
import React, { useContext, useEffect, useRef, useState } from 'react'
import { Helmet } from 'react-helmet-async';

import { toast } from 'react-toastify';
import { AuthContext } from '../../Context/AuthStore';

export default function AddPayBatch() {
    let { accessToken } = useContext(AuthContext);
    const formRef = useRef(null);
    let [isLoading, setIsLoading] = useState(false);
    let [payBatchs, setPayBatchs] = useState({
        value: '',
        number: '' //reset number
    });
    let handleInputChange = (event) => {
        const { name, value } = event.target;
        setPayBatchs(prevState => ({ ...prevState, [name]: value }));
    };
    let sendPayBatchDataToApi = async () => {
        try {
            await axios.post(`${process.env.REACT_APP_API_URL}/api/payBatchs`, payBatchs, {
                headers: {
                    "Authorization": `Bearer ${accessToken}`
                }
            }).then((res) => {    
                formRef.current.reset();
                toast.success(res.data.message, {
                    position: 'top-center'
                });
                setIsLoading(false);
            }).catch((errors) => {          
                setIsLoading(false);
                const errorList = errors?.response?.data?.message;
                if (errorList !== undefined) {
                    Object.keys(errorList)?.map((err) => {
                        errorList[err]?.map((err) => {
                            toast.error(err);
                        })
                    });
                } else {
                    toast.error("حدث خطأ ما");
                }
            });
        } catch (error) {
            toast.error("حدث خطأ ما");
        }
    };

    let validatePayBatchForm = () => {
        const schema = Joi.object({
            value: Joi.string().required(),
            number: Joi.number().required(),
        });
        return schema.validate(payBatchs, { abortEarly: false });
    };
    let submitPayBatchForm = (e) => {
        setIsLoading(true);
        e.preventDefault();
        let validation = validatePayBatchForm();
        if (!validation.error) {
            sendPayBatchDataToApi();
        } else {
            setIsLoading(false);
            try {
                validation?.error?.details?.map((err) => {
                    toast.error(err.message);
                })
            } catch (e) {
                toast.error("حدث خطأ ما");
            }
        }
    };

    return (
        <>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Add PayBatch</title>
            </Helmet>
            <h3 className='alert alert-primary text-center mx-5 my-2  fw-bold'>إضافة كلوز باتش </h3>
            <div className="mx-5 p-3 rounded rounded-3 bg-white">
                <form ref={formRef} onSubmit={submitPayBatchForm} >
                    <div className="row gy-3">
                        <div className="col-md-5">
                            <label htmlFor="value" className='form-label'>قيمة الكلوز باتش</label>
                            <input className='form-control' name="value"
                                onChange={handleInputChange} />
                        </div>
                        <div className="col-md-5">
                            <label htmlFor="number" className='form-label'>رقم الريسيت</label>
                            <input className='form-control' name="number"
                                onChange={handleInputChange} />
                        </div>
                        <div className="col-md-3">
                            <button type='submit' className='btn btn-primary form-control fs-5'>
                                {isLoading == true ? <i className='fa fa-spinner fa-spin'></i> : 'إضافة'}
                            </button>
                        </div>
                    </div>
                </form >
            </div >
        </>
    )
}

