import axios from 'axios';
import React, { useContext, useEffect, useState, useRef } from 'react';
import { Helmet } from 'react-helmet-async';
import { AuthContext } from '../../Context/AuthStore';
import Pagination from '../Pagination/Pagination';
import { toast } from 'react-toastify';
import Joi from 'joi';
import { NavLink } from 'react-router-dom';

export default function AttendanceDeparture() {
    let { accessToken } = useContext(AuthContext);
    const currentPath = window.location.pathname;
    const formRef = useRef(null);
    const formAttendanceRef = useRef(null);
    const formDepartureRef = useRef(null);
    let [pagination, setPagination] = useState(null);
    let [currentPage, setCurrentPage] = useState(1);
    let [attendanceDeparture, setAttendanceDeparture] = useState([]);

    let getAttendanceDepartureData = async (page = 1) => {
        let { data } = await axios.get(`${process.env.REACT_APP_API_URL}/api/attendance?page=${page}`, {
            headers: {
                "Authorization": `Bearer ${accessToken}`
            }
        });
        setAttendanceDeparture(data.data);
        setPagination(data.meta);
        setCurrentPage(page);
    };

    useEffect(() => {
        getAttendanceDepartureData();
    }, []);

    let handlePageChange = (page) => {
        getAttendanceDepartureData(page);
    };

    let [editedTimes, setEditedTimes] = useState({
        attendance_time: '',
        departure_time: '',
    });
    let [initialTimes, setInitialTimes] = useState({
        attendance_time: '',
        departure_time: '',
    });
    let [attendanceId, setAttendanceId] = useState('');

    let showAttendanceDeparture = () => {
        const presentEmployees = attendanceDeparture?.filter(emp => emp.departure_time == null);

        if (presentEmployees.length > 0) {
            return (
                <div className="shadow rounded rounded-4 bg-white mx-3 p-3 table-responsive">
                    <table dir="rtl" className='table table-hover align-middle text-center table-responsive-list'>
                        <thead className='table-primary no-wrap-heading'>
                            <tr>
                                <th>التاريخ</th>
                                <th>اليوم</th>
                                <th>الموظف</th>
                                <th>الوظيفة</th>
                                <th>وقت الحضور</th>
                                <th>وقت الانصراف</th>
                                <th>تأخير</th>
                                {currentPath === '/admin/attendance-departure' && (
                                    <th>خيارات</th>
                                )}
                            </tr>
                        </thead>
                        <tbody>
                            {presentEmployees.map((emp) => (
                                <tr key={emp.id}>
                                    <td data-label="التاريخ">{emp.date}</td>
                                    <td data-label="اليوم">
                                        {emp.day === 'Saturday' ? 'السبت' :
                                            emp.day === 'Sunday' ? 'الأحد' :
                                                emp.day === 'Monday' ? 'الإثنين' :
                                                    emp.day === 'Tuesday' ? 'الثلاثاء' :
                                                        emp.day === 'Wednesday' ? 'الأربعاء' :
                                                            emp.day === 'Thursday' ? 'الخميس' :
                                                                emp.day === 'Friday' ? 'الجمعة' : ''}
                                    </td>
                                    <td data-label="الموظف">{emp?.employee?.name}</td>
                                    <td data-label="الوظيفة">{emp.employee?.category?.name}</td>
                                    <td data-label="وقت الحضور">{emp?.attendance_time}</td>
                                    <td data-label="وقت الانصراف">{emp?.departure_time}</td>
                                    <td data-label="تأخير">{emp?.lateHours}</td>
                                    {currentPath === '/admin/attendance-departure' && (
                                        <td data-label="خيارات">
                                            <i className='bi bi-pencil-square text-primary fs-5 mx-1' onClick={() => {
                                                setAttendanceId(emp.id);
                                                setEditedTimes({
                                                    attendance_time: emp.attendance_time,
                                                    departure_time: emp.departure_time,
                                                });
                                                setInitialTimes({
                                                    attendance_time: emp.attendance_time,
                                                    departure_time: emp.departure_time,
                                                });
                                            }} data-bs-toggle="modal" data-bs-target="#editeTimes"></i>
                                        </td>
                                    )}
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            );
        } else {
            return (
                <div className='d-flex justify-content-center height-calc-70 align-items-center'>
                    <div className='alert alert-danger w-50 text-center'>لا يوجد حاضرين</div>
                </div>
            );
        }
    };


    let [isLoading, setIsLoading] = useState(false);
    let [isLoadingAttendance, setIsLoadingAttendance] = useState(false);
    let [isLoadingDeparture, setIsLoadingDeparture] = useState(false);
    let [attendanceFormData, setAttendanceFormData] = useState({ employee_code: '' });
    let [departureFormData, setDepartureFormData] = useState({ employee_code: '' });

    let handleAttendanceInputChange = (e) => {
        setAttendanceFormData({ employee_code: e.target.value });
    };

    let handleDepartureInputChange = (e) => {
        setDepartureFormData({ employee_code: e.target.value });
    };
    let sendAttendanceDepartureDataToApi = async (type, formData) => {
        try {
            const url = type === 'attendance'
                ? `${process.env.REACT_APP_API_URL}/api/attendance/recordAttendance`
                : `${process.env.REACT_APP_API_URL}/api/attendance/recordDeparture`;
            await axios.post(url, formData, {
                headers: {
                    "Authorization": `Bearer ${accessToken}`
                }
            }).then((res) => {
                toast.success(res.data.message, {
                    position: 'top-center'
                });

                formAttendanceRef.current.reset();
                formDepartureRef.current.reset();
                type === 'attendance' ? setIsLoadingAttendance(false) : setIsLoadingDeparture(false);
                type === 'attendance' ? setAttendanceFormData({ employee_code: '' }) : setDepartureFormData({ employee_code: '' });
                getAttendanceDepartureData();
            }).catch((errors) => {
                type === 'attendance' ? setIsLoadingAttendance(false) : setIsLoadingDeparture(false);
                const errorList = errors?.response?.data?.message;
                try {
                    if (errorList !== undefined) {
                        Object.keys(errorList)?.map((err) => {
                            errorList[err]?.map((err) => {
                                toast.error(err);
                            });
                        });
                    } else {
                        toast.error("حدث خطأ ما");
                    }
                } catch (error) {
                    toast.error(errors?.response?.data?.message);
                }
            });
        } catch (error) {
            toast.error("حدث خطأ ما");
        }
    };

    let validateAttendanceDepartureForm = (formData) => {
        const schema = Joi.object({
            employee_code: Joi.string().required(),
        });
        return schema.validate(formData, { abortEarly: false });
    };
    let submitForm = (e, type) => {
        e.preventDefault();
        type === 'attendance' ? setIsLoadingAttendance(true) : setIsLoadingDeparture(true);
        const formData = type === 'attendance' ? attendanceFormData : departureFormData;
        let validation = validateAttendanceDepartureForm(formData);
        if (!validation.error) {
            sendAttendanceDepartureDataToApi(type, formData);
        } else {
            type === 'attendance' ? setIsLoadingAttendance(false) : setIsLoadingDeparture(false);
            try {
                validation?.error?.details?.map((err) => {
                    toast.error(err.message);
                });
            } catch (e) {
                toast.error("حدث خطأ ما");
            }
        }
    };

    let getInputValue = (event) => {
        let myTimes = { ...editedTimes }; //deep copy
        myTimes[event.target.name] = event.target.value;
        setEditedTimes(myTimes);
    };

    let sendEditedTimesToApi = async () => {
        const dataToSend = {};
        if (editedTimes.attendance_time !== initialTimes.attendance_time) {
            dataToSend.attendance_time = editedTimes.attendance_time;
        }
        if (editedTimes.departure_time !== initialTimes.departure_time) {
            dataToSend.departure_time = editedTimes.departure_time;
        }

        await axios.put(`${process.env.REACT_APP_API_URL}/api/attendance/${attendanceId}`, dataToSend, {
            headers: {
                "Authorization": `Bearer ${accessToken}`
            }
        }).then((res) => {
            setIsLoading(false);
            toast.success(res.data.message, {
                position: 'top-center'
            });
            formRef.current.reset();
            setEditedTimes({
                attendance_time: "",
                departure_time: "",
            });
            getAttendanceDepartureData();
        }).catch((errors) => {
            setIsLoading(false);
            try {
                const errorList = errors?.response?.data?.message;
                if (errorList !== undefined) {
                    Object.keys(errorList)?.map((err) => {
                        errorList[err]?.map((err) => {
                            toast.error(err);
                        });
                    });
                } else {
                    toast.error("حدث خطأ ما");
                }
            } catch (error) {
                toast.error(errors?.response?.data?.message);
            }
        });
    };

    let validateEditedTimesFrom = () => {
        const schema = Joi.object({
            attendance_time: Joi.string().empty(null),
            departure_time: Joi.string().empty(null)
        });
        return schema.validate(editedTimes, { abortEarly: false });
    };

    let submitEditedTimes = (e) => {
        setIsLoading(true);
        e.preventDefault();
        let validation = validateEditedTimesFrom();
        if (!validation.error) {
            sendEditedTimesToApi();
        } else {
            setIsLoading(false);
            try {
                validation?.error?.details?.map((err) => {
                    toast.error(err.message);
                });
            } catch (e) {
                toast.error("حدث خطأ ما");
            }
        }
    };

    return (
        <>
            <Helmet>
                <meta charSet="utf-8" />
                <title> Attendance Departure</title>
            </Helmet>
            <div className="row m-3" dir='rtl'>
                <div className='col-md-6'>
                    <form ref={formAttendanceRef} onSubmit={(e) => submitForm(e, 'attendance')}>
                        <div>
                            <input
                                type="text"
                                className="form-control"
                                onChange={handleAttendanceInputChange}
                                placeholder="أدخل كود الموظف للحضور"
                            />
                        </div>
                        <div className="d-flex justify-content-center">
                            <div className="my-1">
                                <button
                                    type="submit"
                                    className="btn btn-primary form-control"
                                >
                                    {isLoadingAttendance ? <i className='fa fa-spinner fa-spin'></i> : 'تسجيل حضور'}
                                </button>
                            </div>
                        </div>
                    </form>
                </div>
                <div className='col-md-6'>
                    <form ref={formDepartureRef} onSubmit={(e) => submitForm(e, 'departure')}>
                        <div>
                            <input
                                type="text"
                                className="form-control"
                                onChange={handleDepartureInputChange}
                                placeholder="أدخل كود الموظف للانصراف"
                            />
                        </div>
                        <div className="d-flex justify-content-center">
                            <div className="my-1">
                                <button
                                    type="submit"
                                    className="btn btn-danger form-control"
                                >
                                    {isLoadingDeparture ? <i className='fa fa-spinner fa-spin'></i> : 'تسجيل انصراف'}
                                </button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
            <div className="text-center mb-2">
                <Pagination pagination={pagination} currentPage={currentPage} handlePageChange={handlePageChange} />
                {currentPath === '/admin/attendance-departure' && (
                    <NavLink to='/admin/all-attendance-departure' className='btn btn-warning mx-3'>كل الحاضرين والمنصرفين</NavLink>
                )}
                {currentPath === '/branch-manager/attendance-departure' && (
                    <NavLink to='/branch-manager/all-attendance-departure' className='btn btn-warning mx-3'>كل الحاضرين والمنصرفين</NavLink>
                )}
                 {currentPath === '/admin/attendance-departure' && (
                    <NavLink to='/admin/working-hours-more-twelve' className='btn btn-danger '>تنبيهات أكثر من 12 ساعة  </NavLink>
                )}

            </div>
            {showAttendanceDeparture()}
            {/* edite times modal */}
            <div dir='rtl' className="modal fade" id="editeTimes" tabIndex={-1} aria-labelledby="editeTimesLabel" aria-hidden="true">
                <div className="modal-dialog modal-xl">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h1 className="modal-title text-danger fs-5 m-auto" id="editeTimesLabel">تعديل وقت الحضور والانصراف</h1>
                        </div>
                        <div className="modal-body">
                            <form ref={formRef} onSubmit={submitEditedTimes}>
                                <div className="row gy-3">
                                    <div className="col-md-6">
                                        <label htmlFor="body" className='form-label'>وقت الحضور</label>
                                        <input
                                            type='time'
                                            className='form-control'
                                            name="attendance_time"
                                            onChange={getInputValue}
                                        />
                                    </div>
                                    <div className="col-md-6">
                                        <label htmlFor="body" className='form-label'>وقت الانصراف</label>
                                        <input
                                            type='time'
                                            className='form-control'
                                            name="departure_time"
                                            onChange={getInputValue}
                                        />
                                    </div>
                                    <div className="col-md-3">
                                        <button type='submit' className='btn btn-primary form-control fs-5'>
                                            {isLoading ? <i className='fa fa-spinner fa-spin'></i> : 'تعديل'}
                                        </button>
                                    </div>
                                </div>
                            </form>
                            <div className="my-2">
                                <button type="button" className="btn btn-secondary ms-3" data-bs-dismiss="modal">غلق</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
