
import axios from 'axios';
import Joi from 'joi';
import React, { useContext, useEffect, useState } from 'react'
import { toast } from 'react-toastify';
import { AuthContext } from '../../Context/AuthStore';

export default function EditePost({ postId, getPostsData }) {
    let { accessToken } = useContext(AuthContext);
    let [isLoading, setIsLoading] = useState(false);
    let [post, setPost] = useState({
        body: ''
    });
    let handleInputChange = (event) => {
        const { name, value } = event.target;
        setPost(prevState => ({ ...prevState, [name]: value }));
    };
    let [onePost, setOnePost] = useState([]);
    let getOnePost = async () => {
        if (postId) {
            let { data } = await axios.get(`${process.env.REACT_APP_API_URL}/api/posts/show/${postId}`, {
                headers: {
                    "Authorization": `Bearer ${accessToken}`
                }
            });
            setOnePost(data.data);
            setPost({
                body: data.data.body,
            })
        }
    }
    useEffect(() => {
        getOnePost()
    }, [postId])
    let sendPostDataToApi = async () => {
        try {
            await axios.put(`${process.env.REACT_APP_API_URL}/api/posts/${postId}`, post, {
                headers: {
                    "Authorization": `Bearer ${accessToken}`
                }
            }).then((res) => {
                toast.success(res.data.message, {
                    position: 'top-center'
                });
                setIsLoading(false);
                getPostsData()
            }).catch((errors) => {
                setIsLoading(false);
                const errorList = errors?.response?.data?.message;
                if (errorList !== undefined) {
                    Object.keys(errorList)?.map((err) => {
                        errorList[err]?.map((err) => {
                            toast.error(err);
                        })
                    });
                } else {
                    toast.error("حدث خطأ ما");
                }
            });
        } catch (error) {
            toast.error("حدث خطأ ما");
        }
    };

    let validatePostForm = () => {
        const schema = Joi.object({
            body: Joi.string().required(),
        });
        return schema.validate(post, { abortEarly: false });
    };
    let submitPostForm = (e) => {
        setIsLoading(true);
        e.preventDefault();
        let validation = validatePostForm();
        if (!validation.error) {
            sendPostDataToApi();
        } else {
            setIsLoading(false);
            try {
                validation?.error?.details?.map((err) => {
                    toast.error(err.message);
                })
            } catch (e) {
                toast.error("حدث خطأ ما");
            }
        }
    };

    return (
        <>
            <div className="mx-5 p-3 rounded rounded-3 bg-white">
                <form onSubmit={submitPostForm} >
                    <div className="row gy-3">
                    <div className="col-md-12">
                            <label htmlFor="body" className='form-label'>المنشور</label>
                            <textarea className='form-control' name="body" value={post.body}
                                onChange={handleInputChange} />
                        </div>
                    </div>
                    <div className="col-md-3 my-3">
                        <button type='submit' className='btn btn-primary form-control fs-5'>
                            {isLoading == true ? <i className='fa fa-spinner fa-spin'></i> : 'تعديل'}
                        </button>
                    </div>
                </form >
            </div >
        </>
    )
}

