import axios from 'axios';
import React, { useContext, useEffect, useState } from 'react'
import { Helmet } from 'react-helmet-async';
import { NavLink } from 'react-router-dom';
import { AuthContext } from '../../Context/AuthStore';
import Pagination from '../Pagination/Pagination';
import { toast } from 'react-toastify';
import { useRef } from 'react';
import Joi from 'joi';

import { Field, Form, Formik } from "formik";
import { Button, Modal } from "react-bootstrap";

export default function Employees() {
    let { accessToken } = useContext(AuthContext);
    let [employees, setEmployess] = useState({})
    let [pagination, setPagination] = useState(null);
    let [currentPage, setCurrentPage] = useState(1); // Current page state

    const [showModalEdit, setShowModalEdit] = useState(false);

    const daysOfWeek = ['Saturday', 'Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday'];
    let [filterBranchId, setFilterBranchId] = useState('');
    function handleBranchIdChange(event) {
        setFilterBranchId(event?.target?.value);
    }
    let [filterCategryId, setFilterCategryId] = useState('');
    function handleCategryIdChange(event) {
        setFilterCategryId(event.target.value)
    }
    let getEmployeeData = async (page = 1) => {
        try {
            let employeesResult;
            let urlApi = `${process.env.REACT_APP_API_URL}/api/employees/filter?`;
            if (filterBranchId !== undefined && filterBranchId.length > 0) {
                urlApi += `branch_id=${filterBranchId}&`
            }
            if (filterCategryId !== undefined && filterCategryId.length > 0) {
                urlApi += `category_id=${filterCategryId}&`
            }
            urlApi += `page=${page}`
            employeesResult = await axios.get(urlApi, {
                headers: {
                    "Authorization": `Bearer ${accessToken}`
                }
            })

            if (employeesResult) {
                setEmployess(employeesResult.data.data);
                setPagination(employeesResult.data.meta); // Set pagination data
                setCurrentPage(page); // Set current page
            }
        } catch (error) {
            toast.error('حدث خطأ ما')
        }

    };
    useEffect(() => { getEmployeeData() },
        [filterBranchId, filterCategryId]);

    let [branches, setBranches] = useState([]);
    let getBranches = async () => {
        let { data } = await axios.get(`${process.env.REACT_APP_API_URL}/api/properties/getCustomList/branch`, {
            headers: {
                "Authorization": `Bearer ${accessToken}`
            }
        });
        setBranches(data)
    }
    useEffect(() => {
        getBranches()
        getEmployeeCategory()
    }, [])
    //for handle page change
    let handlePageChange = (page) => {
        getEmployeeData(page);
    };
    let deleteEmployee = async (empId) => {
        try {
            let { data } = await axios.delete(`${process.env.REACT_APP_API_URL}/api/employees/delete/${empId}`, {
                headers: {
                    "Authorization": `Bearer ${accessToken}`
                }
            });
            toast.success(data.message);
            getEmployeeData()

        } catch (error) {
            toast.error('حدث خطأ ما، حاول مرة أخرى')
        }
    };
    let [employeeId, setEmployeeId] = useState('');
    let showEmployees = () => {
        if (employees.length > 0) {
            return (
                <div className="shadow rounded rounded-4 bg-white mx-3 p-3 table-responsive ">
                    <table dir="rtl" className='table  table-hover align-middle text-center table-responsive-list  '>
                        <thead className='table-primary   no-wrap-heading'>
                            <tr>
                                <th>الاسم</th>
                                <th>الكود</th>
                                <th>الفرع</th>
                                <th> سعر الساعة</th>
                                <th>الأجازة</th>
                                <th>الوظيفة</th>
                                <th>خيارات</th>
                            </tr>
                        </thead>
                        <tbody>
                            {employees.map((emp) => <tr key={emp.id}>

                                <td data-label="الاسم ">{emp?.name}</td>
                                <td data-label="الكود ">{emp?.code}</td>
                                <td data-label="الفرع ">{emp?.branch?.name}</td>
                                <td data-label="سعر الساعة">{emp?.hourRate}</td>
                                <td data-label="الأجازة">{emp.holiday === 'saturday' ? 'السبت' : emp.holiday === 'sunday' ? 'الأحد' : emp.holiday === 'monday' ? 'الإثنين' : emp.holiday === 'tuesday' ? 'الثلاثاء' : emp.holiday === 'wednesday' ? 'الأربعاء' : emp.holiday === 'thursday' ? 'الخميس' : emp.holiday === 'friday' ? 'الجمعة' : ''}</td>
                                <td data-label="الوظيفة">{emp?.category?.name}</td>
                                <td data-label="خيارات">
                                    <div className="dropdown">
                                        <i className='bi bi-list-ul text-success mx-1 fs-5' onClick={() => {
                                            setEmployeeId(emp.id)
                                        }} data-bs-toggle="modal" data-bs-target="#employeeDetails"></i>
                                        <i className='bi bi-pencil-square text-primary fs-5 mx-1' onClick={() => {
                                            setEmployeeId(emp.id)
                                            setShowModalEdit(true)
                                        }}> </i>
                                        <span data-bs-toggle="dropdown" aria-expanded="false">
                                            <i className='bi bi-trash text-danger fs-5 '></i>
                                        </span>
                                        <ul className="dropdown-menu">
                                            <li>
                                                <button className="dropdown-item" onClick={() => {
                                                    deleteEmployee(emp.id)
                                                }}>حذف
                                                </button>
                                            </li>
                                        </ul>
                                    </div>
                                </td>
                            </tr>
                            )}
                        </tbody>
                    </table>
                </div>
            )
        } else {
            return (
                <div className=' d-flex justify-content-center  height-calc-70 align-items-center' >
                    {employees.length <= 0 && filterBranchId.length <= 0 && filterCategryId.length <= 0 ?
                        <i className='fa fa-spinner fa-spin  fa-3x'></i>
                        : <div className='alert alert-danger w-50 text-center'>لا يوجد تطابق</div>
                    }
                </div>
            )
        }
    };
    // employee details 
    // Helper function to format time
    const formatTime = (time) => {
        const [hours, minutes, seconds] = time.split(':');
        const ampm = hours >= 12 ? 'PM' : 'AM';
        const formattedHours = hours % 12 || 12;
        return `${formattedHours}:${minutes} ${ampm}`;
    };
    let [oneEmployee, setOneEmployee] = useState([]);
    let getOneEmployee = async () => {
        if (employeeId) {
            let { data } = await axios.get(`${process.env.REACT_APP_API_URL}/api/employees/show/${employeeId}`, {
                headers: {
                    "Authorization": `Bearer ${accessToken}`
                }
            });
            const employeeData = data.data;
            setOneEmployee(employeeData);

            let newAttendanceTimes = [
                ...employeeData.attendanceTimes
            ]

            // Add missing attendance times
            daysOfWeek.forEach((day, index) => {
                if (!employeeData.attendanceTimes.some(entry => entry.day == day)) {
                    newAttendanceTimes.push({
                        id: '',
                        day: day,
                        attendance_time: '',
                        departure_time: ''
                    })
                }
            })

            setEditedEmployee({
                name: employeeData.name || '',
                code: employeeData.code || '',
                hourRate: employeeData.hourRate || '',
                holiday: employeeData.holiday || '',
                times: newAttendanceTimes || '',
                category_id: employeeData.category?.id || '',
                notes: employeeData.notes || '',
                branch_id: employeeData.branch?.id || '',
                constantIncentive: employeeData?.constantIncentive || ''
            });
        }
    };

    useEffect(() => {
        getOneEmployee()
    }, [employeeId]);

    // edite abu qir client
    const formRef = useRef(null);
    let [isLoading, setIsLoading] = useState(false);

    let [editedEmployee, setEditedEmployee] = useState({
        name: '',
        code: '',
        hourRate: '',
        holiday: '',
        times: [],
        category_id: '',
        notes: '',
        branch_id: '',
        constantIncentive: ''
    });
    //get employeeCategory data
    let [employeeCategory, setEmployeeCategory] = useState([]);
    let getEmployeeCategory = async () => {
        let { data } = await axios.get(`${process.env.REACT_APP_API_URL}/api/properties/getCustomList/employeeCategory`, {
            headers: {
                "Authorization": `Bearer ${accessToken}`
            }
        });
        setEmployeeCategory(data)
    }

    let sendEditedEmployeeToApi = async (values) => {
        await axios.put(`${process.env.REACT_APP_API_URL}/api/employees/${employeeId}`, values, {
            headers: {
                "Authorization": `Bearer ${accessToken}`
            }
        }).then((res) => {
            toast.success(res.data.message, {
                position: 'top-center'
            });
            setEmployeeId('')
            getEmployeeData()
        }).catch((errors) => {

            try {
                const errorList = errors?.response?.data?.message;
                if (errorList !== undefined) {
                    Object.keys(errorList)?.map((err) => {
                        errorList[err]?.map((err) => {
                            toast.error(err);
                        })
                    });
                } else {
                    toast.error("حدث خطأ ما");
                }
            } catch (error) {
                toast.error("حدث خطأ ما");
            }

        });
    };

    let validateEditedEmployeeFrom = (values) => {
        const schema = Joi.object({
            name: Joi.string().empty(''),
            code: Joi.string().empty(''),
            hourRate: Joi.number().empty(null),
            holiday: Joi.string().empty(''),
            times: Joi.any(),
            category_id: Joi.number().empty(''),
            notes: Joi.any().empty(''),
            branch_id: Joi.number().empty(''),
            constantIncentive: Joi.string().empty(''),

        });
        return schema.validate(values, { abortEarly: false });
    };

    let submitEditedEmployee = (values) => {
        const initialEmployee = { ...editedEmployee };
        // Filter out unchanged fields
        const changedValues = {};
        Object.keys(values).map(key => {
            if (JSON.stringify(values[key]) !== JSON.stringify(initialEmployee[key])) {
                changedValues[key] = values[key];
            }
        });

        if (changedValues.times) {
            changedValues.times = values.times.filter(time => {
                return time.day && time.attendance_time && time.departure_time;
            });
            changedValues.times.map((key) => {
                if (JSON.stringify(changedValues.times[key]) !== JSON.stringify(initialEmployee[key])) {
                    changedValues[key] = values[key];
                }
            })
            // Compare times with the initialEmployee times and only keep changed entries
            changedValues.times = changedValues.times.filter((time, index) => {
                const initialTime = initialEmployee.times[index];
                return initialTime && (
                    time.attendance_time !== initialTime.attendance_time ||
                    time.departure_time !== initialTime.departure_time
                );
            });
        }
        let validation = validateEditedEmployeeFrom(changedValues);
        if (!validation.error) {
            sendEditedEmployeeToApi(changedValues);
        } else {
            try {
                validation.error.details.map((err) => {
                    toast.error(err.message);
                })
            } catch (e) {
                toast.error("حدث خطأ ما");
            }
        }
    };

    return (
        <>
            <Helmet>
                <meta charSet="utf-8" />
                <title> Employees</title>
            </Helmet>
            <div className=" my-3 text-center row mx-2  " dir='rtl'>
                <div className="col-md-4">
                    <NavLink to='/admin/employees/add' className='btn btn-primary mb-1'>إضافة موظف</NavLink>
                </div>
                <div className="col-md-4 m-auto   mb-2">
                    <select name="branch_id" defaultValue={0} className='form-control' id="branch_id"
                        onChange={handleBranchIdChange}>
                        <option value={0} hidden disabled>اختر الفرع...</option>
                        {branches.map((branch) => <option key={branch.id} value={branch?.id}>{branch?.name}</option>)}
                    </select>
                </div>
                <div className="col-md-4">
                    <select className='form-control' defaultValue={0} name="category_id" id="category_id"
                        onChange={handleCategryIdChange}>
                        <option value={0} hidden disabled>اختر الفئة...</option>
                        {employeeCategory.map((cat) => <option key={cat.id} value={cat.id}>{cat?.name}</option>)}
                    </select>
                </div>

            </div>

            <div className="text-center mb-2">
                <Pagination pagination={pagination} currentPage={currentPage} handlePageChange={handlePageChange} />
            </div>
            {showEmployees()}
            {/* employee details modal */}
            <div dir='rtl' className="modal fade" id="employeeDetails" tabIndex={-1}
                aria-labelledby="employeeDetailsLabel" aria-hidden="true">
                <div className="modal-dialog  modal-xl  ">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h1 className="modal-title text-danger fs-5  m-auto" id="employeeDetailsLabel"> تفاصيل
                                الموظف </h1>
                        </div>
                        <div className="modal-body ">

                            <div className="row m-2">
                                <p><strong> كود الموظف:</strong> <span>{oneEmployee?.code} </span></p>
                                <p><strong> اسم الموظف :</strong> <span>{oneEmployee?.name} </span></p>
                                <p><strong> اسم الفرع :</strong> <span>{oneEmployee?.branch?.name} </span></p>
                                <p><strong> قيمة الحافز الثابت  :</strong> <span>{oneEmployee?.constantIncentive} </span></p>
                                <p><strong> سعر الساعة :</strong> <span>{oneEmployee?.hourRate} </span></p>
                                <p><strong> يوم الأجازة :</strong> <span>
                                    {oneEmployee.holiday === 'saturday' ? 'السبت' :
                                        oneEmployee.holiday === 'sunday' ? 'الأحد' :
                                            oneEmployee.holiday === 'monday' ? 'الإثنين' :
                                                oneEmployee.holiday === 'tuesday' ? 'الثلاثاء' :
                                                    oneEmployee.holiday === 'wednesday' ? 'الأربعاء' :
                                                        oneEmployee.holiday === 'thursday' ? 'الخميس' :
                                                            oneEmployee.holiday === 'friday' ? 'الجمعة' : ''}
                                </span></p>
                                <p><strong> الوظيفة :</strong> <span>{oneEmployee?.category?.name} </span></p>
                                {oneEmployee?.attendanceTimes?.map((time) => <div key={time.id} className='row my-1'>
                                    <div className='col-md-4'><strong> اليوم: </strong>
                                        <span>
                                            {time.day === 'Saturday' ? 'السبت' :
                                                time.day === 'Sunday' ? 'الأحد' :
                                                    time.day === 'Monday' ? 'الإثنين' :
                                                        time.day === 'Tuesday' ? 'الثلاثاء' :
                                                            time.day === 'Wednesday' ? 'الأربعاء' :
                                                                time.day === 'Thursday' ? 'الخميس' :
                                                                    time.day === 'Friday' ? 'الجمعة' : ''}
                                        </span>
                                    </div>
                                    <div className='col-md-4'><strong> موعد الحضور: </strong>
                                        <span>{formatTime(time.attendance_time)}</span></div>
                                    <div className='col-md-4'><strong> موعد الإنصراف: </strong>
                                        <span>{formatTime(time.departure_time)}</span></div>
                                </div>)}
                                <p className='my-2'><strong> ملاحظات :</strong> <span>  {oneEmployee?.notes} </span></p>
                            </div>
                        </div>
                        <div className="m-2">
                            <button type="button" className="btn btn-secondary ms-3" data-bs-dismiss="modal">غلق
                            </button>
                        </div>
                    </div>
                </div>
            </div>

            {/* edite employee modal */}
            <Modal show={showModalEdit} size="lg" onHide={() => setShowModalEdit(false)}>

                <Modal.Header>
                    <Modal.Title className='m-auto text-danger' >تعديل الموظف</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Formik
                        initialValues={{ ...editedEmployee }}
                        enableReinitialize={true}
                        onSubmit={(values) => {
                            submitEditedEmployee(values);
                        }}
                    >
                        {
                            formik => {
                                return (
                                    <Form className="row g-3">
                                        <div className="col-md-4">
                                            <label htmlFor="code" className='form-label'>كود
                                                الموظف</label>
                                            <Field type="text" className='form-control' name="code"
                                                id="code" />
                                        </div>
                                        <div className="col-md-4">
                                            <label htmlFor="name" className='form-label'>الاسم</label>
                                            <Field type="text" className='form-control' name="name"
                                                id="name" />
                                        </div>
                                        <div className="col-md-4">
                                            <label htmlFor="branch" className='form-label'>اسم الفرع</label>
                                            <Field as="select" name="branch_id" className='form-control' id="branch_id">
                                                <option value='' hidden disabled>اختر...</option>
                                                {branches.map((branch) => <option key={branch.id} value={branch?.id}>{branch?.name}</option>)}
                                            </Field>
                                        </div>
                                        <div className="col-md-4">
                                            <label htmlFor="hourRate" className='form-label'> سعر
                                                الساعة </label>
                                            <Field type="text" id="hourRate" className='form-control'
                                                name="hourRate" />
                                        </div>
                                        <div className="col-md-4">
                                            <label htmlFor="category_id"
                                                className='form-label'>وظيفته </label>
                                            <Field as="select" type="text" className='form-control'
                                                name="category_id" id="category_id">
                                                <option value='' hidden disabled>اختر...</option>
                                                {employeeCategory.map((cat) => <option key={cat.id}
                                                    value={cat.id}>{cat?.name}</option>)}
                                            </Field>
                                        </div>
                                        <div className="col-md-4">
                                            <label htmlFor="holiday" className='form-label'>يوم
                                                الأجازة</label>
                                            <Field as="select" type="text" className='form-control'
                                                name="holiday" id="holiday">
                                                <option value='' hidden disabled>اختر...</option>
                                                <option value="saturday">السبت</option>
                                                <option value="sunday">الأحد</option>
                                                <option value="monday">الإثنين</option>
                                                <option value="tuesday">الثلاثاء</option>
                                                <option value="wednesday">الأربعاء</option>
                                                <option value="thursday">الخميس</option>
                                                <option value="friday">الجمعة</option>
                                            </Field>
                                        </div>
                                        <div className="col-md-4">
                                            <label htmlFor="constantIncentive" className='form-label'> قيمة الحافز الثابت</label>
                                            <Field type="text" id="constantIncentive" className='form-control' name="constantIncentive" />
                                        </div>
                                        {daysOfWeek.map((day, index) => (
                                            <div key={index} className='col-md-12 row'>
                                                <span
                                                    className='form-label my-3 fw-bolder fs-5'>موعد {day === 'Saturday' ? 'السبت' :
                                                        day === 'Sunday' ? 'الأحد' : day === 'Monday' ? 'الإثنين' :
                                                            day === 'Tuesday' ? 'الثلاثاء' :
                                                                day === 'Wednesday' ? 'الأربعاء' :
                                                                    day === 'Thursday' ? 'الخميس' :
                                                                        day === 'Friday' ? 'الجمعة' : ''}</span>


                                                {
                                                    editedEmployee.name && editedEmployee.times.map((time, index) => (
                                                        time.day === day && <div className="row" key={index}>
                                                            <div className="col-md-3">
                                                                <label className='form-label'>موعد
                                                                    الحضور</label>
                                                                <Field
                                                                    type="time"
                                                                    step='60'
                                                                    id={`attendance_time_${index}`}
                                                                    className='form-control'
                                                                    name={`times[${index}].attendance_time`} />
                                                            </div>
                                                            <div className="col-md-3">
                                                                <label className='form-label'>موعد
                                                                    الإنصراف</label>
                                                                <Field
                                                                    type="time"
                                                                    step='60'
                                                                    id={`departure_time_${index}`}
                                                                    className='form-control'
                                                                    name={`times[${index}].departure_time`} />
                                                            </div>

                                                        </div>
                                                    ))
                                                }


                                            </div>
                                        ))}
                                        <div className="col-md-12">
                                            <label htmlFor="notes"
                                                className='form-label'>ملاحظات</label>
                                            <Field type="text" as="textarea" id="notes"
                                                className='form-control' name="notes" />

                                        </div>
                                        <div className="col-md-3">
                                            <button type='submit'
                                                className='btn btn-primary form-control fs-5'>
                                                {isLoading == true ?
                                                    <i className='fa fa-spinner fa-spin'></i> : 'تعديل'}
                                            </button>
                                        </div>

                                    </Form>
                                )
                            }
                        }

                    </Formik>

                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setShowModalEdit(false)}>إغلاق</Button>
                </Modal.Footer>
            </Modal>
        </>)
}
