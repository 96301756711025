import React, { useContext, useEffect, useRef, useState } from 'react'
import { AuthContext } from '../../Context/AuthStore';
import axios from 'axios';
import Pagination from '../Pagination/Pagination';
import { toast } from 'react-toastify';
import Joi from 'joi';
import Select from 'react-select';

export default function AllAttendanceDeparture() {
    let { accessToken } = useContext(AuthContext);
    const currentPath = window.location.pathname;
    let [pagination, setPagination] = useState(null);
    let [currentPage, setCurrentPage] = useState(1);
    let [attendanceDeparture, setAttendanceDeparture] = useState([]);
    let [filterEmployeeId, setFilterEmployeeId] = useState('');
    function handleEmployeeIdChange(selectedOption) {
        setFilterEmployeeId(selectedOption?.value);
    }
    let [filterFromDate, setFilterFromDate] = useState('');
    function handleFromDateChange(event) {
        setFilterFromDate(event.target.value)
    }
    let [filterToDate, setFilterToDate] = useState('');
    function handleToDateChange(event) {
        setFilterToDate(event.target.value)
    }
    let getAttendanceDepartureData = async (page = 1) => {
        let attentanceResult;
        let urlApi = `${process.env.REACT_APP_API_URL}/api/attendance/filter?`;
        if (filterEmployeeId !== undefined && filterEmployeeId.length > 0) {
            urlApi += `employee_id=${filterEmployeeId}&`
        }
        if (filterFromDate !== undefined && filterFromDate.length > 0) {
            urlApi += `fromDate=${filterFromDate}&`
        }
        if (filterToDate !== undefined && filterToDate.length > 0) {
            urlApi += `toDate=${filterToDate}&`
        }
        urlApi += `page=${page}`
        attentanceResult = await axios.get(urlApi, {
            headers: {
                "Authorization": `Bearer ${accessToken}`
            }
        })
        if (attentanceResult) {
            setAttendanceDeparture(attentanceResult.data.data);
            setPagination(attentanceResult.data.meta); // Set pagination data
            setCurrentPage(page); // Set current page
        }
    };
    useEffect(() => { getAttendanceDepartureData() },
        [filterEmployeeId, filterFromDate, filterToDate]);

    let [employees, setEmployees] = useState([]);
    let [employeesOptions, setEmployeesOptions] = useState([]);
    let getEmployeesData = async () => {
        try {
            let { data } = await axios.get(`${process.env.REACT_APP_API_URL}/api/employees?noPaginate=1`, {
                headers: {
                    "Authorization": `Bearer ${accessToken}`
                }
            });
    
            
            setEmployees(data.data);
        } catch (error) {
            toast.error('حدث خطأ ما.')
        }
    };

    useEffect(() => {
        getEmployeesData()
    }, []);
    useEffect(() => {
        let mapEmployees = employees?.map((emp) => ({
            value: `${emp.id}`,
            label: `${emp.name}`,
        }));
        setEmployeesOptions(mapEmployees);
    }, [employees]);

    let handlePageChange = (page) => {
        getAttendanceDepartureData(page);
    };
    let [editedTimes, setEditedTimes] = useState({
        attendance_time: '',
        departure_time: '',
    });
    let [initialTimes, setInitialTimes] = useState({
        attendance_time: '',
        departure_time: '',
    });
    let [attendanceId, setAttendanceId] = useState('');
    let showAttendanceDeparture = () => {
        if (attendanceDeparture.length > 0) {
            return (
                <div className="shadow rounded rounded-4 bg-white mx-3 p-3 table-responsive">
                    <table dir="rtl" className='table table-hover align-middle text-center table-responsive-list'>
                        <thead className='table-primary no-wrap-heading'>
                            <tr>
                                <th>التاريخ</th>
                                <th>اليوم</th>
                                <th>الموظف</th>
                                <th>الوظيفة</th>
                                <th>وقت الحضور</th>
                                <th>وقت الانصراف</th>
                                <th>ساعات الحضور </th>
                                <th>تأخير</th>
                                {currentPath === '/admin/all-attendance-departure' && (
                                <th>خيارات</th>
                                )}

                            </tr>
                        </thead>
                        <tbody>
                            {attendanceDeparture.map((emp) => (
                                <tr key={emp.id}>
                                    <td data-label="التاريخ">{emp?.date}</td>
                                    <td data-label="اليوم">
                                        {emp.day === 'Saturday' ? 'السبت' :
                                            emp.day === 'Sunday' ? 'الأحد' :
                                                emp.day === 'Monday' ? 'الإثنين' :
                                                    emp.day === 'Tuesday' ? 'الثلاثاء' :
                                                        emp.day === 'Wednesday' ? 'الأربعاء' :
                                                            emp.day === 'Thursday' ? 'الخميس' :
                                                                emp.day === 'Friday' ? 'الجمعة' : ''}
                                    </td>
                                    <td data-label="الموظف">{emp?.employee?.name}</td>
                                    <td data-label="الوظيفة">{emp?.employee?.category?.name}</td>
                                    <td data-label="وقت الحضور">{emp?.attendance_time}</td>
                                    <td data-label="وقت الانصراف">{emp?.departure_time}</td>
                                    <td data-label=" ساعات الحضور">{emp?.working_hours}</td>
                                    <td data-label="تأخير">{emp?.lateHours}</td>
                                    {currentPath === '/admin/all-attendance-departure' && (
                                    <td data-label="خيارات">
                                        <i className='bi bi-pencil-square text-primary fs-5 mx-1' onClick={() => {
                                            setAttendanceId(emp.id);
                                            setEditedTimes({
                                                attendance_time: emp.attendance_time,
                                                departure_time: emp.departure_time,
                                            });
                                            setInitialTimes({
                                                attendance_time: emp.attendance_time,
                                                departure_time: emp.departure_time,
                                            });
                                        }} data-bs-toggle="modal" data-bs-target="#editeTimes"></i>
                                    </td>
                                    )}

                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            );
        } else {
            return (
                <div className=' d-flex justify-content-center  height-calc-70 align-items-center' >
                {attendanceDeparture.length <= 0 && filterEmployeeId.length <= 0 && filterFromDate.length <= 0 && filterToDate.length <= 0  ?
                  <i className='fa fa-spinner fa-spin  fa-3x'></i>
                  : <div className='alert alert-danger w-50 text-center'>لا يوجد تطابق</div>
                }
              </div>
            );
        }
    };
    //edite 
    let [isLoading, setIsLoading] = useState(false);
    const formRef = useRef(null);
    let getInputValue = (event) => {
        let myTimes = { ...editedTimes }; //deep copy
        myTimes[event.target.name] = event.target.value;
        setEditedTimes(myTimes);
    };
    let sendEditedTimesToApi = async () => {
        const dataToSend = {};
        if (editedTimes.attendance_time !== initialTimes.attendance_time) {
            dataToSend.attendance_time = editedTimes.attendance_time;
        }
        if (editedTimes.departure_time !== initialTimes.departure_time) {
            dataToSend.departure_time = editedTimes.departure_time;
        }

        await axios.put(`${process.env.REACT_APP_API_URL}/api/attendance/${attendanceId}`, dataToSend, {
            headers: {
                "Authorization": `Bearer ${accessToken}`
            }
        }).then((res) => {
            setIsLoading(false);
            toast.success(res.data.message, {
                position: 'top-center'
            });
            formRef.current.reset();
            setEditedTimes({
                attendance_time: "",
                departure_time: "",
            });
            getAttendanceDepartureData();
        }).catch((errors) => {
            setIsLoading(false);
            try {
                const errorList = errors?.response?.data?.message;
                if (errorList !== undefined) {
                    Object.keys(errorList)?.map((err) => {
                        errorList[err]?.map((err) => {
                            toast.error(err);
                        });
                    });
                } else {
                    toast.error("حدث خطأ ما");
                }
            } catch (error) {
                toast.error(errors?.response?.data?.message);
            }
        });
    };

    let validateEditedTimesFrom = () => {
        const schema = Joi.object({
            attendance_time: Joi.string().empty(null),
            departure_time: Joi.string().empty(null)
        });
        return schema.validate(editedTimes, { abortEarly: false });
    };

    let submitEditedTimes = (e) => {
        setIsLoading(true);
        e.preventDefault();
        let validation = validateEditedTimesFrom();
        if (!validation.error) {
            sendEditedTimesToApi();
        } else {
            setIsLoading(false);
            try {
                validation?.error?.details?.map((err) => {
                    toast.error(err.message);
                });
            } catch (e) {
                toast.error("حدث خطأ ما");
            }
        }
    };


    return (
        <>
            <div className=" mx-3  row  gy-3" dir='rtl'>
                <div className="col-md-4 mt-5">
                    <Select
                        options={employeesOptions}
                        onChange={handleEmployeeIdChange}
                        isSearchable={true}
                        placeholder="اختر موظف"
                    />
                </div>
                <div className="col-md-4">
                    <label htmlFor="fromDate" className='form-label'> من يوم </label>
                    <input type="date" className='form-control' onChange={handleFromDateChange} />
                </div>
                <div className="col-md-4">
                    <label htmlFor="toDate" className='form-label'> إلى يوم </label>
                    <input type="date" className='form-control' onChange={handleToDateChange} />
                </div>

            </div>
            <div className="text-center my-3">
                <Pagination pagination={pagination} currentPage={currentPage} handlePageChange={handlePageChange} />
            </div>

            {showAttendanceDeparture()}
            {/* edite times modal */}
            <div dir='rtl' className="modal fade" id="editeTimes" tabIndex={-1} aria-labelledby="editeTimesLabel" aria-hidden="true">
                <div className="modal-dialog modal-xl">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h1 className="modal-title text-danger fs-5 m-auto" id="editeTimesLabel">تعديل وقت الحضور والانصراف</h1>
                        </div>
                        <div className="modal-body">
                            <form ref={formRef} onSubmit={submitEditedTimes}>
                                <div className="row gy-3">
                                    <div className="col-md-6">
                                        <label htmlFor="body" className='form-label'>وقت الحضور</label>
                                        <input
                                            type='time'
                                            className='form-control'
                                            name="attendance_time"
                                            onChange={getInputValue}
                                        />
                                    </div>
                                    <div className="col-md-6">
                                        <label htmlFor="body" className='form-label'>وقت الانصراف</label>
                                        <input
                                            type='time'
                                            className='form-control'
                                            name="departure_time"
                                            onChange={getInputValue}
                                        />
                                    </div>
                                    <div className="col-md-3">
                                        <button type='submit' className='btn btn-primary form-control fs-5'>
                                            {isLoading ? <i className='fa fa-spinner fa-spin'></i> : 'تعديل'}
                                        </button>
                                    </div>
                                </div>
                            </form>
                            <div className="my-2">
                                <button type="button" className="btn btn-secondary ms-3" data-bs-dismiss="modal">غلق</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
