import React, { useContext, useEffect, useState } from 'react'
import { NavLink } from 'react-router-dom'
import { AuthContext } from '../../Context/AuthStore';
import axios from 'axios';

export default function Rules() {
  let { accessToken } = useContext(AuthContext);
  const currentPath = window.location.pathname;
  let [managmentRules, setManagmentRules] = useState([]);
  let [clientsRules, setClientsRules] = useState([]);
  let [colleaguesRules, setColleaguesRules] = useState([]);
  let [deliveryRules, setDeliveryRules] = useState([]);

  let [searchText, setSearchText] = useState('');
  function handleSearchChange(event) {
    setSearchText(event.target.value);
    if (event.target.value.length === 0) {
      setSearchRuslet([])
    }
  };
  let [searchRuslet, setSearchRuslet] = useState([]);

  let handleSearchData = async () => {
    let searchResult;
    if (searchText !== undefined && searchText.trim().length > 0) {
      searchResult = await axios.get(`${process.env.REACT_APP_API_URL}/api/rules/search/${searchText.trim()}`, {
        headers: {
          "Authorization": `Bearer ${accessToken}`
        }
      });
      setSearchRuslet(searchResult.data.data);
    }
  }
  useEffect(() => { handleSearchData() }, [searchText])
  useEffect(() => {
    if (currentPath !== '/delivery/rules') {
      getColleaguesRulesData()
      getMangmentRulesData()
      getClientsRulesData()
      getDeliveryRulesData()
    } else {
      getDeliveryRulesData()
    }

  }, []);
  let getMangmentRulesData = async () => {
    let { data } = await axios.get(`${process.env.REACT_APP_API_URL}/api/rules/management`, {
      headers: {
        "Authorization": `Bearer ${accessToken}`
      }
    });
    setManagmentRules(data.data);
  };

  let getClientsRulesData = async () => {
    let { data } = await axios.get(`${process.env.REACT_APP_API_URL}/api/rules/clients`, {
      headers: {
        "Authorization": `Bearer ${accessToken}`
      }
    });
    setClientsRules(data.data);
  };

  let getColleaguesRulesData = async () => {

    let { data } = await axios.get(`${process.env.REACT_APP_API_URL}/api/rules/colleagues`, {
      headers: {
        "Authorization": `Bearer ${accessToken}`
      }
    });;
    setColleaguesRules(data.data);
  };

  let getDeliveryRulesData = async () => {

    let { data } = await axios.get(`${process.env.REACT_APP_API_URL}/api/rules/delivery`, {
      headers: {
        "Authorization": `Bearer ${accessToken}`
      }
    });;
    setDeliveryRules(data.data);
  };

  return (
    <>
      <div className="container-fluid p-3 ">
        {(currentPath === '/admin/rules') && (
          <div className=" my-3 text-center row mx-2">
            <div className="col-md-6">
              <input type="text" className='form-control text-end ' onChange={handleSearchChange} placeholder=' ...بحث   ' />
            </div>
            <div className="col-md-6">
              <NavLink to='/admin/rules/add' className='btn btn-primary mt-1' >إضافة تعليمات</NavLink>
            </div>
          </div>)}
        {searchRuslet.length > 0 ? (
          <div>
            {searchRuslet.map((res) => (
              <div key={res.id} className='card p-2 mb-4'>
                <div className='fw-bolder'>{res?.type?.name}:</div>
                <div>{res?.body}</div>

              </div>
            ))}
          </div>
        ) : (
          <>

            {currentPath !== '/delivery/rules' && (
              <>
                <div className="card pb-3">
                  <p className='text-center fw-bold bg-primary-subtle  m-auto rounded py-1 px-4 fs-5'>ما يخص الإدارة</p>
                  {managmentRules.map((mangRule) => (
                    <div key={mangRule.id}>
                      <div className='p-2'>
                        {mangRule?.body}
                        {(currentPath === '/admin/rules') && (
                          <NavLink to={`/admin/rules/edite/${mangRule.id}`}>
                            <i className='bi bi-pencil-square mx-4 text-primary fs-5'></i>
                          </NavLink>)}
                      </div>
                    </div>
                  ))}
                </div>
                <div className="card pb-3 my-3">
                  <p className='text-center fw-bold bg-primary-subtle  m-auto rounded py-1 px-4 fs-5'>ما يخص العملاء</p>
                  {clientsRules.map((clientRule) => (
                    <div key={clientRule.id}>
                      <div className='p-2'>
                        {clientRule?.body}
                        {(currentPath === '/admin/rules') && (
                          <NavLink to={`/admin/rules/edite/${clientRule.id}`}>
                            <i className='bi bi-pencil-square mx-4 text-primary fs-5'></i>
                          </NavLink>)}
                      </div>
                    </div>
                  ))}
                </div>
                <div className="card pb-3">
                  <p className='text-center fw-bold bg-primary-subtle m-auto rounded py-1 px-4 fs-5'>ما يخص الزملاء</p>
                  {colleaguesRules.map((colleRule) => (
                    <div key={colleRule.id}>
                      <div className='p-2'>
                        {colleRule?.body}
                        {(currentPath === '/admin/rules') && (
                          <NavLink to={`/admin/rules/edite/${colleRule.id}`}>
                            <i className='bi bi-pencil-square mx-4 text-primary fs-5'></i>
                          </NavLink>
                        )}
                      </div>
                    </div>
                  ))}
                </div>
              </>
            )}

            <div className="card pb-3 my-3 ">
              <p className='text-center fw-bold bg-primary-subtle m-auto rounded py-1 px-4 fs-5'>ما يخص الطيارين</p>
              {deliveryRules.map((delivRule) => (
                <div key={delivRule.id}>
                  <div className='p-2'>
                    {delivRule?.body}
                    {currentPath === '/admin/rules' && (
                      <NavLink to={`/admin/rules/edite/${delivRule.id}`}>
                        <i className='bi bi-pencil-square mx-4 text-primary fs-5'></i>
                      </NavLink>
                    )}
                  </div>
                </div>
              ))}
            </div>
          </>
        )}
      </div>
    </>
  )
}

