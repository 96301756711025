
import axios from 'axios';
import React, { useContext, useEffect, useState } from 'react'
import { Helmet } from 'react-helmet-async';
import { NavLink } from 'react-router-dom';
import { AuthContext } from '../../../Context/AuthStore';
import Pagination from '../../Pagination/Pagination';
import { toast } from 'react-toastify';
import { useRef } from 'react';
import Joi from 'joi';

export default function AbuQirClients() {
    let { accessToken } = useContext(AuthContext);
    const currentPath = window.location.pathname;
    let [clients, setClients] = useState([]);
    let [pagination, setPagination] = useState(null);
    let [currentPage, setCurrentPage] = useState(1); // Current page state
    let [searchText, setSearchText] = useState('');

    function handleSearchChange(event) {
        setSearchText(event.target.value);
    };
    let getClientData = async (page = 1) => {
        let searchResult;
        if (searchText !== undefined && searchText.trim().length > 0) {
            searchResult = await axios.get(`${process.env.REACT_APP_API_URL}/api/abuQirClients/filter?key=${searchText.trim()}&page=${page}`, {
                headers: {
                    "Authorization": `Bearer ${accessToken}`
                }
            });

            setClients(searchResult.data.data);
            setPagination(searchResult.data.meta); // Set pagination data
            setCurrentPage(page); // Set current page
        } else {
            searchResult = await axios.get(`${process.env.REACT_APP_API_URL}/api/abuQirClients?page=${page}`, {
                headers: {
                    "Authorization": `Bearer ${accessToken}`
                }
            });

            setClients(searchResult.data.data);
            setPagination(searchResult.data.meta); // Set pagination data
            setCurrentPage(page); // Set current page
        }
    };
    useEffect(() => {
        getClientData()
    }, [searchText])
    //for handle page change
    let handlePageChange = (page) => {
        getClientData(page);
    };
    let deleteClient = async (clientId) => {
        try {
            let { data } = await axios.delete(`${process.env.REACT_APP_API_URL}/api/abuQirClients/delete/${clientId}`, {
                headers: {
                    "Authorization": `Bearer ${accessToken}`
                }
            });
            toast.success(data.message);
            getClientData()

        } catch (error) {
            toast.error('حدث خطأ ما، حاول مرة أخرى')
        }
    };
    let [clientId, setClientId] = useState('');
    let showClients = () => {
        if (clients.length > 0) {
            return (
                <div className="shadow rounded rounded-4 bg-white mx-3 p-3 table-responsive ">
                    <table dir="rtl" className='table  table-hover align-middle text-center table-responsive-list  '>
                        <thead className='table-primary   no-wrap-heading'>
                            <tr>
                                <th> رقم</th>
                                <th>الاسم</th>
                                <th> رقم القيد</th>
                                <th> الهاتف </th>
                                <th>حالته</th>
                                {/* <th>الباقي</th> */}
                                <th>خيارات</th>
                            </tr>
                        </thead>
                        <tbody>
                            {clients.map((client, index) => <tr key={client.id}>
                                <td data-label="#">{++index}</td>
                                <td data-label="الاسم ">{client?.name}</td>
                                <td data-label="رقم القيد">{client?.registrationNumber}</td>
                                <td data-label="الهاتف">{client?.phone}</td>
                                <td data-label="حالته">{client?.statu?.name}</td>
                                {/* <td data-label="الباقي">{client?.totalRemaining}</td> */}
                                <td data-label="خيارات" >
                                    <div className="dropdown">
                                        <i className='bi bi-pencil-square text-primary fs-5 mx-1' onClick={() => {
                                            setClientId(client.id)
                                        }} data-bs-toggle="modal" data-bs-target="#exampleModal"></i>
                                        <span data-bs-toggle="dropdown" aria-expanded="false">
                                            <i className='bi bi-trash text-danger fs-5 ' ></i>
                                        </span>
                                        <ul className="dropdown-menu">
                                            <li>
                                                <button className="dropdown-item" onClick={() => { deleteClient(client.id) }}>حذف</button>
                                            </li>
                                        </ul>
                                    </div>
                                </td>
                            </tr>
                            )}
                        </tbody>
                    </table>
                </div>
            )
        } else {
            return (
                <div className=' d-flex justify-content-center  height-calc-70 align-items-center' >
                    {clients.length <= 0 && searchText.length <= 0 ?
                        <i className='fa fa-spinner fa-spin  fa-3x'></i>
                        : <div className='alert alert-danger w-50 text-center'>لا يوجد تطابق لهذا البحث</div>
                    }
                </div>
            )
        }
    };
    // edite abu qir client
    const formRef = useRef(null);
    let [isLoading, setIsLoading] = useState(false);
    let [editedClient, setEditedClient] = useState({
        name: '',
        registrationNumber: '',
        phone: '',
        status_id: '',
        notes: ''
    });
    let [oneClient, setOneClient] = useState([])
    let getOneClient = async () => {
        if (clientId) {
            let { data } = await axios.get(`${process.env.REACT_APP_API_URL}/api/abuQirClients/show/${clientId}`, {
                headers: {
                    "Authorization": `Bearer ${accessToken}`
                }
            });
            setOneClient(data.data);
            setEditedClient({
                name: data.data.name || '',
                registrationNumber: data.data.registrationNumber || '',
                phone: data.data.phone || '',
                status_id: data.data.statu.id || '',
                notes: data.data.notes || ''
            });
        }
    };
    useEffect(() => {
        getOneClient()
    }, [clientId]);
    //get client status
    let [clientsStatus, setClientsStatus] = useState([]);
    let getClientsStatus = async () => {
        let { data } = await axios.get(`${process.env.REACT_APP_API_URL}/api/properties/getCustomList/AbuQirClientStatus`, {
            headers: {
                "Authorization": `Bearer ${accessToken}`
            }
        });
        setClientsStatus(data)
    }
    useEffect(() => {
        getClientsStatus()
    }, []);
    let getInputValue = (event) => {
        let myClients = { ...editedClient }; //deep copy
        myClients[event.target.name] = event.target.value;
        setEditedClient(myClients)
    };
    let sendEditedClientToApi = async () => {
        await axios.put(`${process.env.REACT_APP_API_URL}/api/abuQirClients/${clientId}`, editedClient, {
            headers: {
                "Authorization": `Bearer ${accessToken}`
            }
        }).then((res) => {
            toast.success(res.data.message, {
                position: 'top-center'
            });
            formRef.current.reset();
            setEditedClient({
                name: '',
                registrationNumber: '',
                phone: '',
                status_id: '',
                notes: ''
            })
            getClientData()
        }).catch((errors) => {
            try {
                const errorList = errors?.response?.data?.message;
                if (errorList !== undefined) {
                    Object.keys(errorList)?.map((err) => {
                        errorList[err]?.map((err) => {
                            toast.error(err);
                        })
                    });
                } else {
                    toast.error("حدث خطأ ما");
                }
            } catch (error) {
                toast.error("حدث خطأ ما");
            }

        });
    };

    let validateEditedClientFrom = () => {
        const schema = Joi.object({
            name: Joi.string().required().messages({
                'string.empty': 'اسم العميل  مطلوب',
                'any.required': 'اسم العميل  مطلوب',
            }),
            registrationNumber: Joi.string().required().messages({
                'string.empty': ' رقم القيد  مطلوب',
                'any.required': ' رقم القيد  مطلوب',
            }),
            phone: Joi.string().pattern(/^01[0125][0-9]{8}$/).message('رقم الهاتف غير صالح').empty(''),
            status_id: Joi.number().empty(''),
            notes: Joi.string().empty(''),

        });
        return schema.validate(editedClient, { abortEarly: false });
    };

    let submitEditedClient = (e) => {
        e.preventDefault();
        let validation = validateEditedClientFrom();
        if (!validation.error) {
            sendEditedClientToApi();
        } else {

            try {
                validation.error.details.map((err) => {
                    toast.error(err.message);
                })
            } catch (e) {
                toast.error("حدث خطأ ما");
            }
        }
    };

    return (
        <>
            <Helmet>
                <meta charSet="utf-8" />
                <title>AbuQir Clients</title>
            </Helmet>
            <div className=" my-3 text-center row mx-2  ">
                <div className="col-md-6">
                    {/* Render NavLink based on the current URL path */}
                    {currentPath === '/admin/abuqir-clients' && (
                        <NavLink to='/admin/abuqir-clients/add' className='btn btn-primary mb-1'>إضافة عميل</NavLink>
                    )}
                    {currentPath === '/doctor/abuqir-clients' && (
                        <NavLink to='/doctor/abuqir-clients/add' className='btn btn-primary mb-1'>إضافة عميل</NavLink>
                    )}
                    {currentPath === '/casher/abuqir-clients' && (
                        <NavLink to='/casher/abuqir-clients/add' className='btn btn-primary mb-1'>إضافة عميل</NavLink>
                    )}
                    {currentPath === '/purchases/abuqir-clients' && (
                        <NavLink to='/purchases/abuqir-clients/add' className='btn btn-primary mb-1'>إضافة عميل</NavLink>
                    )}
                    {currentPath === '/branch-manager/abuqir-clients' && (
                        <NavLink to='/branch-manager/abuqir-clients/add' className='btn btn-primary mb-1'>إضافة عميل</NavLink>
                    )}
                </div>
                <div className="col-md-4">
                    <input type="text" className='form-control text-end mt-1' onChange={handleSearchChange} placeholder=' ...بحث عن عميل ' />
                </div>
            </div>

            <div className="text-center mb-2">
                <Pagination pagination={pagination} currentPage={currentPage} handlePageChange={handlePageChange} />
            </div>
            {showClients()}
            {/* edite Client modal */}
            <div dir='rtl' className="modal fade " id="exampleModal" tabIndex={-1} aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog  modal-xl ">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h1 className="modal-title text-danger fs-5  m-auto" id="exampleModalLabel"> تعديل العميل</h1>
                        </div>
                        <div className="modal-body">
                            <form ref={formRef} onSubmit={submitEditedClient} >
                                <div className="row gy-3">
                                    <div className="col-md-4">
                                        <label htmlFor="name" className='form-label'>اسم العميل </label>
                                        <input
                                            type="text"
                                            className='form-control'
                                            name="name"
                                            onChange={getInputValue}
                                            defaultValue={oneClient?.name}
                                        />
                                    </div>
                                    <div className="col-md-4">
                                        <label htmlFor="registrationNumber" className='form-label'>رقم القيد</label>
                                        <input
                                            type="text"
                                            className='form-control'
                                            name="registrationNumber"
                                            onChange={getInputValue}
                                            defaultValue={oneClient?.registrationNumber}
                                        />
                                    </div>
                                    <div className="col-md-4">
                                        <label htmlFor="phone" className='form-label'> هاتف العميل</label>
                                        <input
                                            type="number"
                                            className='form-control'
                                            name="phone"
                                            onChange={getInputValue}
                                            defaultValue={oneClient?.phone}
                                        />
                                    </div>
                                    <div className="col-md-4">
                                        <label htmlFor="status_id" className='form-label'>حالته </label>
                                        <select name="status_id" className='form-control ' defaultValue={0} onChange={getInputValue}>
                                            <option value={0} hidden disabled>اختر</option>
                                            {clientsStatus.map((status) => <option key={status.id} value={status.id}>{status?.name}</option>)}
                                        </select>
                                    </div>
                                    <div className="col-md-12">
                                        <label htmlFor="notes" className='form-label'>ملاحظات</label>
                                        <textarea
                                            name="notes"
                                            className='form-control'
                                            onChange={getInputValue}
                                            defaultValue={oneClient?.notes}
                                        />
                                    </div>
                                    <div className="col-md-2">
                                        <button type='submit' className='btn btn-primary form-control fs-5' data-bs-dismiss="modal">
                                            {isLoading == true ? <i className='fa fa-spinner fa-spin'></i> : 'تعديل'}
                                        </button>
                                    </div>

                                </div>
                            </form >
                            <div className="my-2">
                                <button type="button" className="btn btn-secondary ms-3" data-bs-dismiss="modal">غلق</button>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>)
}
