
import axios from 'axios';
import Joi from 'joi';
import React, { useContext, useEffect, useRef, useState } from 'react'
import { toast } from 'react-toastify';
import { Helmet } from 'react-helmet-async';
import { AuthContext } from '../../Context/AuthStore';

export default function AddEmployee() {
    let { accessToken } = useContext(AuthContext);
    let [isLoading, setIsLoading] = useState(false);
    let formRef = useRef(null);
    let [employees, setEmployess] = useState({
        name: '',
        code: '',
        branch_id: '',
        notes: '',
        hourRate: '',
        attendance_time: '',
        departure_time: '',
        category_id: '',
        constantIncentive: ''
    });
    let handleInputChange = (event) => {
        const { name, value } = event.target;
        setEmployess((prevState) => ({
            ...prevState,
            [name]: value,
        }));
    };
    let [branches, setBranches] = useState([]);
    let getBranches = async () => {
        let { data } = await axios.get(`${process.env.REACT_APP_API_URL}/api/properties/getCustomList/branch`, {
            headers: {
                "Authorization": `Bearer ${accessToken}`
            }
        });
        setBranches(data)
    }
    //get employeeCategory data
    let [employeeCategory, setEmployeeCategory] = useState([]);
    let getEmployeeCategory = async () => {
        let { data } = await axios.get(`${process.env.REACT_APP_API_URL}/api/properties/getCustomList/employeeCategory`, {
            headers: {
                "Authorization": `Bearer ${accessToken}`
            }
        });
        setEmployeeCategory(data)
    }
    useEffect(() => {
        getBranches()
        getEmployeeCategory()
    }, []);
    let sendEmployeesDataToApi = async () => {
        try {
            let employeeResponse = await axios.post(`${process.env.REACT_APP_API_URL}/api/employees`, employees, {
                headers: {
                    "Authorization": `Bearer ${accessToken}`
                }
            });
    
            toast.success(employeeResponse.data.message, {
                position: 'top-center'
            });
    
            if (userChecked) {
                // Add user data if the "Add as user" checkbox is checked
                await sendUserDataToApi();     
            }
    
            setIsLoading(false);
            // Reset form and state
            formRef.current.reset();
            setEmployess({
                name: '',
                code: '',
                hourRate: '',
                attendance_time: '',
                departure_time: '',
                category_id: '',
                notes: '',
                branch_id: '',
                constantIncentive: ''
            });
        } catch (errors) {
            handleApiErrors(errors);
        }
    };
    
    let handleApiErrors = (errors) => {
        setIsLoading(false);
        try {
            const errorList = errors?.response?.data?.message;
            if (errorList !== undefined) {
                Object.keys(errorList).map((err) => {
                    errorList[err].map((err) => {
                        toast.error(err);
                    });
                });
            }
        } catch (error) {
            toast.error('حدث خطأ ما');
        }
    };
    let validateEmployeesForm = () => {
        const schema = Joi.object({
            name: Joi.string().required(),
            code: Joi.string().required(),
            hourRate: Joi.number().required(),
            attendance_time: Joi.string().required(),
            departure_time: Joi.string().required(),
            category_id: Joi.number().required(),
            notes: Joi.string().empty(''),
            branch_id: Joi.number().required(),
            constantIncentive: Joi.string().empty(''),
        });
        return schema.validate(employees, { abortEarly: false });
    };
    let submitEmployeesForm = (e) => {
        setIsLoading(true);
        e.preventDefault();
        let validation = validateEmployeesForm();
        if (!validation.error) {
            sendEmployeesDataToApi();
        } else {
            setIsLoading(false);
            try {
                validation.error.details.map((err) => {
                    toast.error(err.message);
                });
            } catch (e) {
                toast.error("حدث خطأ ما");
            }
        }
    };
    // for add employee as user
    let [user, setUser] = useState({
        name: '',
        code: '',
        branch_id: '',
        notes: '',
        role: '',
        phone: '',
        password: '',
        password_confirmation: '',
    })
    let handleUserInputsChange = (event) => {
        const { name, value } = event.target;
        if(userChecked){
            setUser((prevState) => ({
                ...prevState,
                [name]: value, 
                name:employees.name,
                    code:employees.code,
                    branch_id:employees.branch_id,
                    notes:employees.notes
            }));                
        }
    };
    let [userChecked, setUserChecked] = useState(false);
    let handleUserCheckboxChange = (event) => {
        setUserChecked(event.target.checked);
    };
    useEffect(() => {
        if (userChecked) {
            setUser((prevUser) => ({
                ...prevUser,
                name: employees.name,
                code: employees.code,
                branch_id: employees.branch_id,
                notes: employees.notes,
            }));
        }
    }, [employees, userChecked]);
    let sendUserDataToApi = async () => {      
        try {
            let userResponse = await axios.post(`${process.env.REACT_APP_API_URL}/api/users`, user, {
                headers: {
                    "Authorization": `Bearer ${accessToken}`
                }
            });
    
            toast.success(userResponse.data.message, {
                position: 'top-center'
            });
            setUser({
                name: '',
                code: '',
                branch_id: '',
                notes: '',
                role: '',
                phone: '',
                password: '',
                password_confirmation: '',
            });
        } catch (errors) {      
            handleApiErrors(errors);
        }
    };
  

    return (
        <>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Add Emlployee</title>
            </Helmet>
            <h3 className='alert alert-primary text-center mx-5 my-2  fw-bold'>إضافة موظف </h3>
            <div className="mx-5 p-3 rounded rounded-3 bg-white">
                <form ref={formRef} onSubmit={submitEmployeesForm}>
                    <div className="row gy-3">
                        <div className="col-md-4">
                            <label htmlFor="code" className='form-label'>كود الموظف</label>
                            <input type="text" className='form-control' name="code" id="code" onChange={handleInputChange} />
                        </div>
                        <div className="col-md-4">
                            <label htmlFor="name" className='form-label'>الاسم</label>
                            <input type="text" className='form-control' name="name" id="name" onChange={handleInputChange} />
                        </div>
                        <div className="col-md-4">
                            <label htmlFor="branch" className='form-label'>اسم الفرع</label>
                            <select name="branch_id" className='form-control' id="branch" defaultValue={0} onChange={handleInputChange}>
                                <option value={0} hidden disabled>اختر...</option>
                                {branches.map((branch) => <option key={branch.id} value={branch?.id}>{branch?.name}</option>)}
                            </select>
                        </div>
                        <div className="col-md-4">
                            <label htmlFor="hourRate" className='form-label'> سعر الساعة </label>
                            <input type="text" id="hourRate" className='form-control' name="hourRate" onChange={handleInputChange} />
                        </div>
                        <div className="col-md-4">
                            <label htmlFor="category_id" className='form-label'>وظيفته </label>
                            <select type="text" className='form-control' name="category_id" id="category_id" defaultValue={0} onChange={handleInputChange} >
                                <option value={0} hidden disabled>اختر...</option>
                                {employeeCategory.map((cat) => <option key={cat.id} value={cat.id}>{cat?.name}</option>)}
                            </select>
                        </div>
                        <div className="col-md-4">
                            <label htmlFor="constantIncentive" className='form-label'>  الحافز الثابت</label>
                            <input type="text" id="constantIncentive" className='form-control' name="constantIncentive" onChange={handleInputChange} />
                        </div>
                        <div className="col-md-4">
                            <label htmlFor="attendance_time" className='form-label'>  موعد الحضور </label>
                            <input step="60" type="time" id="attendance_time" className='form-control' name="attendance_time" onChange={handleInputChange} />
                        </div>
                        <div className="col-md-4">
                            <label htmlFor="departure_time" className='form-label'>  موعد الإنصراف </label>
                            <input type="time" step='60' id="departure_time" className='form-control' name="departure_time" onChange={handleInputChange} />
                        </div>
                        <div className='col-md-5'>
                            <label className='fw-bold ms-2'> إضافة كمستخدم</label>
                            <input type="checkbox" onChange={handleUserCheckboxChange} />
                        </div>
                        {userChecked && (
                            <div className='row gy-2'>
                                <div className="col-md-4">
                                    <label htmlFor="role" className='form-label'>نوع المستخدم</label>
                                    <select name="role" defaultValue={0} className='form-control' id="role"
                                        onChange={handleUserInputsChange}>
                                        <option value={0} hidden disabled>اختر...</option>
                                        <option value="admin">أدمن</option>
                                        <option value="doctor">صيدلي</option>
                                        <option value="delivery">طيار</option>
                                        <option value="purchases">مشتريات</option>
                                        <option value="casher">كاشير</option>
                                        <option value="branchMgr">مدير فرع</option>
                                    </select>
                                </div>
                                <div className="col-md-4">
                                    <label htmlFor="phone" className='form-label'>رقم الهاتف</label>
                                    <input type="tel" className='form-control' name="phone" id="phone"
                                        onChange={handleUserInputsChange} />
                                </div>
                                <div className="col-md-4">
                                    <label htmlFor="password" className='form-label'>كلمة السر</label>
                                    <input type="password" className='form-control' name="password" id="password"
                                        onChange={handleUserInputsChange} />
                                </div>
                                <div className="col-md-4">
                                    <label htmlFor="password_confirmation" className='form-label'>تأكيد كلمة السر</label>
                                    <input type="password" className='form-control' name="password_confirmation"
                                        id="password_confirmation" onChange={handleUserInputsChange} />
                                </div>
                            </div>)}
                        <div className="col-md-12">
                            <label htmlFor="notes" className='form-label'>ملاحظات</label>
                            <textarea type="text" id="notes" className='form-control' name="notes" onChange={handleInputChange} />
                        </div>
                        <div className="col-md-3">
                            <button type='submit' className='btn btn-primary form-control fs-5'>
                                {isLoading == true ?
                                    <i className='fa fa-spinner fa-spin'></i> : 'إضافة'}
                            </button>
                        </div>
                    </div>
                </form>
            </div>
        </>
    )
}
