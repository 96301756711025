import React from 'react'
import Posts from './Posts'

export default function CompletePosts() {
  return (
  <>
  <Posts/>
  </>
  )
}
