
import React, { useContext } from 'react'
import { Link, NavLink } from 'react-router-dom';
import { AuthContext } from '../../Context/AuthStore';


export default function PurchasesNavbar() {
    let { logout, userData, userName ,transfersCount } = useContext(AuthContext);


    return (
        <>

            {userData ? <nav className="navbar navbar-expand-lg bg-primary">
                <div className="container">
                    <div className="d-flex" >
                        <div className='dropdown'>
                            <a className="nav-link fs-3 " data-bs-toggle="dropdown" aria-expanded="false" style={{ lineHeight: 'px' }}>
                                <i className="bi bi-person-circle text-white "></i>
                            </a>
                            <ul className="dropdown-menu">
                                <li onClick={logout}><a className="dropdown-item " > تسجيل خروج</a></li>
                                <li><Link to='/purchases/reset-password' className="dropdown-item " >تغيير كلمة السر</Link></li>
                            </ul>
                        </div>
                        <span className='text-white mt-1 ms-2 fs-5 d-inline-block' >{userName}</span>
                    </div>
                    <button className="navbar-toggler text-white mb-2" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                        <span className="navbar-toggler-icon  " />
                    </button>
                    <div className="collapse navbar-collapse" id="navbarSupportedContent">
                        <ul className="navbar-nav ms-auto mb-2 mb-lg-0">
                            <li className="nav-item">
                                <NavLink className="nav-link  text-white fs-6 fw-bold " aria-current="page" to={`/purchases/posts`} >منشورات</NavLink>
                            </li>
                            <li className="nav-item">
                                <NavLink className="nav-link  text-white fs-6 fw-bold " aria-current="page" to={`/purchases/pay-batchs`} >QNP</NavLink>
                            </li>
                            <li className="nav-item">
                                <NavLink className="nav-link  text-white fs-6 fw-bold " aria-current="page" to={`/purchases/attendance-register`} >سجل الحضور</NavLink>
                            </li>
                            <li className="nav-item dropdown" >
                                <span className="nav-link text-white fs-6 fw-bold" data-bs-toggle="dropdown" aria-expanded="false">
                                    أبو قير
                                </span>
                                <ul className="dropdown-menu">
                                    <li>
                                        <NavLink to={`/purchases/abuqir-clients`} className="dropdown-item" >العملاء</NavLink>
                                    </li>
                                    <li>
                                        <NavLink to={`/purchases/abuqir-forms`} className="dropdown-item" >الإستمارات</NavLink>
                                    </li>
                                    <li>
                                        <NavLink to={`/purchases/abuqir-bills`} className="dropdown-item" >لنا فواتير</NavLink>
                                    </li>
                                </ul>
                            </li>
                            <li className="nav-item">
                                <NavLink className="nav-link text-white fs-6 fw-bold" aria-current="page" to={`/purchases/transfers-products`}>
                                   <span className='text-white'>التحويلات </span> 
                                    {/* Display the transfers count */}
                                    {transfersCount > 0 && <span className="badge bg-danger"> {transfersCount} </span>}
                                </NavLink>

                            </li>
                            <li className="nav-item">
                                <NavLink className="nav-link  text-white fs-6 fw-bold " aria-current="page" to={`/purchases/notifications`} >الإشعارات </NavLink>
                            </li>
                            <li className="nav-item">
                                <NavLink className="nav-link  text-white fs-6 fw-bold " aria-current="page" to={`/purchases/customer-service`} >خدمة العملاء</NavLink>
                            </li>
                            <li className="nav-item">
                                <NavLink className="nav-link  text-white fs-6 fw-bold " aria-current="page" to={`/purchases/companies`} >الشركات</NavLink>
                            </li>
                            <li className="nav-item">
                                <NavLink className="nav-link  text-white fs-6 fw-bold " aria-current="page" to={`/purchases/inventory-products`} >الجرد</NavLink>
                            </li>

                            <li className="nav-item">
                                <NavLink className="nav-link  text-white fs-6 fw-bold " aria-current="page" to={`/purchases/purchases`} >المشتريات</NavLink>
                            </li>

                        </ul>

                    </div>
                </div>
            </nav> : ''}


        </>
    )
}
