import axios from 'axios';
import Joi from 'joi';
import React, { useContext,  useRef, useState } from 'react'
import { Helmet } from 'react-helmet-async';
import { toast } from 'react-toastify';
import { AuthContext } from '../../Context/AuthStore';

export default function AddPost() {
    let { accessToken } = useContext(AuthContext);
    const formRef = useRef(null);
    let [isLoading, setIsLoading] = useState(false);
    let [post, setPost] = useState({
        body: ''
    });
    let handleInputChange = (event) => {
        const { name, value } = event.target;
        setPost(prevState => ({ ...prevState, [name]: value }));
    };
    let sendPostDataToApi = async () => {
        try {
            await axios.post(`${process.env.REACT_APP_API_URL}/api/posts`, post, {
                headers: {
                    "Authorization": `Bearer ${accessToken}`
                }
            }).then((res) => {
                formRef.current.reset();
                toast.success(res.data.message, {
                    position: 'top-center'
                });
                setIsLoading(false);
            }).catch((errors) => {
                setIsLoading(false);
                const errorList = errors?.response?.data?.message;
                if (errorList !== undefined) {
                    Object.keys(errorList)?.map((err) => {
                        errorList[err]?.map((err) => {
                            toast.error(err);
                        })
                    });
                } else {
                    toast.error("حدث خطأ ما");
                }
            });
        } catch (error) {
            toast.error("حدث خطأ ما");
        }
    };

    let validatePostForm = () => {
        const schema = Joi.object({
            body: Joi.string().required(),
        });
        return schema.validate(post, { abortEarly: false });
    };
    let submitPostForm = (e) => {
        setIsLoading(true);
        e.preventDefault();
        let validation = validatePostForm();
        if (!validation.error) {
            sendPostDataToApi();
        } else {
            setIsLoading(false);
            try {
                validation?.error?.details?.map((err) => {
                    toast.error(err.message);
                })
            } catch (e) {
                toast.error("حدث خطأ ما");
            }
        }
    };

    return (
        <>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Add Post</title>
            </Helmet>
            <h3 className='alert alert-primary text-center mx-5 my-2  fw-bold'>إضافة منشور </h3>
            <div className="mx-5 p-3 rounded rounded-3 bg-white">
                <form ref={formRef} onSubmit={submitPostForm} >
                    <div className="row gy-3">
                    <div className="col-md-12">
                            <label htmlFor="body" className='form-label'>المنشور</label>
                            <textarea className='form-control' name="body"
                                onChange={handleInputChange} />
                        </div>
             
                        <div className="col-md-3">
                            <button type='submit' className='btn btn-primary form-control fs-5'>
                                {isLoading == true ? <i className='fa fa-spinner fa-spin'></i> : 'إضافة'}
                            </button>
                        </div>

                    </div>
                </form >
            </div >
        </>
    )
}
