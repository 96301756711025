
import axios from 'axios';
import Joi, { number } from 'joi';
import React, { useContext, useEffect, useRef, useState } from 'react'
import { Helmet } from 'react-helmet-async';

import { toast } from 'react-toastify';
import { AuthContext } from '../../Context/AuthStore';

export default function EditePayBatch({ batchId, getPayBatchsData }) {
    let { accessToken } = useContext(AuthContext);
    let [isLoading, setIsLoading] = useState(false);
    let [payBatchs, setPayBatchs] = useState({
        value: '',
        number: '',//reset number
    });
    let handleInputChange = (event) => {
        const { name, value } = event.target;
        setPayBatchs(prevState => ({ ...prevState, [name]: value }));
    };
    let [oneBatch, setOneBatch] = useState([]);
    let getOnePayBatch = async () => {
        if (batchId) {
            let { data } = await axios.get(`${process.env.REACT_APP_API_URL}/api/payBatchs/show/${batchId}`, {
                headers: {
                    "Authorization": `Bearer ${accessToken}`
                }
            });
            setOneBatch(data.data);
            setPayBatchs({
                value: data.data.value,
                number: data.data.number,
            })
        }
    }
    useEffect(() => {
        getOnePayBatch()
    }, [batchId])
    let sendPayBatchDataToApi = async () => {
        try {
            await axios.put(`${process.env.REACT_APP_API_URL}/api/payBatchs/${batchId}`, payBatchs, {
                headers: {
                    "Authorization": `Bearer ${accessToken}`
                }
            }).then((res) => {
                toast.success(res.data.message, {
                    position: 'top-center'
                });
                setIsLoading(false);
                getPayBatchsData()
            }).catch((errors) => {
                setIsLoading(false);
                const errorList = errors?.response?.data?.message;
                if (errorList !== undefined) {
                    Object.keys(errorList)?.map((err) => {
                        errorList[err]?.map((err) => {
                            toast.error(err);
                        })
                    });
                } else {
                    toast.error("حدث خطأ ما");
                }
            });
        } catch (error) {
            toast.error("حدث خطأ ما");
        }
    };

    let validatePayBatchForm = () => {
        const schema = Joi.object({
            value: Joi.string().required(),
            number: Joi.number().required(),
        });
        return schema.validate(payBatchs, { abortEarly: false });
    };
    let submitPayBatchForm = (e) => {
        setIsLoading(true);
        e.preventDefault();
        let validation = validatePayBatchForm();
        if (!validation.error) {
            sendPayBatchDataToApi();
        } else {
            setIsLoading(false);
            try {
                validation?.error?.details?.map((err) => {
                    toast.error(err.message);
                })
            } catch (e) {
                toast.error("حدث خطأ ما");
            }
        }
    };

    return (
        <>
            <div className="mx-5 p-3 rounded rounded-3 bg-white">
                <form onSubmit={submitPayBatchForm} >
                    <div className="row gy-3">
                        <div className="col-md-5">
                            <label htmlFor="value" className='form-label'>قيمة الكلوز باتش</label>
                            <input className='form-control' name="value"
                                onChange={handleInputChange} value={payBatchs.value} />
                        </div>
                        <div className="col-md-5">
                            <label htmlFor="number" className='form-label'>رقم الريسيت</label>
                            <input className='form-control' name="number"
                                onChange={handleInputChange} defaultValue={payBatchs.number} />
                        </div>

                    </div>
                    <div className="col-md-3 my-3">
                        <button type='submit' className='btn btn-primary form-control fs-5'>
                            {isLoading == true ? <i className='fa fa-spinner fa-spin'></i> : 'تعديل'}
                        </button>
                    </div>
                </form >
            </div >
        </>
    )
}

