import axios from 'axios';
import React, { useContext, useEffect, useState } from 'react'
import { Helmet } from 'react-helmet-async';
import { NavLink } from 'react-router-dom';
import { AuthContext } from '../../Context/AuthStore';
import { toast } from 'react-toastify';
import Pagination from '../Pagination/Pagination';
import EditePost from './EditePost';
import AddComment from './AddComment';
import EditeComment from './EditeComment';

export default function Posts() {
  let { accessToken } = useContext(AuthContext);
  const currentPath = window.location.pathname;
  let [pagination, setPagination] = useState(null);
  let [currentPage, setCurrentPage] = useState(1); // Current page state
  let [postsData, setPostsData] = useState([]);
  let getPostsData = async (page = 1) => {
    try {
      let { data } = await axios.get(`${currentPath == '/admin/posts' || currentPath == '/doctor/posts' || currentPath == '/purchases/posts' || currentPath == '/branch-manager/posts' ? `${process.env.REACT_APP_API_URL}/api/posts/0?page=${page}` : `${process.env.REACT_APP_API_URL}/api/posts/1?page=${page}`} `, {
        headers: {
          "Authorization": `Bearer ${accessToken}`
        }
      });
      setPostsData(data.data);
      setPagination(data.meta); // Set pagination data
      setCurrentPage(page); // Set current page
    } catch (error) {
      toast.error('حدث خطأ ما.')
    }
  };
  useEffect(() => { getPostsData() },
    []);
  let handlePageChange = (page) => {
    getPostsData(page);
  };
  let deletePost = async (postId) => {
    try {
      let { data } = await axios.delete(`${process.env.REACT_APP_API_URL}/api/posts/delete/${postId}`, {
        headers: {
          "Authorization": `Bearer ${accessToken}`
        }
      });
      toast.success(data.message);
      getPostsData()
    } catch (error) {
      toast.error('حدث خطأ ما، حاول مرة أخرى')
    }
  };
  let [isCompleted, setIsCompleted] = useState({
    isCompleted: true
  });
  // for making isCompleted for post
  let makeIsCompletedForPost = async (postId) => {
    alert('هل أنت متأكد من تغيير حالة المنشور إلى مكتمل؟')
    await axios.put(`${process.env.REACT_APP_API_URL}/api/posts/${postId}`, isCompleted, {
      headers: {
        "Authorization": `Bearer ${accessToken}`
      }
    }).then((res) => {
      toast.success(res.data.message);
      getPostsData()
    }).catch((errors) => {
      toast.error('حدث خطأ ما');
    })
  }

  let [postId, setPostId] = useState('');
  let [commentId, setCommentId] = useState('');
  let showPostsData = () => {
    if (postsData.length > 0) {
      return (
        <div className="row gy-3 m-2" dir='rtl'>
          {postsData.map((post) => <div key={post.id} className='col-md-4'>
            <div className="card">
              <div className="dropdown bg-secondary-subtle w-25">
                <i className='bi bi-pencil-square text-primary fs-5 mx-1' onClick={() => {
                  setPostId(post.id)
                }} data-bs-toggle="modal" data-bs-target="#editePost"> </i>
                <span data-bs-toggle="dropdown" aria-expanded="false">
                  <i className='bi bi-trash text-danger fs-5'></i>
                </span>
                <ul className="dropdown-menu">
                  <li>
                    <button className="dropdown-item" onClick={() => {
                      deletePost(post.id)
                    }}>حذف
                    </button>
                  </li>
                </ul>
              </div>
              <div className='position-absolute top-0 start-50' onClick={() => {
                makeIsCompletedForPost(post.id)
              }}>
                <i className='bi bi-check-circle-fill text-success fs-5'></i>
              </div>
              {post.isCompleted == false ?
                <div className='text-bg-danger position-absolute top-0 start-0 p-1'>غير مكتمل</div>
                :
                <div className='text-bg-success position-absolute top-0 start-0 p-1'>مكتمل</div>}
              <div className='p-2'>
                <span className='bg-info-subtle p-1 rounded'>كاتب المنشور : {post.creator?.name}</span>
                <p>
                  {post.body}
                </p>
              </div>
              <div className='m-auto'>
                <span className='btn btn-sm btn-secondary' onClick={() => {
                  setPostId(post.id)
                }} data-bs-toggle="modal" data-bs-target="#addComment">إضافة  تعليق</span>
              </div>
              <hr className='mb-0' />
              <span className='bg-secondary-subtle m-auto w-50 text-center p-1 rounded'>التعليقات</span>
              {post.comments.length > 0 ?
                <div>
                  {post.comments.map((comment) => <div key={comment.id}>
                    <div className='bg-primary-subtle m-1 rounded p-1'>
                      <span className='bg-white p-1 rounded'>كاتب التعليق : {comment.creator?.name}</span>
                      <p> {comment.body}
                        <i className='bi bi-pencil-square float-start text-primary fs-5' onClick={() => {
                          setCommentId(comment.id)
                        }} data-bs-toggle="modal" data-bs-target="#editeComment"> </i>
                      </p>
                      <span className='clearfix'></span>
                    </div>
                  </div>)}
                </div>
                :
                <div>
                  <p className='bg-danger-subtle m-1 rounded p-1'>لا يوجد تعليقات</p>
                </div>
              }

            </div>
          </div>)}
        </div>
      )
    } else {
      return (
        <div className='d-flex justify-content-center  height-calc-70 align-items-center' >
          <i className='fa fa-spinner fa-spin  fa-3x'></i>
        </div>
      )
    }
  };



  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title> Posts</title>
      </Helmet>
      <div className=" my-3 text-center row mx-2 " dir='rtl'>
        <div className="col-md-6">
          {/* Render NavLink based on the current URL path */}
          {currentPath === '/admin/posts' && (
            <div>
              <NavLink to='/admin/complete-posts' className='btn  btn-success m-3 '>منشورات مكتملة</NavLink>
              <NavLink to='/admin/posts/add' className='btn btn-primary m-3 '>إضافة  منشور</NavLink>
            </div>
          )}
          {currentPath === '/doctor/posts' && (
            <div>
              <NavLink to='/doctor/complete-posts' className='btn  btn-success m-3 '>منشورات مكتملة</NavLink>
              <NavLink to='/doctor/posts/add' className='btn btn-primary m-3 '>إضافة  منشور</NavLink>
            </div>
          )}
          {currentPath === '/purchases/posts' && (
               <div>
               <NavLink to='/purchases/complete-posts' className='btn  btn-success m-3 '>منشورات مكتملة</NavLink>
               <NavLink to='/purchases/posts/add' className='btn btn-primary m-3 '>إضافة  منشور</NavLink>
             </div>
          )}
          {currentPath === '/branch-manager/posts' && (
            <div>
            <NavLink to='/branch-manager/complete-posts' className='btn  btn-success m-3 '>منشورات مكتملة</NavLink>
            <NavLink to='/branch-manager/posts/add' className='btn btn-primary m-3 '>إضافة  منشور</NavLink>
          </div>
          )}
        </div>
      </div>
      <div className="text-center mb-2">
        <Pagination pagination={pagination} currentPage={currentPage} handlePageChange={handlePageChange} />
      </div>
      {showPostsData()}
      {/* edite post modal */}
      <div dir='rtl' className="modal fade" id="editePost" tabIndex={-1}
        aria-labelledby="editePostLabel" aria-hidden="true">
        <div className="modal-dialog  modal-xl">
          <div className="modal-content">
            <div className="modal-header">
              <h1 className="modal-title text-danger fs-5 m-auto" id="editePostLabel"> تعديل
                المنشور  </h1>
            </div>
            <div className="modal-body">
              <EditePost postId={postId} getPostsData={getPostsData} />
            </div>
            <div className="m-2">
              <button type="button" className="btn btn-secondary ms-3" data-bs-dismiss="modal">غلق
              </button>
            </div>
          </div>
        </div>
      </div>
      {/* add comment modal */}
      <div dir='rtl' className="modal fade" id="addComment" tabIndex={-1}
        aria-labelledby="addCommentLabel" aria-hidden="true">
        <div className="modal-dialog  modal-xl">
          <div className="modal-content">
            <div className="modal-header">
              <h1 className="modal-title text-danger fs-5 m-auto" id="addCommentLabel"> إضافة
                تعليق  </h1>
            </div>
            <div className="modal-body">
              <AddComment postId={postId} getPostsData={getPostsData} />
            </div>
            <div className="m-2">
              <button type="button" className="btn btn-secondary ms-3" data-bs-dismiss="modal">غلق
              </button>
            </div>
          </div>
        </div>
      </div>
      {/* edite comment modal */}
      <div dir='rtl' className="modal fade" id="editeComment" tabIndex={-1}
        aria-labelledby="editeCommentLabel" aria-hidden="true">
        <div className="modal-dialog  modal-xl">
          <div className="modal-content">
            <div className="modal-header">
              <h1 className="modal-title text-danger fs-5 m-auto" id="editeCommentLabel"> تعديل
                التعليق  </h1>
            </div>
            <div className="modal-body">
              <EditeComment commentId={commentId} getPostsData={getPostsData} />
            </div>
            <div className="m-2">
              <button type="button" className="btn btn-secondary ms-3" data-bs-dismiss="modal">غلق
              </button>
            </div>
          </div>
        </div>
      </div>

    </>)
}
