
import React, { useContext } from 'react'
import { Link, NavLink } from 'react-router-dom';
import { AuthContext } from '../../Context/AuthStore';


export default function CasherNavbar() {
    let { logout, userData, userName } = useContext(AuthContext);


    return (
        <>

            {userData ? <nav className="navbar navbar-expand-lg bg-primary">
                <div className="container">
                    <div className="d-flex" >
                        <div className='dropdown'>
                            <a className="nav-link fs-3 " data-bs-toggle="dropdown" aria-expanded="false" style={{ lineHeight: 'px' }}>
                                <i className="bi bi-person-circle text-white "></i>
                            </a>
                            <ul className="dropdown-menu">
                                <li onClick={logout}><a className="dropdown-item " > تسجيل خروج</a></li>
                                <li><Link to='/casher/reset-password' className="dropdown-item " >تغيير كلمة السر</Link></li>
                            </ul>
                        </div>
                        <span className='text-white mt-1 ms-2 fs-5 d-inline-block' >{userName}</span>
                    </div>
                    <button className="navbar-toggler text-white mb-2" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                        <span className="navbar-toggler-icon  " />
                    </button>
                    <div className="collapse navbar-collapse" id="navbarSupportedContent">
                        <ul className="navbar-nav ms-auto mb-2 mb-lg-0">
                            <li className="nav-item dropdown" >
                                <span className="nav-link text-white fs-6 fw-bold" data-bs-toggle="dropdown" aria-expanded="false">
                                    الإدارة
                                </span>
                                <ul className="dropdown-menu">
                                    <li>
                                        <NavLink to='/casher/attendance-departure' className='dropdown-item' >حضور وانصراف  </NavLink>
                                    </li>
                                    <li>
                                        <NavLink to='/casher/attendance-register' className='dropdown-item' >سجل الحضور</NavLink>
                                    </li> 
                                    <li>
                                        <NavLink to='/casher/pay-batchs' className='dropdown-item' >QNP</NavLink>
                                    </li> 
                                </ul>
                            </li>
                            <li className="nav-item dropdown" >
                                <span className="nav-link text-white fs-6 fw-bold" data-bs-toggle="dropdown" aria-expanded="false">
                                    أبو قير
                                </span>
                                <ul className="dropdown-menu">
                                    <li>
                                        <NavLink to={`/casher/abuqir-clients`} className="dropdown-item" >العملاء</NavLink>
                                    </li>
                                    <li>
                                        <NavLink to={`/casher/abuqir-forms`} className="dropdown-item" >الإستمارات</NavLink>
                                    </li>
                                    <li>
                                        <NavLink to={`/casher/abuqir-bills`} className="dropdown-item" >لنا فواتير</NavLink>
                                    </li>
                                </ul>
                            </li>
                            <li className="nav-item">
                                <NavLink className="nav-link  text-white fs-6 fw-bold " aria-current="page" to={`/casher/users`} >المستخدمون</NavLink>
                            </li>
                            <li className="nav-item">
                                <NavLink className="nav-link  text-white fs-6 fw-bold " aria-current="page" to={`/casher/clients`} >العملاء</NavLink>
                            </li>

                            <li className="nav-item">
                                <NavLink className="nav-link  text-white fs-6 fw-bold " aria-current="page" to={`/casher/orders`} >الأوردرات</NavLink>
                            </li>

                        </ul>

                    </div>
                </div>
            </nav> : ''}


        </>
    )
}
