
import React from 'react';
import { Outlet } from 'react-router-dom'
import SalePointModal from '../SalePointModal/SalePointModal';
import BranchManagerNavbar from '../Navbar/BranchManagerNavbar';
import { Helmet } from 'react-helmet-async';

export default function BranchManagerLayout() {

  return (
    <>
    <Helmet>
        <meta charSet="utf-8" />
        <title>Orders</title>
    </Helmet>
    <SalePointModal />
    <BranchManagerNavbar />
    <Outlet />
</>
  )
}
