import axios from 'axios';
import React, { useContext, useEffect, useState } from 'react'
import { Helmet } from 'react-helmet-async';
import { NavLink } from 'react-router-dom';
import { AuthContext } from '../../../Context/AuthStore';
import { toast } from 'react-toastify';

export default function AbuQirBills() {
  let { accessToken } = useContext(AuthContext);
  const currentPath = window.location.pathname;
  let [billsData, setBillsData] = useState([]);

  let getBillsData = async () => {
    try {
      let { data } = await axios.get(`${process.env.REACT_APP_API_URL}/api/abuQirForms/filter?isReceived=0`, {
        headers: {
          "Authorization": `Bearer ${accessToken}`
        }
      });
      setBillsData(data.data);
    } catch (error) {
      toast.error('حدث خطأ ما.')
    }
  };
  useEffect(() => { getBillsData() },
    []);

  // for making received for form
  let makeReceivedForForm = async (formId) => {
    alert('  هل أنت متأكد من تغيير حالة هذه الاستمارة؟ بتغيير الحالة تُنقل الاستمارة إلى صفحة استمارات أبو قير.')
    await axios.put(`${process.env.REACT_APP_API_URL}/api/abuQirForms/updateReceiveStatus/${formId}`, {}, {
      headers: {
        "Authorization": `Bearer ${accessToken}`
      }
    }).then((res) => {
      toast.success(res.data.message);
      getBillsData()
    }).catch((errors) => {
      toast.error('حدث خطأ ما');
      toast.error(errors?.response?.data?.message);
    })

  }

  let showBillsData = () => {
    if (billsData.length > 0) {
      return (
        <div className="shadow rounded rounded-4 bg-white mx-3 p-3 table-responsive ">
          <table dir="rtl" className='table table-bordered table-hover align-middle text-center table-responsive-list '>
            <thead className='table-primary   no-wrap-heading'>
              <tr>
                <th>تاريخ الإنشاء</th>
                <th> رقم القيد</th>
                <th>اسم العميل</th>
                <th>الهاتف</th>
                <th>مساهمة</th>
                <th> الباقي</th>
                <th> قيم الإستمارة</th>
                {(currentPath === '/admin/abuqir-bills' || currentPath === '/casher/abuqir-bills') && (
                  <th>وصلت؟</th>
                )}

              </tr>
            </thead>
            <tbody>
              {billsData.map((bill) => <tr key={bill.id}>
                <td data-label="تاريخ الإنشاء"  >{bill.created_at}</td>
                <td data-label="رقم القيد">{bill?.client?.registrationNumber}</td>
                <td data-label="اسم العميل ">{bill?.client?.name}</td>
                <td data-label="الهاتف">{bill?.client?.phone}</td>
                <td data-label=" مساهمة ">{bill.isAContribution === 1 ? 'نعم' : 'لا'}</td>
                <td data-label="الباقي">{bill?.remaining}</td>
                <td data-label="قيم الإستمارة">
                  {bill?.elements?.map((ele, index) => (
                    <span key={ele.id}>
                      <span>{ele.price}</span>
                      {index !== bill.elements.length - 1 && <span> ,</span>} {/* إضافة الفاصلة إلا إذا كانت هذه آخر عنصر */}
                    </span>
                  ))}
                </td>
                {(currentPath === '/admin/abuqir-bills' || currentPath === '/casher/abuqir-bills') && (
                  <td data-label="وصلت؟">
                    <i className='bi bi-x-circle-fill text-danger fs-5'
                      onClick={() => makeReceivedForForm(bill.id)} ></i>
                  </td>
                )}

              </tr>
              )}
            </tbody>
          </table>

        </div>
      )
    } else {
      return (
        <div className='d-flex justify-content-center  height-calc-70 align-items-center' >
          <span className='alert alert-danger w-50 text-center'>لا يوجد فواتير</span>
        </div>
      )
    }
  };



  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>AbuQir Bills</title>
      </Helmet>
      <div className=" my-3 text-center row mx-2 " dir='rtl'>
        <div className="col-md-6">
          {/* Render NavLink based on the current URL path */}
          {currentPath === '/admin/abuqir-bills' && (
            <NavLink to='/admin/abuqir-bills/add' className='btn btn-primary my-2 '>إضافة إستمارة</NavLink>
          )}
          {currentPath === '/doctor/abuqir-bills' && (
            <NavLink to='/doctor/abuqir-bills/add' className='btn btn-primary my-2 '>إضافة إستمارة</NavLink>
          )}
          {currentPath === '/casher/abuqir-bills' && (
            <NavLink to='/casher/abuqir-bills/add' className='btn btn-primary my-2 '>إضافة إستمارة</NavLink>
          )}
          {currentPath === '/purchases/abuqir-bills' && (
            <NavLink to='/purchases/abuqir-bills/add' className='btn btn-primary my-2 '>إضافة إستمارة</NavLink>
          )}
          {currentPath === '/branch-manager/abuqir-bills' && (
            <NavLink to='/branch-manager/abuqir-bills/add' className='btn btn-primary my-2 '>إضافة إستمارة</NavLink>
          )}
        </div>
      </div>

      {showBillsData()}

    </>)
}
